import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { useSettings } from "src/hooks/useSettings";
import { useAuth } from "src/context/AuthProvider";
import { useNavigate } from "react-router";
import Button from "src/components/Button";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function InProgressLinkModal({ open, closeModal }) {
  const { idToken } = useAuth();

  const navigate = useNavigate();
  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    console.log(data);
    reset();
    // setImportInProgress(true);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[12]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="flex flex-col justify-center items-center w-full">
                    <img
                      className="mt-3"
                      alt="wait"
                      src="/icons/FormDill/progress.png"
                    />
                    <p className="mt-3 text-neutral-900 text-xl">
                      Import in Progress
                    </p>

                    <Button
                      onClick={() => {
                        closeModal(false);
                      }}
                      type="button"
                      className="my-4 flex w-full max-w-[345px] justify-center rounded text-base font-medium text-white focus:outline-none"
                    >
                      OK
                    </Button>
                    <p className="text-base text-neutral-900">
                      Pasted an incorrect link by mistake?{" "}
                      <span className="cursor-pointer text-tertiary4-500">
                        Update drive link
                      </span>{" "}
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
}
