import { Transition } from "@headlessui/react";
import React, { useState } from "react";
import "./accordian.scss";

export default function Accordian({
  title,
  content,
  className = null,
  handleMonthDataClick = null,
}) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={`${className} accordian_item`}>
      <div
        className="accordian_heading"
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        {/* <div className={`title ${isActive ? "titleActive" : ""}`}>{title}</div> */}
        {isActive ? (
          <button
            onClick={() => handleMonthDataClick(title)}
            className={`title ${isActive ? "titleActive" : ""}`}
          >
            {title}
          </button>
        ) : (
          // <div className={`title ${isActive ? "titleActive" : ""}`}>{title}</div>

          <div className={`title notActive`}>{title}</div>
        )}
        <div className="iconAccordian">
          {isActive ? (
            <span className="text-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="activeIcon w-5 h-5 text-secondary-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 15.75l7.5-7.5 7.5 7.5"
                />
              </svg>
            </span>
          ) : (
            <span className="text-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="notActiveIcon w-5 h-5 text-secondary-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          )}
        </div>
      </div>

      <Transition
        show={isActive}
        enter="ease-in-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="accordian_content">{content}</div>
      </Transition>
    </div>
  );
}
