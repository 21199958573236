import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useCompanyData } from "src/context/CompanyDataProvider";
import { useInvoice } from "src/hooks/useInvoice";
import { useAuth } from "src/context/AuthProvider";
import { toast, ToastContainer } from "react-toastify";
import Button from "src/components/Button";
import { useDocumentData } from "src/context/DocumentProvider";
import { useComplianceDocuments } from "src/hooks/useComplianceDocuments";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function UploadComplianceFileModal({
  open,
  closeModal,
  fetchAgain,
  setFetchAgain,
  recordId,
}: any) {
  const [loading, setLoading] = useState(false);
  const { state: CompanyData } = useCompanyData();
  const { uploadFile } = useInvoice();
  const { state: authData, fetchTokenManually, idToken } = useAuth();
  const { uploadComplianceFile, getComplianceDocs } = useComplianceDocuments();
  const { reloadComplainceDocumentData } = useDocumentData();

  const methods = useForm();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    console.log(data);
    const formData = new FormData();
    formData.append("docItem", data.file_input[0]);
    const updatedToken = await fetchTokenManually();
    const token = updatedToken || authData?.idTokenUpdated || idToken;
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");

    try {
      const res = await uploadComplianceFile(
        companyId,
        token,
        recordId,
        formData
      );

      if (res) {
        console.log(res);
        const allFormationDocs = await getComplianceDocs(token, companyId);
        reloadComplainceDocumentData(allFormationDocs);
        reset();
        setFetchAgain(!fetchAgain);
        setLoading(false);
        closeModal(false);
        toast.success("File uploaded successfully!!", {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log("error", error);
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong!! Please try again",
        {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      reset();
      setFetchAgain(!fetchAgain);
      setLoading(false);
      closeModal(false);
    }

    closeModal(false);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[12]"
          onClose={() => closeModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="sm:flex sm:items-start">
                      <div className="block w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="mt-2 mb-3">
                          <p className="text-lg text-neutral-900">
                            Upload File
                          </p>
                        </div>

                        <input
                          type="file"
                          id="file_input"
                          name="file_input"
                          {...register("file_input", {
                            required: {
                              value: true,
                              message: "Please upload a file to proceed",
                            },
                          })}
                          //   onChange={handleFileChange}
                          className="block w-full mt-4 text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-medium
                                    file:bg-primary-100 file:text-tertiary4-500
                                    hover:file:bg-[#4895ef]
                                    hover:file:text-white
                                    focus:outline-none 
                                    focus:border-none
                                    file:cursor-pointer 
                                    "
                        />

                        <ErrorTest errors={errors} field="file_input" />
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      {/* <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-tertiary4-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-550 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Upload
                      </button> */}
                      <Button
                        disabled={loading}
                        loading={loading}
                        className="inline-flex w-full justify-center rounded-md text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Upload
                      </Button>
                      {/* <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-white hover:bg-primary-100 hover:border-none border border-tertiary4-500 text-tertiary4-500 px-4 py-2 text-base font-medium  shadow-sm focus:outline-none  sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => {
                          reset();
                          closeModal(false);
                        }}
                      >
                        Cancel
                      </button> */}
                      <Button
                        btnType="secondary"
                        type="button"
                        className="mt-3 inline-flex w-full justify-center items-center rounded-md text-tertiary4-500 text-base font-medium shadow-sm focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => {
                          reset();
                          closeModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
}
