import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useAuth } from "src/context/AuthProvider";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";
import { toast, ToastContainer } from "react-toastify";
import Button from "../Button";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function ProfileEditNameSlider({ open, closeModal }) {
  const [loading, setLoading] = useState(false);
  const { idToken, state: UserData, reloadUserData } = useAuth();
  const { editUser } = useAuthOnBoardFlow();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const res = await editUser(data.firstname, data.lastname, idToken);
      if (res) {
        const userData = {
          customer: res,
          isAuthenticated: true,
          companyClicked: true,
        };
        reloadUserData(userData);
      }
      toast.success("Profile Details updated successfully!!", {
        className: "toast-position",
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
      closeModal(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong!! Please try again later",
        {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      setLoading(false);
      closeModal(false);
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[12]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-medium text-neutral-900">
                            Edit Name
                          </Dialog.Title>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                              onClick={() => {
                                closeModal(false);
                                reset();
                              }}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <div className="absolute inset-0 px-4 sm:px-6">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="space-y-6 pt-2">
                              <div>
                                <label
                                  htmlFor="firstName"
                                  className="block text-sm font-medium text-neutral-900"
                                >
                                  First Name
                                </label>
                                <input
                                  id="firstname"
                                  type="text"
                                  className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  placeholder="First Name"
                                  defaultValue={
                                    UserData?.customer?.firstName || ""
                                  }
                                  {...register("firstname", {
                                    required: {
                                      value: true,
                                      message: "First name is required",
                                    },
                                    maxLength: {
                                      value: 15,
                                      message:
                                        "First name length should be between 1 to 15 characters",
                                    },
                                  })}
                                />
                                <ErrorTest errors={errors} field="firstname" />
                              </div>

                              <div>
                                <label
                                  htmlFor="lastname"
                                  className="block text-sm font-medium text-neutral-900"
                                >
                                  Last Name
                                </label>
                                <input
                                  id="lastname"
                                  type="lastname"
                                  className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  placeholder="Last Name"
                                  defaultValue={
                                    UserData?.customer?.lastName || ""
                                  }
                                  {...register("lastname", {
                                    required: {
                                      value: true,
                                      message: "Last name is required",
                                    },
                                    maxLength: {
                                      value: 15,
                                      message:
                                        "Last name length should be between 1 to 15 characters",
                                    },
                                  })}
                                />
                                <ErrorTest errors={errors} field="lastname" />
                              </div>
                              <div>
                                {/* <button
                                  type="submit"
                                  className="group relative  justify-center rounded border border-transparent bg-tertiary4-500 py-2 px-4 text-sm font-medium text-white hover:bg-primary-550"
                                >
                                  Save
                                </button> */}
                                <Button
                                  disabled={loading}
                                  loading={loading}
                                  className="inline-flex w-full justify-center rounded-md text-base font-medium text-white focus:outline-none sm:w-auto"
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
}
