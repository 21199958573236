import axios from "axios";
import { useState } from "react";

export const useComplianceDocuments = () => {
  const getComplianceSearchData = (companyId, idToken) => {
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/compliance-docs/${companyId}/doc-search-data`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const deleteComplianceFile = (companyId, itemId, recordId, idToken) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/compliance-docs/${companyId}/record/${recordId}/item/${itemId}/delete`,
      {},
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const renameComplianceFile = (
    companyId,
    itemId,
    recordId,
    idToken,
    newFileName
  ) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/compliance-docs/${companyId}/record/${recordId}/item/${itemId}/rename`,
      {
        newFileName,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const downloadComplianceFile = (companyId, recordId, itemId, idToken) => {
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/compliance-docs/${companyId}/record/${recordId}/item/${itemId}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        responseType: "blob",
      }
    );
  };

  const uploadComplianceFile = (companyId, idToken, recordId, fileData) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/compliance-docs/${companyId}/record/${recordId}/item`,
      fileData,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  const getComplianceDocs = async (idToken, companyId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/compliance-docs/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      console.log("error", error);
      if (
        error?.response?.status === 404 &&
        error?.response?.data?.message === "Company not found"
      ) {
        throw error?.response?.data?.message;
      }
    }
  };

  return {
    getComplianceDocs,
    uploadComplianceFile,
    deleteComplianceFile,
    renameComplianceFile,
    downloadComplianceFile,
    getComplianceSearchData,
  };
};
