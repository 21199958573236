import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useTable, useExpanded } from "react-table";
import { useAuth } from "src/context/AuthProvider";
import { useCompanyData } from "src/context/CompanyDataProvider";
import "./table.scss";

const gridData = [
  {
    Particulars: "Profit",
    January: 100,
    February: 200,
    March: 100,
    April: 100,
    May: 100,
    June: 200,
    July: 100,
    August: 100,
    Sept: 200,
    Oct: 100,
    Nov: 100,
    Dec: 200,
    subRows: [
      {
        Particulars: "Profit 1",
        January: 100,
        February: 200,
        March: 100,
        April: 100,
        Oct: 100,
        Nov: 100,
        Dec: 200,
      },
      {
        Particulars: "Profit 2",
        January: 100,
        February: 200,
        March: 100,
        April: 100,
        May: 100,
        June: 200,
        July: 100,
        August: 100,
        Sept: 200,
        Oct: 100,
        Nov: 100,
        Dec: 200,
      },
    ],
  },
  {
    Particulars: "Loss",
    January: 400,
    February: 200,
    March: 100,
    April: 100,
    May: 100,
    June: 200,
    July: 100,
    August: 100,
    Nov: 100,
    Dec: 200,
    subRows: [],
  },
  {
    Particulars: "Sales",
    January: 1010,
    February: 200,
    March: 100,
    April: 100,
    May: 100,
    June: 200,
    July: 100,
    August: 100,
    Sept: 200,
    Oct: 100,
    Nov: 100,
    Dec: 200,
    subRows: [
      {
        Particulars: "Sales 1",
        January: 100,
        February: 200,
        March: 100,
        April: 100,
        Oct: 100,
        Nov: 100,
        Dec: 200,
      },
      {
        Particulars: "Sales 2",
        January: 100,
        February: 200,
        March: 100,
        April: 100,
        May: 100,
        June: 200,
        July: 100,
        August: 100,
        Sept: 200,
        Oct: 100,
        Nov: 100,
        Dec: 200,
      },
    ],
  },
  {
    Particulars: "Expense",
    January: 100,
    February: 200,
    March: 100,
    April: 100,
    May: 100,
    June: 200,
    July: 100,
    August: 100,
    Sept: 200,
    Oct: 100,
    Nov: 100,
    Dec: 200,
    subRows: [
      {
        Particulars: "Expense 1",
        January: 100,
        February: 200,
        March: 100,
        April: 100,
        Oct: 100,
        Nov: 100,
        Dec: 200,
      },
      {
        Particulars: "Expense 2",
        January: 100,
        February: 200,
        March: 100,
        April: 100,
        May: 100,
        June: 200,
        July: 100,
        August: 100,
        Sept: 200,
        Oct: 100,
        Nov: 100,
        Dec: 200,
      },
    ],
  },
  {
    Particulars: "Data",
    January: 100,
    February: 200,
    March: 100,
    April: 100,
    May: 100,
    June: 200,
    July: 100,
    August: 100,
    Sept: 200,
    Oct: 100,
    Nov: 100,
    Dec: 200,
    // subRows: [
    //   {
    //     Particulars: "Profit 1",
    //     January: 100,
    //     February: 200,
    //     March: 100,
    //     April: 100,
    //     Oct: 100,
    //     Nov: 100,
    //     Dec: 200,
    //   },
    //   {
    //     Particulars: "Profit 2",
    //     January: 100,
    //     February: 200,
    //     March: 100,
    //     April: 100,
    //     May: 100,
    //     June: 200,
    //     July: 100,
    //     August: 100,
    //     Sept: 200,
    //     Oct: 100,
    //     Nov: 100,
    //     Dec: 200,
    //   },
    // ],
  },
  {
    Particulars: "Data 1",
    January: 100,
    February: 200,
    March: 100,
    April: 100,
    May: 100,
    June: 200,
    July: 100,
    August: 100,
    Sept: 200,
    Oct: 100,
    Nov: 100,
    Dec: 200,
    subRows: [],
  },
  {
    Particulars: "Data 2",
    January: 100,
    February: 200,
    March: 100,
    April: 100,
    May: 100,
    June: 200,
    July: 100,
    August: 100,
    Sept: 200,
    Oct: 100,
    Nov: 100,
    Dec: 200,
  },
  {
    Particulars: "Profit",
    January: 100,
    February: 200,
    March: 100,
    April: 100,
    May: 100,
    June: 200,
    July: 100,
    August: 100,
    Sept: 200,
    Oct: 100,
    Nov: 100,
    Dec: 200,
    subRows: [
      {
        Particulars: "Profit 1",
        January: 100,
        February: 200,
        March: 100,
        April: 100,
        Oct: 100,
        Nov: 100,
        Dec: 200,
      },
      {
        Particulars: "Profit 2",
        January: 100,
        February: 200,
        March: 100,
        April: 100,
        May: 100,
        June: 200,
        July: 100,
        August: 100,
        Sept: 200,
        Oct: 100,
        Nov: 100,
        Dec: 200,
      },
    ],
  },
];

export default function FinancialTable({ tableData, tableDataColumn }) {
  console.log("table props", tableData);
  console.log("table props", tableDataColumn);
  // const columnFix = ["Particulars", "Dec 2022", "Jan 2023", "Feb 2023"];

  const dataWithoutSubRows = tableData?.map(({ subRows, ...rest }) => {
    return { ...rest };
  });

  // const dataWithoutSubRows = gridData.map(({ subRows, ...rest }) => {
  //   return { ...rest };
  // });

  // const columnsHead = Object.keys(dataWithoutSubRows[0]).map((key, id) => {
  //   return {
  //     Header: key,
  //     accessor: key,
  //   };
  // });

  // const columnsHead1 = columnFix?.map((item, id) => {
  //   return {
  //     Header: item,
  //     accessor: item,
  //   };
  // });

  const columnsHead2 = tableDataColumn?.map((item, id) => {
    return {
      Header: item,
      accessor: item,
    };
  });

  // console.log(columnsHead);
  // console.log(columnsHead1);
  // console.log(columnsHead2);

  const expandedColumn = {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    // Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
    //   <span {...getToggleAllRowsExpandedProps()}>
    //     {isAllRowsExpanded ? "👇" : "👉"}
    //   </span>
    // ),
    className: "frozen",
    headerClassName: "frozen",
    Cell: ({ row }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <span
          {...row.getToggleRowExpandedProps({
            style: {
              // We can even use the row.depth property
              // and paddingLeft to indicate the depth
              // of the row
              paddingLeft: `${row.depth * 2}rem`,
              display: "inline-block",
            },
          })}
        >
          {/* {row.isExpanded ? "👇" : "👉"} */}
          {row.isExpanded ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 text-tertiary4-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 15.75l7.5-7.5 7.5 7.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 text-tertiary4-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          )}
        </span>
      ) : null,
  };

  const newColumnWithExpander = [expandedColumn, ...columnsHead2];

  const columns = React.useMemo(() => newColumnWithExpander, [tableDataColumn]);
  const data = React.useMemo(() => tableData, [tableData]);

  // const data = React.useMemo(() => gridData, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns,
      data,
    },
    useExpanded // Use the useExpanded plugin hook
  );

  console.log("column in table", columns);
  console.log("data in table", data);

  return (
    // <table {...getTableProps()}>
    //   <thead>
    //     {headerGroups.map((headerGroup) => (
    //       <tr {...headerGroup.getHeaderGroupProps()}>
    //         {headerGroup.headers.map((column) => (
    //           <th {...column.getHeaderProps()}>{column.render("Header")}</th>
    //         ))}
    //       </tr>
    //     ))}
    //   </thead>
    //   <tbody {...getTableBodyProps()}>
    //     {rows.map((row, i) => {
    //       console.log(row);
    //       prepareRow(row);
    //       return (
    //         <>
    //           <tr
    //             {...row.getRowProps()}
    //             onClick={() => {
    //               row.toggleRowExpanded(); // toggle row expand
    //             }}
    //           >
    //             {row.cells.map((cell) => {
    //               return (
    //                 <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
    //               );
    //             })}
    //           </tr>
    //           {row.isExpanded ? (
    //             <tr>
    //               <td>
    //                 {/*
    //                     Inside it, call our renderRowSubComponent function. In reality,
    //                     you could pass whatever you want as props to
    //                     a component like this, including the entire
    //                     table instance. But for this example, we'll just
    //                     pass the row
    //                   */}
    //                 {/* {renderRowSubComponent({ row })} */}
    //                 {/* {row.id} */}
    //               </td>
    //             </tr>
    //           ) : null}
    //         </>
    //       );
    //     })}
    //   </tbody>
    // </table>
    <table
      className="hide-scrollbar table-fixed table overflow-auto  w-full border-collapse "
      {...getTableProps()}
    >
      <thead>
        {headerGroups?.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => {
              return (
                <th
                  className={` table-head text-neutral-900 text-sm font-normal px-4 pb-2`}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows?.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, j) => {
                return (
                  <td
                    className={
                      j === 0 && row.canExpand
                        ? "highlighted table-body border border-slate-300 bg-primary-100 text-tertiary4-500 text-center text-sm "
                        : "notHighlighted table-body border border-slate-300 text-center text-sm py-2"
                    }
                    // className={`table-body border border-slate-300 text-center text-sm ${
                    //   j === 1 && row.canExpand
                    //     ? "highlighted bg-primary-100 text-tertiary4-500"
                    //     : `${
                    //         j === 0 && row.canExpand
                    //           ? "highlighted bg-primary-100 text-tertiary4-500"
                    //           : "py-2"
                    //       }`
                    // }`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

// const gridData = [
//     {
//       Particulars: "Profit",
//       January: 100,
//       February: 200,
//       March: 100,
//       April: 100,
//       May: 100,
//       June: 200,
//       July: 100,
//       August: 100,
//       Sept: 200,
//       Oct: 100,
//       Nov: 100,
//       Dec: 200,
//       subRows: [
//         {
//           Particulars: "Profit 1",
//           January: 100,
//           February: 200,
//           March: 100,
//           April: 100,
//           May: "",
//           June: "",
//           July: "",
//           August: "",
//           Sept: "",
//           Oct: 100,
//           Nov: 100,
//           Dec: 200,
//         },
//         {
//           Particulars: "Profit 2",
//           January: 100,
//           February: 200,
//           March: 100,
//           April: 100,
//           May: 100,
//           June: 200,
//           July: 100,
//           August: 100,
//           Sept: 200,
//           Oct: 100,
//           Nov: 100,
//           Dec: 200,
//         },
//       ],
//     },
//     {
//       Particulars: "Loss",
//       January: 400,
//       February: 200,
//       March: 100,
//       April: 100,
//       May: 100,
//       June: 200,
//       July: 100,
//       August: 100,
//       Sept: 200,
//       Oct: 100,
//       // subRows: [],
//       subRows: [
//         {
//           Particulars: "Loss 1",
//           January: 100,
//           February: 200,
//           March: 100,
//           April: 100,
//           Nov: 100,
//           Dec: 200,
//         },
//         {
//           Particulars: "Loss 2",
//           January: 100,
//           February: 200,
//           March: 100,
//           April: 100,
//           May: 100,
//           June: 200,
//           July: 100,
//           August: 100,
//           Sept: 200,
//           Oct: 100,
//           Nov: 100,
//           Dec: 200,
//         },
//       ],
//     },
//   ];

// const ListTable = ({ item }) => {
//   const [showList, setShowList] = useState(false);
//   const { subRows, ...rest } = item;
//   const itemWithoutSubRows = rest;

//   return (
//     <>
//       <tr>
//         {Object.values(itemWithoutSubRows).map((itemV: any) => {
//           return <td onClick={() => setShowList(!showList)}>{itemV}</td>;
//         })}
//       </tr>

//       {showList &&
//         item.subRows.length > 0 &&
//         item.subRows.map((item) => {
//           return (
//             <tr>
//               {Object.values(item).map((itemV: any) => (
//                 <td>{itemV}</td>
//               ))}
//             </tr>
//           );
//         })}
//     </>
//   );
// };

// export default function FinancialTable() {
//   const gridDataWithoutSubRows = gridData.map(({ subRows, ...rest }) => {
//     return { ...rest };
//   });

//   const columns = Object.keys(gridDataWithoutSubRows[0]).map((item) => item);

//   return (
//     <div>
//       <table>
//         <thead>
//           <tr>
//             {columns.map((item) => (
//               <th className="px-4">{item}</th>
//             ))}
//           </tr>
//         </thead>

//         <tbody>
//           {gridData.map((item) => (
//             <ListTable item={item} />
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }
