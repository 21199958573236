import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import Button from "src/components/Button";

import DownloadPDFWorkModal from "../DownloadPDFWordModal";
import DownloadTemplateModal from "../DownloadTemplateModal";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function NDA() {
  const [formData, setFormData] = useState({});
  const [showCopyDownloadModal, setShowCopyDownloadModal] = useState(false);
  const [showDownloadTemplate, setShowDownloadTemplate] = useState(false);

  const navigate = useNavigate();
  const { docName } = useParams();
  const { state } = useLocation();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const handleFromSubmit = (data) => {
    const updatedObj = {
      from_company_name: data.companyName || "",
      from_brand_name: data.brandName || "",
      from_complete_address: data.completeAddress || "",
      from_representatives_name: data.repName || "",
      from_representatives_title: data.repTitle || "",
      to_company_name: data.TocompanyName || "",
      to_brand_name: data.TobrandName || "",
      to_complete_address: data.TocompleteAddress || "",
      to_representatives_name: data.TorepName || "",
      to_representatives_title: data.TorepTitle || "",
      date_of_execution: data.date_format || "",
    };
    setFormData((prev) => ({ ...prev, updatedObj }));
    setShowCopyDownloadModal(true);
    // setactivateToCompo(true);
  };

  return (
    <>
      <div className="docComponentRender">
        <div className="flex items-center justify-between border-b border-neutral-400 gap-4 pl-[30px] pr-5">
          <div className="flex items-center gap-6">
            <span className="text-xl text-neutral-900 font-semibold inline-block py-5">
              Generate {state?.documentName || "Non-Disclosure Agreement"} (NDA)
            </span>
          </div>
          <div className="flex gap-4">
            <div
              onClick={() => setShowDownloadTemplate(!showDownloadTemplate)}
              className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>

              <span>Download Template</span>
            </div>
            <div
              onClick={() => navigate("/documentGeneration/requestCustom")}
              // onClick={() => setShowShareModal(!showShareModal)}
              className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>

              <span>Request Custom</span>
            </div>
          </div>
        </div>
        <div className="activeFromComp ml-[30px] mr-5 mt-6">
          <p className="text-neutral-900">
            Enter below details to generate a prefilled NDA document!
          </p>
          <p className="mt-5 mb-1 font-semibold text-neutral-900">From:</p>
          <form onSubmit={handleSubmit(handleFromSubmit)}>
            <div className="fromGroup space-y-6 pt-4">
              <div className="flex gap-6">
                <div className="w-full">
                  <input
                    {...register("companyName", {
                      required: {
                        value: true,
                        message: "Company name is required",
                      },
                    })}
                    id="companyName"
                    name="companyName"
                    type="text"
                    className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Company Name"
                  />
                  <ErrorTest errors={errors} field="companyName" />
                </div>
                <div className="w-full">
                  <input
                    {...register("brandName", {
                      required: {
                        value: true,
                        message: "Brand name is required",
                      },
                    })}
                    id="brandName"
                    name="brandName"
                    type="text"
                    className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Brand Name"
                  />
                  <ErrorTest errors={errors} field="brandName" />
                </div>
              </div>
              <div>
                <input
                  {...register("completeAddress", {
                    required: {
                      value: true,
                      message: "Address is required",
                    },
                  })}
                  id="completeAddress"
                  name="completeAddress"
                  type="text"
                  className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Complete Address"
                />
                <ErrorTest errors={errors} field="completeAddress" />
              </div>
              <div className="flex gap-6">
                <div className="w-full">
                  <input
                    {...register("repName", {
                      required: {
                        value: true,
                        message: "Representative's Name is required",
                      },
                    })}
                    id="repName"
                    name="repName"
                    type="text"
                    className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Representative's Name"
                  />
                  <ErrorTest errors={errors} field="repName" />
                </div>
                <div className="w-full">
                  <input
                    {...register("repTitle", {
                      required: {
                        value: true,
                        message: "Representative's Title is required",
                      },
                    })}
                    id="repTitle"
                    name="repTitle"
                    type="text"
                    className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Representative's Title/Designation"
                  />
                  <ErrorTest errors={errors} field="repTitle" />
                </div>
              </div>
            </div>
            <p className="mt-5 mb-4 font-semibold text-neutral-900">To:</p>
            <div className="toGroup space-y-6">
              <div className="flex gap-6">
                <div className="w-full">
                  <input
                    {...register("TocompanyName", {
                      required: {
                        value: true,
                        message: "Company name is required",
                      },
                    })}
                    id="TocompanyName"
                    name="TocompanyName"
                    type="text"
                    className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Company Name"
                  />
                  <ErrorTest errors={errors} field="TocompanyName" />
                </div>
                <div className="w-full">
                  <input
                    {...register("TobrandName", {
                      required: {
                        value: true,
                        message: "Brand name is required",
                      },
                    })}
                    id="TobrandName"
                    name="TobrandName"
                    type="text"
                    className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Brand Name"
                  />
                  <ErrorTest errors={errors} field="TobrandName" />
                </div>
              </div>
              <div>
                <input
                  {...register("TocompleteAddress", {
                    required: {
                      value: true,
                      message: "Address is required",
                    },
                  })}
                  id="TocompleteAddress"
                  name="TocompleteAddress"
                  type="text"
                  className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Complete Address"
                />
                <ErrorTest errors={errors} field="TocompleteAddress" />
              </div>
              <div className="flex gap-6">
                <div className="w-full">
                  <input
                    {...register("TorepName", {
                      required: {
                        value: true,
                        message: "Representative's Name is required",
                      },
                    })}
                    id="TorepName"
                    name="TorepName"
                    type="text"
                    className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Representative's Name"
                  />
                  <ErrorTest errors={errors} field="TorepName" />
                </div>
                <div className="w-full">
                  <input
                    {...register("TorepTitle", {
                      required: {
                        value: true,
                        message: "Representative's Title is required",
                      },
                    })}
                    id="TorepTitle"
                    name="TorepTitle"
                    type="text"
                    className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Representative's Title/Designation"
                  />
                  <ErrorTest errors={errors} field="TorepTitle" />
                </div>
              </div>

              <div>
                <label className="text-neutral-900 font-semibold">
                  Date of Execution:
                </label>
                <input
                  className="my-4 relative block appearance-none rounded border border-neutral-500 px-3 py-1 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  type="date"
                  id="date_format"
                  name="date_format"
                  //   onKeyDown={(event) => {
                  //     event.preventDefault();
                  //   }}
                  {...register("date_format", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                  // max={new Date().toISOString().split("T")[0]}
                />
              </div>

              <Button
                // disabled={loading}
                // loading={loading}
                className="group relative flex justify-center rounded text-white focus:outline-none text-base"
              >
                Generate Document
              </Button>
            </div>
          </form>
        </div>{" "}
      </div>
      <DownloadPDFWorkModal
        open={showCopyDownloadModal}
        closeModal={setShowCopyDownloadModal}
        updatedObj={formData}
        docType="nda"
        reset={reset}
      />
      {/* <CopyDownloadModal
        open={showCopyDownloadModal}
        closeModal={setShowCopyDownloadModal}
      /> */}
      <DownloadTemplateModal
        open={showDownloadTemplate}
        closeModal={setShowDownloadTemplate}
        templateCode="nda"
      />
    </>
  );
}
