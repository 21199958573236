import React from "react";
import { Link } from "react-router-dom";

export default function FinancialYearInitial() {
  return (
    <div>
      <p className="text-lg text-neutral-900">
        Your financial reports are not generated yet.
      </p>
      <p className="text-lg text-neutral-900 mt-6">
        Want to have a financial overview of your business? Complete the below 2
        steps.
      </p>

      <div className="mt-6">
        <p className="text-lg text-neutral-900">1. Add your bank accounts</p>
        <Link
          to={"/"}
          className="ml-4 mt-3 inline-flex items-center border border-transparent bg-neutral-300 px-16 py-2 text-base font-medium text-neutral-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Start
        </Link>
      </div>

      <div className="mt-2">
        <p className="text-lg text-neutral-900 mt-6">
          2. Add invoices and other cashflow documents like expenses, payroll
          etc.
        </p>
        <Link
          to={"/invoice"}
          className="ml-4 mt-3 inline-flex items-center border border-transparent bg-neutral-300 px-16 py-2 text-base font-medium text-neutral-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Start
        </Link>
      </div>
    </div>
  );
}
