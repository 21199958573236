import React from "react";
import { useFormContext } from "react-hook-form";
import Button from "src/components/Button";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function CompanyAgree({ setShowClientFunnel }) {
  const {
    register,
    watch,
    trigger,
    formState: { errors: formErrors },
  } = useFormContext();

  const communicationValue = watch("communication");
  console.log("object", communicationValue);

  console.log("errors", formErrors);

  return (
    <div className="mt-4">
      <label className="text-neutral-900 font-semibold">
        Name, country of incorporation and registered address of the company:{" "}
      </label>
      <div className="mt-4">
        <input
          {...register("spName", {
            required: {
              value: true,
              message: "Company name is required",
            },
            minLength: {
              value: 3,
              message: "Company name should be between 3 to 50 characters",
            },
            maxLength: {
              value: 50,
              message: "Company name should be between 3 to 50 characters",
            },
          })}
          id="spName"
          name="spName"
          type="text"
          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          placeholder="Company Name"
        />
        <ErrorTest errors={formErrors} field="spName" />
      </div>
      <div className="mt-4">
        <input
          {...register("COI", {
            required: {
              value: true,
              message: "Country of Incorporation is required",
            },
          })}
          id="COI"
          name="COI"
          type="text"
          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          placeholder="Country of Incorporation"
        />
        <ErrorTest errors={formErrors} field="COI" />
      </div>
      <div className="mt-4">
        <input
          {...register("registeredAddress", {
            required: {
              value: true,
              message: "Address is required",
            },
          })}
          id="registeredAddress"
          name="registeredAddress"
          type="text"
          className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          placeholder="Registered Address"
        />
        <ErrorTest errors={formErrors} field="registeredAddress" />
      </div>

      <div className="mt-4">
        <label className="text-neutral-900 font-semibold">
          Is service provider's communication address same as above mentioned
          address?{" "}
        </label>
      </div>

      <div className="mt-4">
        <input
          {...register("communication")}
          type="radio"
          value="yes"
          id="communication"
        />
        {"  "}
        Yes
      </div>
      <div className="mt-2">
        <input
          {...register("communication")}
          type="radio"
          value="no"
          id="communication"
        />
        {"  "}
        No
      </div>

      {communicationValue === "yes" && (
        <>
          <div className="mt-4">
            <label className="text-neutral-900 font-semibold">
              Name of the person representing the service provider:
            </label>
          </div>

          <div className="mt-4">
            <input
              {...register("serviceProviderName", {
                required: {
                  value: true,
                  message: "Full name is required",
                },
              })}
              id="serviceProviderName"
              name="serviceProviderName"
              type="text"
              className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              placeholder="Full Name"
            />
            <ErrorTest errors={formErrors} field="serviceProviderName" />
          </div>
          <Button
            onClick={async () => {
              const result = await trigger();
              console.log(result);
              if (result) {
                setShowClientFunnel(true);
              }
            }}
            type="button"
            className="text-white rounded my-4"
          >
            {" "}
            Continue
          </Button>
        </>
      )}

      {communicationValue === "no" && (
        <>
          <div className="mt-4">
            <label className="text-neutral-900 font-semibold">
              Communication address of the service provider:{" "}
            </label>
          </div>

          <div className="mt-4">
            <input
              {...register("communicationAddress", {
                required: {
                  value: true,
                  message: "Communication Address is required",
                },
              })}
              id="communicationAddress"
              name="communicationAddress"
              type="text"
              className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              placeholder="Communication Address"
            />
            <ErrorTest errors={formErrors} field="communicationAddress" />
          </div>

          <div className="mt-4">
            <label className="text-neutral-900 font-semibold">
              Name of the person representing the service provider:
            </label>
          </div>

          <div className="mt-4">
            <input
              {...register("serviceProviderName", {
                required: {
                  value: true,
                  message: "Full name is required",
                },
              })}
              id="serviceProviderName"
              name="serviceProviderName"
              type="text"
              className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              placeholder="Full Name"
            />
            <ErrorTest errors={formErrors} field="serviceProviderName" />
          </div>

          <Button
            onClick={async () => {
              const result = await trigger();
              console.log(result);
              if (result) {
                setShowClientFunnel(true);
              }
            }}
            type="button"
            className="text-white rounded my-4"
          >
            {" "}
            Continue
          </Button>
        </>
      )}
    </div>
  );
}
