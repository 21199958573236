import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useAuth } from "src/context/AuthProvider";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";
import { toast, ToastContainer } from "react-toastify";
import Button from "src/components/Button";
import { useDocumentData } from "src/context/DocumentProvider";
import { useFormationDocuments } from "src/hooks/useFormationDocuments";
import { useCompanyData } from "src/context/CompanyDataProvider";
import { useComplianceDocuments } from "src/hooks/useComplianceDocuments";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function AddCustomRowComplianceModal({
  open,
  closeModal,
  selectedCategory,
}) {
  const [loading, setLoading] = useState(false);
  const {
    state: authData,
    fetchTokenManually,
    idToken,
    state: UserData,
    reloadUserData,
  } = useAuth();
  const {
    state: docData,
    selectedValues,
    setSelectedValues,
    reloadComplainceDocumentData,
    reloadPath,
  } = useDocumentData();
  const { addCustomDocuments } = useFormationDocuments();
  const { renameComplianceFile, getComplianceDocs } = useComplianceDocuments();
  const { state: CompanyData } = useCompanyData();

  const methods = useForm({
    defaultValues: {
      documentName: "",
      documentCategory: selectedCategory,
      fileType: "singleFile",
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset({ documentCategory: selectedCategory });
  }, [reset, selectedCategory, open, closeModal]);

  const onSubmit = async (data) => {
    setLoading(true);
    const updatedToken = await fetchTokenManually();
    const token = updatedToken || authData?.idTokenUpdated || idToken;
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");

    const multipleUpload = data?.fileType == "multipleFile" ? true : false;
    const docCat = data?.documentCategory || selectedCategory;
    const path = docData?.path || [];

    try {
      const res: any = await addCustomDocuments(
        companyId,
        token,
        data?.documentName,
        multipleUpload,
        docCat,
        path
      );
      if (res) {
        console.log("res link", res);
        const allComplianceDocs = await getComplianceDocs(token, companyId);
        reloadComplainceDocumentData(allComplianceDocs);

        setLoading(false);
        closeModal(false);
        reset();
        reloadPath([]);
        toast.success("Row added in the opened category", {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong!! Please try again later",
        {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      setLoading(false);
      closeModal(false);
      reset();
      reloadPath([]);
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[12]" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className=" inset-0 overflow-hidden">
            <div className=" inset-0 overflow-hidden">
              <div className="fixed top-[76px] inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-sm">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white pb-6 shadow-xl">
                      <div className="border-b border-neutral-400 px-4 sm:px-6">
                        <div className="flex items-center justify-between w-full h-[72px]">
                          <Dialog.Title className="text-xl font-medium text-gray-900">
                            Add Custom Document
                          </Dialog.Title>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                              onClick={() => {
                                closeModal(false);
                                reset();
                              }}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-5 flex-1 px-4 sm:px-6">
                        <div className="absolute inset-0 px-4 sm:px-6">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div>
                              <p className="text-base text-neutral-900 font-medium">
                                Enter the name of the document you would like to
                                add here:
                              </p>{" "}
                            </div>
                            <div className="w-full mt-4">
                              <input
                                {...register("documentName", {
                                  required: {
                                    value: true,
                                    message: "Document name is required",
                                  },
                                })}
                                id="documentName"
                                name="documentName"
                                type="text"
                                className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="Name of Document"
                              />
                              <ErrorTest errors={errors} field="documentName" />
                            </div>

                            <div className="mt-4">
                              <label className="text-neutral-900 font-semibold">
                                Select if there would be single file or multiple
                                files:{" "}
                              </label>
                            </div>

                            <div className="mt-4">
                              <input
                                {...register("fileType")}
                                type="radio"
                                value="singleFile"
                                id="fileType"
                                defaultChecked
                              />
                              {"  "}
                              Single File
                            </div>

                            <div className="mt-2">
                              <input
                                {...register("fileType")}
                                type="radio"
                                value="multipleFile"
                                id="fileType"
                              />
                              {"  "}
                              Multiple File
                            </div>
                            <Button className="mt-4 px-5 text-white rounded bg-tertiary4-500">
                              + Add
                            </Button>
                          </form>
                          <p className="text-base text-neutral-900 mt-4">
                            A new document row would be added at the end of the
                            list in the opened category.
                          </p>{" "}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
}
