// import Accordian from "components/Accordian";
import React, { useEffect, useState } from "react";
import {
  useLocation,
  useSearchParams,
  useNavigate,
  useParams,
  Link,
} from "react-router-dom";
import Accordian from "src/components/Accordian";

import AddNewFolderModal from "src/components/InvoiceModal/AddNewFolderModal";
import NewContextMenu from "src/components/NewContextMenu";
import { useCompanyData } from "src/context/CompanyDataProvider";
import { getCurrentFinancialYear } from "src/services/helpers/getCurrentFinancialYear";
import {
  PencilSquareIcon,
  XMarkIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";

import "../invoice.scss";
import OpenFolderModal from "src/components/InvoiceModal/OpenFolder";
import { useAuth } from "src/context/AuthProvider";
import { useInvoice } from "src/hooks/useInvoice";
import { toast } from "react-toastify";
import UploadFileModal from "src/components/InvoiceModal/UploadFileModal";
import RenameFolderModal from "src/components/InvoiceModal/RenameFolderModal";
import RenameFileModal from "src/components/InvoiceModal/RenameFileModal";
import DeleteFolderConfirmationModal from "src/components/InvoiceModal/DeleteFolderConfirmationModal";
import DeleteFileConfirmationModal from "src/components/InvoiceModal/DeleteFileConfirmationModal";

const particularFolderData = {
  path: [
    {
      name: "..",
      href: "/invoice/2022-2023", // fYear will be dynamic
    },
    {
      name: "October 2022",
      href: "/invoice/2022-2023/February 2022",
    },
    {
      name: "Sales Invoice",
      href: "/invoice/2022-2023/February 2022/124",
    },
    {
      name: "Purchase Invoice",
      href: "#", // This will be not required as we are on this current route
    },
  ],
  folders: [
    {
      folderName: "Sales Invoice HR",
      folderId: "125",
    },
    {
      folderName: "Purchase Invoice HR",
      folderId: "126",
    },
  ],
  files: [{ fileName: "Invoice 222" }],
};

const menuItems = [
  {
    name: "Rename",
    icon: PencilSquareIcon,
  },
  {
    name: "Download",
    icon: ArrowDownTrayIcon,
  },
  {
    name: "Delete",
    icon: XMarkIcon,
  },
];

const folderItems = [
  {
    name: "Rename",
    icon: PencilSquareIcon,
  },
  {
    name: "Delete",
    icon: XMarkIcon,
  },
];

const folderItemsMember = [];

const menuItemsMember = [
  {
    name: "Download",
    icon: ArrowDownTrayIcon,
  },
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function InvoiceFolder() {
  const [fYearList, setFYearList] = useState([getCurrentFinancialYear()]);
  const [showAddFolder, setshowAddFolder] = useState(false);
  const [showOpenFolder, setShowOpenFolder] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [folderClickedId, setFolderClickedId] = useState("");
  const [oldFolderName, setOldFolderName] = useState("");
  const [fileClickedId, setFileClickedId] = useState("");
  const [oldFileName, setOldFileName] = useState("");
  const [showRenameFile, setShowRenameFile] = useState(false);
  const [showRenameFolder, setShowRenameFolder] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDeleteFileConfirmation, setShowDeleteFileConfirmation] =
    useState(false);
  const [showDeleteFolderConfirmation, setShowDeleteFolderConfirmation] =
    useState(false);
  const [deleteName, setDeleteName] = useState("");

  const [initialFolderDetails, setInitialFolderDetails] = useState({});
  const [particlarFolderData, setParticlarFolderData] = useState({
    folders: [],
    files: [],
    path: [],
  });
  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();
  const { fYear, monthName, folderId } = useParams();
  const location = useLocation();
  const hiddenFileInput = React.useRef(null);

  const { idToken } = useAuth();
  const {
    getListOfFinancialYear,
    getParticularFolderData,
    deleteFolder,
    downloadFile,
    deleteFile,
  } = useInvoice();
  const { state: CompanyData } = useCompanyData();

  console.log(fYear, monthName, folderId);
  // let fYear = search.get("fy") || getCurrentFinancialYear();
  const financialYear = fYear || getCurrentFinancialYear();
  const companyId = CompanyData?.id || window.localStorage.getItem("companyId");

  useEffect(() => {
    console.log("Inside folder component", folderId);
  }, [folderId]);

  const handleChange = (e) => {
    // console.log(e.target.value);
    const splitFYear = e.target.value.split(" ");
    // console.log(splitFYear[1]);
    navigate(`/invoice/${splitFYear[1]}`);
    // setSearch({ fy: e.target.value });
  };

  const handleFileClick = async (
    clickAction,
    fileClickedId,
    monthName,
    oldFileName
  ) => {
    console.log(clickAction);
    console.log(fileClickedId);
    console.log(monthName);
    console.log(oldFileName);

    if (clickAction === "Download") {
      downloadFile(companyId, financialYear, monthName, fileClickedId, idToken)
        .then((res) => {
          console.log(res);
          const href = URL.createObjectURL(res.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", oldFileName); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (clickAction === "Delete") {
      setFileClickedId(fileClickedId);
      setDeleteName(oldFileName);
      setShowDeleteFileConfirmation(!showDeleteFileConfirmation);
    } else {
      setFileClickedId(fileClickedId);
      setOldFileName(oldFileName);
      setShowRenameFile(!showRenameFile);
    }
  };

  const handleFolderClick = async (
    clickAction,
    folderClickedId,
    monthName,
    oldFolderName
  ) => {
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");
    // console.log(clickAction);
    // console.log(folderClickedId);
    // console.log(monthName);
    // console.log(oldFolderName);
    if (clickAction === "Rename") {
      setFolderClickedId(folderClickedId);
      setOldFolderName(oldFolderName);
      clickAction === "Rename" && setShowRenameFolder(!showRenameFolder);
    } else {
      setFolderClickedId(folderClickedId);
      setDeleteName(oldFolderName);
      setShowDeleteFolderConfirmation(!showDeleteFolderConfirmation);
    }
  };
  // const handleDoubleClick = (itemDetails) => {
  //   console.log(itemDetails);
  //   navigate(`/invoice/${fYear}/${monthName}/${itemDetails.folderId}`);
  // };

  const handleDoubleClick = (folderId) => {
    console.log(folderId);
    navigate(`/invoice/${fYear}/${monthName}/${folderId}`);
  };

  const handleFileDoubleClick = (fileClickedId, monthName, fileName) => {
    downloadFile(companyId, financialYear, monthName, fileClickedId, idToken)
      .then((res) => {
        console.log(res);
        const href = URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleAddFileClick = () => {
    // hiddenFileInput.current.click();
    setShowUploadModal(!showUploadModal);
  };

  // const handleFileChange = (e) => {
  //   const fileUploaded = e.target.files[0];
  //   console.log(fileUploaded);
  // };

  useEffect(() => {
    const fetchParticularDataInMonth = async () => {
      if (idToken) {
        const companyId =
          CompanyData?.id || window.localStorage.getItem("companyId");

        try {
          const fYearList = await getListOfFinancialYear(companyId, idToken);

          const res = await getParticularFolderData(
            companyId,
            fYear,
            monthName,
            folderId,
            idToken
          );
          if (res) {
            console.log(res);
            setFYearList(fYearList.data);

            setParticlarFolderData(res.data);
            setLoading(false);
          }
        } catch (error) {
          console.log("error", error);
          setLoading(false);
          toast.error(
            error?.response?.data?.message || "Something went wrong!!",
            {
              className: "toast-position",
              position: "bottom-center",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      } else {
        console.log("token not found");
        setLoading(false);
      }
    };

    fetchParticularDataInMonth();
  }, [fYear, monthName, idToken, fetchAgain, setFetchAgain]);

  console.log(particlarFolderData);

  const getCurrentTitle = () => {
    if (location.pathname.includes("/financial")) {
      return "Financials";
    }

    if (location.pathname.includes("/invoice")) {
      return "Monthly Invoices";
    }

    switch (location.pathname) {
      case "/":
        return "Bank Accounts";
      case "/invoice":
        return "Monthly Invoices";
      case "/settings":
        return "Settings";
      case "/co-workers":
        return "Co-workers";
      case "/account":
        return "Your Account";
      case "/gethelp":
        return "Get Help";
      case "/invite":
        return "Invite";
      default:
        return "";
    }
  };

  return (
    <div className="particularFolderData">
      {/* <div className="fixed top-2.5 ml-64 md:ml-52 z-10"> */}
      {/* <div className="fixed top-2.5 ml-64 md:ml-52 z-10">
        <select
          id="fYear"
          name="fYear"
          className="mt-1 block cursor-pointer rounded-md border-tertiary4-500 py-2 pl-3 pr-10 text-base hover:bg-primary-100 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500 sm:text-sm"
          onChange={handleChange}
          defaultValue={fYearList[0] || `FY ${getCurrentFinancialYear()}`}
        >
          <option value="" disabled selected hidden>
            Select Year
          </option>

          {fYearList.map((item) => {
            return <option value={item}>{item}</option>;
          })}
        </select>
      </div> */}
      <div className="flex items-center gap-5 z-10 pl-[30px] border-b border-neutral-400">
        <span className="text-xl text-neutral-900 font-medium inline-block py-5">
          {getCurrentTitle()}
        </span>
        <select
          id="fYear"
          name="fYear"
          className="mt-1 block cursor-pointer rounded-md border-neutral-500 py-2 pl-3 pr-10 text-base hover:bg-primary-100 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500 sm:text-sm"
          onChange={handleChange}
          defaultValue={fYearList[0] || `FY ${getCurrentFinancialYear()}`}
        >
          <option value="" disabled selected hidden>
            Select Year
          </option>

          {fYearList.map((item) => {
            return <option value={item}>{item}</option>;
          })}
        </select>
      </div>
      <div className="pl-[30px] pr-5 mt-6 mb-20">
        {/* <div className="breadCrumb">
          <Link to={`/invoice/${fYear}`}>
            {" "}
            <span className="titleActive cursor-pointer">..</span>{" "}
          </Link>
          <span className="mx-2">/</span>
          <Link to={`/invoice/${fYear}/${monthName}`}>
            {" "}
            <span className="titleActive cursor-pointer">{monthName}</span>{" "}
          </Link>

          <span className="mx-2">/</span>
          <span className="titleActive">{folderId}</span>
        </div> */}
        <div className="breadCrumb">
          <Link to={`/invoice/${fYear}`}>
            {" "}
            <span className="titleActive cursor-pointer">..</span>{" "}
          </Link>
          <span className="mx-2">/</span>
          <Link to={`/invoice/${fYear}/${monthName}`}>
            {" "}
            <span className="titleActive cursor-pointer">{monthName}</span>{" "}
          </Link>
          <span className="mx-2">/</span>
          {particlarFolderData.path.length > 0 &&
            particlarFolderData?.path?.map((item, index) => (
              <Link
                className="item "
                to={`/invoice/${fYear}/${monthName}/${item.id}`}
              >
                <span className="titleActive cursor-pointer">{item.name}</span>
              </Link>
            ))}
        </div>
        {loading ? (
          <div className="hor-ver-center fixed">
            <p>Loading...</p>
          </div>
        ) : (
          <>
            <div className="mt-5">
              <p className="mb-4 text-neutral-900 text-sm font-semibold">
                Folders
              </p>
              <div className="flex flex-wrap flex-1 gap-y-5">
                {particlarFolderData.folders.length > 0 ? (
                  particlarFolderData?.folders?.map((item, index) => (
                    <NewContextMenu
                      // items={folderItems}
                      items={
                        CompanyData?.userRole !== "MEMBER"
                          ? folderItems
                          : folderItemsMember
                      }
                      targetId={item.folderId}
                      data={{
                        monthName: monthName,
                        folderName: item.folderName,
                      }}
                      handleClick={handleFolderClick}
                    >
                      <div
                        key={index}
                        onDoubleClick={() => handleDoubleClick(item.folderId)}
                        id={item.folderId}
                        className={`cursor-default flex items-center justify-between p-4 w-[190px] h-[49px] border border-neutral-300 bg-white shadow-[0_4px_4px_rgba(23,26,31,0.1)] mr-5`}
                      >
                        <span
                          title={item.folderName}
                          className="text-sm text-neutral-900 ellipseFolderName"
                        >
                          {item.folderName}
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                          />
                        </svg>
                      </div>
                    </NewContextMenu>
                  ))
                ) : (
                  <p className="block w-full text-neutral-900 text-sm">
                    There are no folders added for {monthName}
                    {/* {months[new Date().getMonth()]} {new Date().getFullYear()}. */}
                  </p>
                )}
                {CompanyData?.userRole !== "MEMBER" && (
                  <div
                    onClick={() => setshowAddFolder(true)}
                    className="cursor-pointer flex items-center justify-center p-4 w-[190px] h-[49px] bg-white hover:bg-primary-100 hover:border-none border-dashed border-neutral-700 border mr-5"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-tertiary4-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v12m6-6H6"
                      />
                    </svg>
                    <span className="text-sm text-tertiary4-500">
                      Make New Folder
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-5">
              <p className="mb-4 text-neutral-900 text-sm font-semibold">
                Files
              </p>
              <div className="flex flex-wrap flex-1 gap-y-5">
                {particlarFolderData.files.length > 0 ? (
                  particlarFolderData?.files?.map((item, index) => (
                    <NewContextMenu
                      // items={menuItems}
                      items={
                        CompanyData?.userRole !== "MEMBER"
                          ? menuItems
                          : menuItemsMember
                      }
                      targetId={item.fileId}
                      data={{
                        monthName: monthName,
                        folderName: item.fileName,
                      }}
                      handleClick={handleFileClick}
                    >
                      <div
                        onDoubleClick={() => {
                          handleFileDoubleClick(
                            item.fileId,
                            monthName,
                            item.fileName
                          );
                        }}
                        key={index}
                        id={item.fileId}
                        className={`${item.fileId} cursor-default flex items-center justify-between p-4 w-[190px] h-[49px] border border-neutral-300 bg-white shadow-[0_4px_4px_rgba(23,26,31,0.1)] mr-5`}
                      >
                        <span
                          title={item.fileName}
                          className="text-sm text-neutral-900 ellipseFolderName"
                        >
                          {item.fileName}
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>
                      </div>
                    </NewContextMenu>
                  ))
                ) : (
                  <p className="block w-full text-neutral-900 text-sm">
                    There are no files added for {monthName}
                    {/* {months[new Date().getMonth()]}{" "} */}
                    {/* {new Date().getFullYear()}. */}
                  </p>
                )}

                {CompanyData?.userRole !== "MEMBER" && (
                  <div
                    onClick={handleAddFileClick}
                    className="cursor-pointer flex items-center justify-center p-4 w-[190px] h-[49px] bg-white hover:bg-primary-100 hover:border-none border-dashed border-neutral-700 border mr-5"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-tertiary4-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v12m6-6H6"
                      />
                    </svg>
                    <span className="text-sm text-tertiary4-500">Add File</span>
                  </div>
                )}
                {/* <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                /> */}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="stickyNotification fixed bottom-4 cursor-default flex items-center justify-between p-2.5 flex gap-4 border border-neutral-300 bg-white shadow-[0_4px_4px_rgba(23,26,31,0.1)] mx-[30px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>

        <p className="text-neutral-900">
          Generally, teams update their invoices and documents regularly to not
          miss the correctness of the financial report. The report of each month
          is generated on the 5th of next month.
        </p>
      </div>

      {/* <AddNewFolderModal open={showAddFolder} closeModal={setshowAddFolder} /> */}
      <AddNewFolderModal
        fYear={financialYear}
        monthName={monthName}
        open={showAddFolder}
        fetchAgain={fetchAgain}
        parentFolderId={folderId || ""}
        closeModal={setshowAddFolder}
        setFetchAgain={setFetchAgain}
      />
      <RenameFolderModal
        fYear={financialYear}
        monthName={monthName}
        folderClickedId={folderClickedId}
        open={showRenameFolder}
        fetchAgain={fetchAgain}
        oldFolderName={oldFolderName}
        closeModal={setShowRenameFolder}
        setFetchAgain={setFetchAgain}
      />
      <RenameFileModal
        fYear={financialYear}
        monthName={monthName}
        fileClickedId={fileClickedId}
        open={showRenameFile}
        fetchAgain={fetchAgain}
        oldFileName={oldFileName}
        closeModal={setShowRenameFile}
        setFetchAgain={setFetchAgain}
      />
      <UploadFileModal
        fYear={financialYear}
        monthName={monthName}
        open={showUploadModal}
        fetchAgain={fetchAgain}
        parentFolderId={folderId || ""}
        closeModal={setShowUploadModal}
        setFetchAgain={setFetchAgain}
      />
      <DeleteFolderConfirmationModal
        open={showDeleteFolderConfirmation}
        closeModal={setShowDeleteFolderConfirmation}
        fYear={financialYear}
        monthName={monthName}
        folderClickedId={folderClickedId}
        name={deleteName}
        fetchAgain={fetchAgain}
        setFetchAgain={setFetchAgain}
      />
      <DeleteFileConfirmationModal
        open={showDeleteFileConfirmation}
        closeModal={setShowDeleteFileConfirmation}
        fYear={financialYear}
        monthName={monthName}
        fileClickedId={fileClickedId}
        name={deleteName}
        fetchAgain={fetchAgain}
        setFetchAgain={setFetchAgain}
      />
    </div>
  );
}
