import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import FinancialShareModal from "src/components/Financial/FinancialShareModal";
import FinancialTable from "src/components/Financial/Table";
import { useAuth } from "src/context/AuthProvider";
import { useCompanyData } from "src/context/CompanyDataProvider";
import "./index.scss";

// const data = [
//   {
//     RowName: "Profit",
//     children: [
//       {
//         January: 100,
//         February: 200,
//         March: 100,
//         April: 100,
//         May: 100,
//         June: 200,
//         July: 100,
//         August: 100,
//         Sept: 200,
//         Oct: 100,
//         Nov: 100,
//         Dec: 200,
//       },
//     ],
//   },
// ];

// const data2 = [
//   {
//     Particulars: "Profit",
//     January: 100,
//     February: 200,
//     March: 100,
//     April: 100,
//     May: 100,
//     June: 200,
//     July: 100,
//     August: 100,
//     Sept: 200,
//     Oct: 100,
//     Nov: 100,
//     Dec: 200,
//   },
// ];

export default function FinancialYear() {
  const [showShareModal, setShowShareModal] = useState(false);
  const { idToken } = useAuth();
  const { state: CompanyData, reloadCompnayData } = useCompanyData();
  const { financialYear, sheetId } = useParams();
  const location = useLocation();
  console.log(location);
  const { tableData, tableColumns } = location.state;

  console.log("from state tableData", tableData);
  console.log("from state tableColumns", tableColumns);

  // useEffect(() => {
  //   if (idToken && CompanyData) {
  //     const res = CompanyData?.financials
  //       .find((item) => item.financialYear === financialYear)
  //       .children.find((item) => item.sheetId === sheetId).sheetData;
  //     console.log(res);
  //     setNewGridData(res);
  //   }
  // }, []);

  // console.log("newGridData fYear", newGridData);

  let resTableData = [];
  let resTableColumn = [];
  if (idToken && CompanyData) {
    resTableData =
      tableData ||
      CompanyData?.financials
        ?.find((item) => item.financialYear === financialYear)
        ?.children?.find((item) => item.sheetId === sheetId)?.sheetData;

    resTableColumn =
      tableColumns ||
      CompanyData?.financials
        ?.find((item) => item.financialYear === financialYear)
        ?.children?.find((item) => item.sheetId === sheetId)?.sheetColumns;
  }
  // else {
  //   res = tableData;
  // }

  // const res =  CompanyData?.financials
  //   ?.find((item) => item.financialYear === financialYear)
  //   ?.children?.find((item) => item.sheetId === sheetId)?.sheetData;

  console.log("resTableData", resTableData);

  const getCurrentTitle = () => {
    if (location.pathname.includes("/financial")) {
      return "Financials";
    }

    if (location.pathname.includes("/invoice")) {
      return "Invoices and Documents";
    }

    switch (location.pathname) {
      case "/":
        return "Bank Accounts";
      case "/invoice":
        return "Invoices and Documents";
      case "/settings":
        return "Settings";
      case "/co-workers":
        return "Co-workers";
      case "/account":
        return "Your Account";
      case "/gethelp":
        return "Get Help";
      case "/invite":
        return "Invite";
      default:
        return "";
    }
  };

  return (
    <div className="finanicalPage">
      <div className="flex items-center gap-5 z-10 pl-[30px] pr-5 border-b border-neutral-400">
        <span className="text-xl text-neutral-900 font-medium inline-block py-5">
          {getCurrentTitle()}
        </span>
        <div
          onClick={() => setShowShareModal(!showShareModal)}
          className="cursor-pointer z-10 mt-1 px-3 py-1.5 rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
            />
          </svg>
          <span>Share</span>
        </div>
      </div>

      <div className="hide-scrollbar overflow-auto h-[500px] mt-6">
        {resTableData?.length > 0 ? (
          <FinancialTable
            tableDataColumn={resTableColumn}
            tableData={resTableData}
          />
        ) : (
          "loading..."
        )}
      </div>
      <FinancialShareModal
        open={showShareModal}
        closeModal={setShowShareModal}
      />
    </div>
  );
}
