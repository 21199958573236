import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "src/components/Button";
import DownloadPDFWorkModal from "./DownloadPDFWordModal";

export default function RequestSubmitModal({ open, closeModal, reset }) {
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const handleDownloadLink = () => {
    // setShowDownloadModal(true);
    closeModal(false);
    reset();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => closeModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-8 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] p-8">
                <>
                  <div className="flex justify-center">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="text-center mt-2">
                        <img
                          className="mx-auto text-center"
                          src="/icons/docGenerate/checkCircle.png"
                          alt=""
                        />

                        <p className="text-xl my-4 text-center text-neutral-900">
                          Request Submitted
                        </p>
                        <p className="text-center text-neutral-900">
                          You will be contacted by our team for further
                          requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 text-center flex justify-between gap-6">
                    <div
                      onClick={handleDownloadLink}
                      className="w-full cursor-pointer z-10 mt-1 px-3 py-2.5 text-base rounded inline-flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                    >
                      <span>Continue</span>
                    </div>
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
