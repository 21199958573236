import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useAuth } from "src/context/AuthProvider";
import { useNavigate } from "react-router";
import { useCompanyData } from "src/context/CompanyDataProvider";
import Button from "../Button";

export default function LogOutSlider({ open, closeModal }) {
  const navigate = useNavigate();
  // const { state: CompanyData } = useCompanyData();
  const { logout } = useAuth();

  const onSignOut = async () => {
    try {
      await logout();
      localStorage.clear();
      // localStorage.removeItem("emailForSignIn");
      // localStorage.removeItem("user");
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-medium text-neutral-900">
                          Log out
                        </Dialog.Title>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={() => {
                              closeModal(false);
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <div className="absolute inset-0 px-4 sm:px-6 mr-12">
                        <p className="text-neutral-900">
                          Are you sure you want to log out of{" "}
                          {/* {CompanyData?.brandName}? */}
                          Cashwise?
                        </p>
                        <div className="flex justify-between mt-5 sm:mt-4 gap-4 ">
                          {/* <button
                            type="button"
                            className="inline-flex w-[140px] justify-center rounded border bg-white hover:bg-primary-100 hover:border-none border border-tertiary4-500 text-tertiary4-500 px-4 py-2 text-base font-medium  shadow-sm focus:outline-none focus:ring-2 focus:ring-tertiary4-500 focus:ring-offset-2 sm:text-sm"
                            onClick={() => {
                              closeModal(false);
                            }}
                          >
                            No, Cancel
                          </button> */}
                          <Button
                            btnType="secondary"
                            type="button"
                            className="mt-3 inline-flex w-[140px] justify-center items-center rounded-md text-tertiary4-500 text-base font-medium shadow-sm focus:outline-none sm:mt-0 "
                            onClick={() => {
                              closeModal(false);
                            }}
                          >
                            No, Cancel
                          </Button>
                          {/* <button
                            type="button"
                            className="inline-flex ml-8 w-[140px] justify-center rounded border border-transparent bg-tertiary4-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-550 focus:outline-none focus:ring-2 focus:ring-tertiary4-500 focus:ring-offset-2 "
                            onClick={onSignOut}
                          >
                            Yes, Log out
                          </button> */}
                          <Button
                            onClick={onSignOut}
                            type="button"
                            className="inline-flex w-[140px] justify-center rounded-md text-base font-medium text-white focus:outline-none"
                          >
                            Yes, Log out
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
