import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import Button from "src/components/Button";
import ImportDriveLinkModal from "src/components/FormationDiligenceModals/ImportDriveLinkModal";
import InProgressLinkModal from "src/components/FormationDiligenceModals/InProgressLinkModal";
import ShareLinkModal from "src/components/FormationDiligenceModals/ShareLinkModal";
import AnnualFiling from "./AnnualFiling";
import MonthlyFiling from "./MonthlyFiling";
import Fuse from "fuse.js";

import { useAuth } from "src/context/AuthProvider";
import { useComplianceDocuments } from "src/hooks/useComplianceDocuments";
import { useDocumentData } from "src/context/DocumentProvider";
import { toast } from "react-toastify";
import { useCompanyData } from "src/context/CompanyDataProvider";
import { Transition } from "@headlessui/react";
import ChooseActionComplianceModal from "src/components/ComplianceModals/ChooseActionComplianceModal";
import DeleteLinkComplianceModal from "src/components/ComplianceModals/DeleteLinkComplianceModal";
import AddCustomRowComplianceModal from "src/components/ComplianceModals/AddCustomRowComplianceModal";

const AllDocuments = [
  {
    category: "Annual Filings",
    documents: [
      {
        file: [],
        link: "",
        type: "ADT - 1:",
        btnName: "Add ADT - 1",
        btn: "Single",
      },
      {
        file: [{ fileName: "fcgrp.pdf", fileId: "wadabhef" }],
        link: "",
        type: "DIR -2::",
        btnName: "Add DIR -2",
        btn: "Single",
      },
      {
        file: [],
        link: "",
        type: "AOC - 4",
        btnName: "Add AOC - 4",
        btn: "Single",
      },
      {
        file: [],
        link: "",
        type: "MGT - 7",
        btnName: "Add MGT - 7",
        btn: "Single",
      },
      {
        file: [],
        link: "",
        type: "DPT - 3",
        btnName: "Add DPT - 3",
        btn: "Single",
      },
    ],
  },
  {
    category: "Monthly Filings",
    documents: [
      {
        file: [],
        link: "",
        type: "ADT - 1:",
        btnName: "Add ADT - 1",
        btn: "Single",
      },
      {
        file: [{ fileName: "fcgrp.pdf", fileId: "wadabhef" }],
        link: "",
        type: "DIR -2::",
        btnName: "Add DIR -2",
        btn: "Single",
      },
      {
        file: [],
        link: "",
        type: "AOC - 4",
        btnName: "Add AOC - 4",
        btn: "Single",
      },
      {
        file: [],
        link: "",
        type: "MGT - 7",
        btnName: "Add MGT - 7",
        btn: "Single",
      },
      {
        file: [],
        link: "",
        type: "DPT - 3",
        btnName: "Add DPT - 3",
        btn: "Single",
      },
    ],
  },
];

export default function Compliance() {
  const [showChooseActionComplianceModal, setShowChooseActionComplianceModal] =
    useState(false);
  const [showDeleteComplianceModal, setShowDeleteComplianceModal] =
    useState(false);
  const [showCustomRowComplianceModal, setShowCustomRowComplianceModal] =
    useState(false);

  const [allComplianceDocs, setAllComplianceDocs] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setsearchData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [openDriveModal, setOpenDriveModal] = useState(false);
  const [openInProgressModal, setOpenInProgressModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [searchResultData, setSearchResultData] = useState([]);
  const [searchResultData2, setSearchResultData2] = useState([]);
  const [showSearchPop, setShowSearchPop] = useState(false);
  const location = useLocation();

  const { state: authData, fetchTokenManually, idToken } = useAuth();
  const { getComplianceDocs, getComplianceSearchData } =
    useComplianceDocuments();
  const {
    state: docData,
    selectedValues,
    setSelectedValues,
    selectChooseAction,
    reloadDocumentData,
    reloadComplainceDocumentData,
  } = useDocumentData();
  const { state: CompanyData } = useCompanyData();
  const searchComplianceDivRef = useRef(null);

  const getCurrentTitle = () => {
    if (location.pathname.includes("/financial")) {
      return "Financials";
    }

    if (location.pathname.includes("/invoice")) {
      return "Invoices and Documents";
    }

    switch (location.pathname) {
      case "/":
        return "Bank Accounts";
      case "/invoice":
        return "Invoices and Documents";
      case "/settings":
        return "Settings";
      case "/co-workers":
        return "Co-workers";
      case "/account":
        return "Your Account";
      case "/gethelp":
        return "Get Help";
      case "/invite":
        return "Invite";
      case "/formationDiligence":
        return "Formation and Diligence Documents";
      case "/compliance":
        return "Compliance Documents";
      default:
        return "";
    }
  };

  let tabsArray = [];

  if (CompanyData?.companyCountry === "India") {
    if (CompanyData?.companyType === "HUF") {
      tabsArray = [
        {
          title: "Annual Filings",
          className: "",
          render: (props) => <AnnualFiling {...props} />,
        },
      ];
    } else if (CompanyData?.companyType === "Proprietorship") {
      tabsArray = [
        {
          title: "Annual Filings",
          className: "",
          render: (props) => <AnnualFiling {...props} />,
        },
      ];
    } else if (CompanyData?.companyType === "Partnership") {
      tabsArray = [
        {
          title: "Annual Filings",
          className: "",
          render: (props) => <AnnualFiling {...props} />,
        },
      ];
    } else if (CompanyData?.companyType === "LLP") {
      tabsArray = [
        {
          title: "Annual Filings",
          className: "",
          render: (props) => <AnnualFiling {...props} />,
        },
      ];
    } else {
      tabsArray = [
        {
          title: "Annual Filings",
          className: "",
          render: (props) => <AnnualFiling {...props} />,
        },
        {
          title: "Monthly Filings",
          className: "",
          render: (props) => <MonthlyFiling {...props} />,
        },
      ];
    }
  } else if (CompanyData?.companyCountry === "US") {
    tabsArray = [
      {
        title: "Annual Filings",
        className: "",
        render: (props) => <AnnualFiling {...props} />,
      },
    ];
  } else {
    tabsArray = [
      {
        title: "Annual Filings",
        className: "",
        render: (props) => <AnnualFiling {...props} />,
      },
    ];
  }

  const getComponentActive = {
    "": 0,
    "Annual Filings": 0,
    "Monthly Filings": 1,
  };

  useEffect(() => {
    const checkIfClickedOutside = (event) => {
      if (
        searchComplianceDivRef.current &&
        !searchComplianceDivRef.current.contains(event.target)
      ) {
        setShowSearchPop(false);
        setSearchResultData([]);
        setSearchResultData2([]);
        setSearchValue("");
        // console.log("se");
      }
    };

    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (idToken) {
        const updatedToken = await fetchTokenManually();
        const token = updatedToken || authData?.idTokenUpdated || idToken;
        const companyId = window.localStorage.getItem("companyId");
        try {
          const allFormationDocs = await getComplianceDocs(token, companyId);
          const searchComplianceData: any = await getComplianceSearchData(
            companyId,
            token
          );

          // console.log("allFormationDocs in useeffect", allFormationDocs);
          reloadComplainceDocumentData(allFormationDocs);
          setsearchData(searchComplianceData?.data);
          setAllComplianceDocs(allFormationDocs);
        } catch (error) {
          toast.error(error?.response?.data?.message, {
            className: "toast-position",
            position: "bottom-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // Commenting as we disable bankaccount for now and our / is changed from bankaccount to companies page
          // navigate("/companies");
          // Commenting this as we handle this in axiosInterceptor
          // navigate("/");
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedValues?.length === 0) {
      handleCloseAllModalState();
      selectChooseAction("");
    }
  }, [selectedValues?.length]);

  const getArrFromAPI = useMemo(() => {
    return allComplianceDocs?.find((item, index) => {
      // console.log(
      //   "item.name === tabsArray[activeTab].title",
      //   item.name === tabsArray[activeTab].title
      // );
      return item.name === tabsArray[activeTab].title;
    });
  }, [activeTab, idToken]);

  const handleShareModalState = () => {
    setShowCustomRowComplianceModal(false);
    setShowChooseActionComplianceModal(false);
    setShowDeleteComplianceModal(false);
    setShowShareModal(true);
  };

  const handleDeleteModalState = () => {
    setShowCustomRowComplianceModal(false);
    setShowChooseActionComplianceModal(false);
    setShowShareModal(false);
    setShowDeleteComplianceModal(true);
  };

  const handleActionModalState = () => {
    setShowCustomRowComplianceModal(false);
    setShowDeleteComplianceModal(false);
    setShowShareModal(false);
    setShowChooseActionComplianceModal(true);
  };

  const handleCloseAllModalState = () => {
    setShowDeleteComplianceModal(false);
    setShowShareModal(false);
    setShowChooseActionComplianceModal(false);
    document.body.style.overflow = "auto";
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    // const fuse = new Fuse(docSearch, {
    //   keys: ["documnets.type"],
    // });

    // console.log("fuse", fuse);
    // const results = fuse.search("pen");

    // const fuse = new Fuse(AllDocuments, {
    //   // keys: ["documents.type"],
    //   keys: [{ name: "documents.type" }],
    //   includeMatches: true,
    // });

    const fuse = new Fuse(searchData, {
      // keys: ["documents.type"],
      keys: ["kind"],
      includeMatches: true,
    });

    let searchResult = [];
    if (e.target.value === "") {
      console.log("object", "nothing");
      searchResult = [];
      setSearchResultData([]);
      setSearchResultData2([]);
    } else {
      const results = fuse.search(e.target.value);
      console.log("results", results);
      setSearchResultData(results);
      // const data = results?.map((subItem, index) => {
      //   searchResult = [...searchResult, ...subItem.matches];
      //   return subItem.matches.map((item) => item.value);
      // });
      // console.log("data", data);
      // console.log("searchResult", searchResult);
      // setSearchResultData(searchResult);
      // setSearchResultData2(data);

      // To get unique result
      // const uniqueResult = [
      //   ...new Map(searchResult.map((m) => [m.value, m])).values(),
      // ];
      // console.log(uniqueResult);
    }

    // Logic for norma JS search
    // if (e.target.value == "") {
    //   console.log("object", "nothing");
    //   searchResult = [];
    //   setSearchResultData([]);
    //   setSearchResultData2([]);
    // } else {
    //   const data = AllDocuments.map((item, index) => {
    //     // return item.documents.filter((subItem, index) => {
    //     //   if (subItem.type.toLowerCase().includes(e.target.value.toLowerCase())) {
    //     //     console.log("object", subItem);
    //     //     let obj = {
    //     //       files: subItem.file,
    //     //       category: item.category,
    //     //       type: subItem.type,
    //     //     };
    //     //     searchResult.push(obj);
    //     //     return subItem;
    //     //   }
    //     // });
    //     return {
    //       ...item,
    //       documents: item.documents.filter((subItem) => {
    //         if (
    //           subItem.type.toLowerCase().includes(e.target.value.toLowerCase())
    //         ) {
    //           // console.log("object", subItem);
    //           let obj = {
    //             files: subItem.file,
    //             category: item.category,
    //             type: subItem.type,
    //           };
    //           searchResult.push(obj);
    //           return subItem;
    //         }
    //       }),
    //     };
    //   });
    //   setSearchResultData(searchResult);
    //   setSearchResultData2(data);
    // }
  };

  const handleSearchClick = (item) => {
    console.log(item);
    console.log(getComponentActive[item?.item?.category]);
    setActiveTab(getComponentActive[item?.item?.category]);
    setSearchValue("");
    setShowSearchPop(false);
  };

  console.log("docData", docData);

  return (
    <div className="compliancePage">
      <div className="flex items-center justify-between border-b border-neutral-400 gap-4 pl-[30px] pr-5">
        <div className="flex items-center gap-6">
          <span className="text-xl text-neutral-900 font-semibold inline-block py-5">
            {getCurrentTitle()}
          </span>
          <div ref={searchComplianceDivRef} className="relative">
            <>
              <div
                onClick={() => setShowSearchPop(true)}
                className="relative mt-1 rounded-md shadow-sm"
              >
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  value={searchValue}
                  onChange={handleSearchChange}
                  type="search"
                  name="search"
                  id="search"
                  autoComplete="off"
                  className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Search Document"
                />
              </div>

              {showSearchPop && (
                <Transition
                  as={Fragment}
                  show={showSearchPop}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <div className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-6">
                        {searchResultData.length > 0 ? (
                          <>
                            <ul className="divide-y divide-gray-200 overflow-auto">
                              {/* This is used for JS search normal  */}
                              {/* {searchResultData.map((item, index) => (
                                <li
                                  onClick={() => handleSearchClick(item)}
                                  className="py-4 text-neutral-700 cursor-pointer hover:text-neutral-900"
                                >
                                  <span className="font-medium">
                                    {item.type}
                                  </span>{" "}
                                  exist in{" "}
                                  <span className="font-medium">
                                    {item.category}
                                  </span>
                                </li>
                              ))} */}
                              {searchResultData.map((item, index) => {
                                return (
                                  item.matches.length > 0 &&
                                  item.matches.map((subItem) => {
                                    console.log(item);
                                    return (
                                      <li
                                        onClick={() => handleSearchClick(item)}
                                        className="py-4 text-neutral-700 cursor-pointer hover:text-neutral-900"
                                      >
                                        <span className="font-medium">
                                          {subItem.value}
                                        </span>{" "}
                                        exist in{" "}
                                        <span className="font-medium">
                                          {item.item.category}
                                        </span>
                                      </li>
                                    );
                                  })
                                );
                              })}
                            </ul>
                          </>
                        ) : (
                          <p className="text-center">No results</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Transition>
              )}
            </>
          </div>
        </div>
        {/* <Button
          onClick={() => setOpenDriveModal(true)}
          type="button"
          className="text-white text-base bg-tertiary4-500 rounded hover:bg-primary-550 text-center"
        >
          Import From Drive
        </Button> */}
        {/* <Button
          onClick={() => setOpenInProgressModal(true)}
          type="button"
          className="text-white text-base bg-tertiary4-500 rounded hover:bg-primary-550 text-center"
        >
          Import In Progress
        </Button> */}
        <div className="flex items-center gap-4">
          <div
            onClick={() =>
              setShowCustomRowComplianceModal(!showCustomRowComplianceModal)
            }
            className="border border-tertiary4-500 hover:bg-primary-100 hover:border-none border border-tertiary4-500 p-2 rounded cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4 text-tertiary4-500 hover:text-primary-550"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </div>

          <div
            onClick={() => {
              selectChooseAction("delete");
              setShowDeleteComplianceModal(!showDeleteComplianceModal);
              document.body.style.overflow = "auto";
            }}
            className=" border border-tertiary4-500 hover:bg-primary-100 hover:border-none border border-tertiary4-500 p-2 rounded cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4 text-tertiary4-500 hover:text-primary-550"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </div>

          <div
            onClick={() => {
              selectChooseAction("share");
              setShowShareModal(!showShareModal);
              document.body.style.overflow = "auto";
            }}
            className="cursor-pointer px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#ffffff"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
              />
            </svg>
            <span>Share</span>
          </div>
        </div>
      </div>
      <div className="ml-[30px] mt-2 max-w-[75%]">
        <div className="flex justify-start mb-2">
          {/* {tabHeaders} */}
          <div className="flex gap-4">
            {tabsArray.map((tab, index) => (
              <div
                key={index}
                className={`mx-auto md:mx-0 ${
                  activeTab === index
                    ? `${tab.className} active text-tertiary4-500 border-b-4 border-tertiary4-500 p-3`
                    : "text-base text-neutral-600 cursor-pointer p-3"
                }`}
                onClick={() => setActiveTab(index)}
              >
                {tab.title}
              </div>
            ))}
          </div>
        </div>
        {tabsArray[activeTab].render({
          title: tabsArray[activeTab].title,
          data: getArrFromAPI,
          closeShareModal: handleShareModalState,
          openShareModal: showShareModal,
          openActionModal: showChooseActionComplianceModal,
          closeActionModal: handleActionModalState,
          closeDeleteModal: handleDeleteModalState,
        })}
      </div>
      <ImportDriveLinkModal
        open={openDriveModal}
        closeModal={setOpenDriveModal}
        docType="complianceDocs"
      />
      <InProgressLinkModal
        open={openInProgressModal}
        closeModal={setOpenInProgressModal}
      />
      <ChooseActionComplianceModal
        open={showChooseActionComplianceModal}
        closeModal={setShowChooseActionComplianceModal}
        setShowShareModal={setShowShareModal}
        setShowDeleteModal={setShowDeleteComplianceModal}
      />
      <DeleteLinkComplianceModal
        open={showDeleteComplianceModal}
        closeModal={setShowDeleteComplianceModal}
      />
      <AddCustomRowComplianceModal
        open={showCustomRowComplianceModal}
        closeModal={setShowCustomRowComplianceModal}
        selectedCategory={tabsArray[activeTab]?.title || ""}
      />
      <ShareLinkModal open={showShareModal} closeModal={setShowShareModal} />
    </div>
  );
}
