import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { useCompanyData } from "src/context/CompanyDataProvider";
import { useInvoice } from "src/hooks/useInvoice";
import { useAuth } from "src/context/AuthProvider";
import { toast, ToastContainer } from "react-toastify";
import Button from "src/components/Button";
import { useDocumentData } from "src/context/DocumentProvider";
import { useComplianceDocuments } from "src/hooks/useComplianceDocuments";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function RenameComplianceFileModal({
  open,
  closeModal,
  itemId,
  recordId,
  fetchAgain,
  setFetchAgain,
  oldFileName,
}: any) {
  const [loading, setLoading] = useState(false);
  const { state: CompanyData } = useCompanyData();
  const { renameComplianceFile, getComplianceDocs } = useComplianceDocuments();
  const { reloadComplainceDocumentData } = useDocumentData();
  const { state: authData, fetchTokenManually, idToken } = useAuth();

  const methods = useForm({
    defaultValues: {
      fileName: oldFileName,
    },
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset({ fileName: oldFileName });
  }, [reset, oldFileName, open, closeModal]);

  const onRenameFile = async (data) => {
    setLoading(true);
    const updatedToken = await fetchTokenManually();
    const token = updatedToken || authData?.idTokenUpdated || idToken;
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");
    try {
      const res = await renameComplianceFile(
        companyId,
        itemId,
        recordId,
        token,
        data?.fileName
      );
      if (res) {
        console.log("res", res);
        const allFormationDocs = await getComplianceDocs(token, companyId);
        reloadComplainceDocumentData(allFormationDocs);
        reset();
        setFetchAgain(!fetchAgain);
        setLoading(false);
        closeModal(false);
        toast.success("File renamed successfully!!", {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log("error", error);
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong!! Please try again",
        {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      setFetchAgain(!fetchAgain);
      setLoading(false);
      closeModal(false);
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[12]"
          onClose={() => closeModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <form onSubmit={handleSubmit(onRenameFile)}>
                    <div className="sm:flex sm:items-start">
                      <div className="block w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="mt-2 mb-3">
                          <p className="text-lg text-neutral-900">
                            Rename File
                          </p>
                        </div>
                        <input
                          type="text"
                          name="fileName"
                          id="fileName"
                          className="block w-full rounded-md border-neutral-300 shadow-sm focus:border-priamry-500 focus:ring-priamry-500 sm:text-sm"
                          {...register("fileName", {
                            required: {
                              value: true,
                              message: "File Name is required",
                            },
                            minLength: {
                              value: 1,
                              message:
                                "File name should be between 1 to 50 characters",
                            },
                            maxLength: {
                              value: 50,
                              message:
                                "File name should be between 1 to 50 characters",
                            },
                            pattern: {
                              value: /^[ \w\.\(\)-]{1,50}$/,
                              message: `File name should only contain alphanumeric, '-', '_', '(', ')' and spaces.`,
                            },
                          })}
                        />
                        <ErrorTest errors={errors} field="fileName" />
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      {/* <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-tertiary4-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-550 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Ok
                      </button> */}
                      <Button
                        disabled={loading}
                        loading={loading}
                        className="inline-flex w-full justify-center rounded-md text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Ok
                      </Button>
                      {/* <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-white hover:bg-primary-100 hover:border-none border border-tertiary4-500 text-tertiary4-500 px-4 py-2 text-base font-medium  shadow-sm focus:outline-none  sm:mt-0 sm:w-auto sm:text-sm"
                        // className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none  sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => {
                          reset();
                          closeModal(false);
                        }}
                      >
                        Cancel
                      </button> */}
                      <Button
                        btnType="secondary"
                        type="button"
                        className="mt-3 inline-flex w-full justify-center items-center rounded-md text-tertiary4-500 text-base font-medium shadow-sm focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => {
                          reset();
                          closeModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
}
