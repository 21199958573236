import { useEffect } from "react";
import { Navigate, useNavigate, useNavigation } from "react-router";
import "./App.css";
import SideBar from "./components/SideBar";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import BankAccount from "./routes/BankAccount";
import Invoice from "./routes/Invoice";
import FinancialYearInitial from "./routes/FinancialYear/FinancialInitial";
import FinancialYear from "./routes/FinancialYear";
import AccountSideBar from "./components/AccountSideBar";
import Account from "./routes/Account";
import GetHelp from "./routes/GetHelp";
import InviteTeam from "./routes/InviteTeam";
import Authentication from "./routes/Authentication/Authentication";
import ResetPassword from "./routes/ResetPassword";
import PrivateRoute from "./routes/PrivateRoute";
import ProfileDetails from "./routes/ProfileDetails";
import Confirm from "./components/Confirm";
import { AuthProvider, useAuth, UserActions } from "./context/AuthProvider";
import Companies from "./routes/Companies";
import InvitePeopleInCompany from "./routes/InvitePeopleInCompany";
import CompanyCreationCompleted from "./routes/CompanyCreationCompleted";
import CompaniesInvitedSuccess from "./routes/CompaniesInvitedSuccess";
import CreateNewCompany from "./routes/CreateNewCompany";
import { CompanyDataProvider } from "./context/CompanyDataProvider";
import Settings from "./routes/Settings";
import CoWorkers from "./routes/CoWorkers";
import NewCoWorkers from "./routes/CoWorkers/NewCoWorkers";
import ErrorPage from "./components/ErrorPage";
import InvoiceMonth from "./routes/Invoice/InvoiceMonth";
import InvoiceFolder from "./routes/Invoice/InvoiceFolder";
import { useAuthOnBoardFlow } from "./hooks/useAuthOnBoardFlow";

import { AxiosInterceptor } from "./context/AxiosInterceptor";
// import { ErrorBoundary } from "./components/ErrorBoundary";
import FormationDiligence from "./routes/FormationDiligence";
import Complaince from "./routes/Compliance";
import { DocumentDataProvider } from "./context/DocumentProvider";
import DocumentGeneration from "./routes/DocumentGeneration";
import DocumentName from "./routes/DocumentName";
import NDA from "./routes/DocumentGeneration/NDA";
import CoFounder from "./routes/DocumentGeneration/CoFounder";
import ServiceAgreement from "./routes/DocumentGeneration/ServiceAgreement";
import ConsultingAgreement from "./routes/DocumentGeneration/ConsultingAgreement";
import EmploymentAgreement from "./routes/DocumentGeneration/EmploymentAgreement";
import OfferLetter from "./routes/DocumentGeneration/OfferLetter";
import RequestCustom from "./routes/DocumentGeneration/RequestCustom";
import PrivacyPolicy from "./routes/DocumentGeneration/PrivacyPolicy";
import TermsAndCondition from "./routes/DocumentGeneration/TermsAndCondition";

function App() {
  console.log("process.env.REACT_APP_NAME", process.env.REACT_APP_NAME);

  return (
    // <div className="App">
    //   <SideBar />
    // </div>
    <>
      <AxiosInterceptor>
        <AuthProvider>
          <CompanyDataProvider>
            <DocumentDataProvider>
              {/* <ErrorBoundary> */}
              <Routes>
                {/* <Route
            path="/"
            element={
              <PrivateRoute>
                <SideBar />
              </PrivateRoute>
            }
          >
            <Route index={true} element={<BankAccount />} />
            <Route path="invoice" element={<Invoice />} />
            <Route
              path="financial/:financialYear"
              element={<FinancialYearInitial />}
            />
            <Route
              path="financial/:financialYear/:sheetName"
              element={<FinancialYear />}
            />
          </Route>
          <Route
            element={
              <PrivateRoute>
                <AccountSideBar />
              </PrivateRoute>
            }
          >
            <Route path="account" element={<Account />} />
            <Route path="gethelp" element={<GetHelp />} />
            <Route path="invite" element={<InviteTeam />} />
          </Route> */}
                {/* This is your layout - this will be on all pages after we land on home(bankaccount page) */}
                {/* <Route path="/" element={<SideBar />}>
              <Route index={true} element={<BankAccount />} /> */}
                <Route element={<SideBar />}>
                  <Route path="bankaccount" element={<BankAccount />} />
                  <Route path="invoice/:fYear" element={<Invoice />} />
                  <Route
                    path="invoice/:fYear/:monthName"
                    element={<InvoiceMonth />}
                  />
                  <Route
                    path="invoice/:fYear/:monthName/:folderId"
                    element={<InvoiceFolder />}
                  />
                  <Route
                    path="invoice/:fYear/:monthName/:folderId/*"
                    element={<InvoiceFolder />}
                  />
                  <Route path="settings" element={<Settings />} />
                  <Route path="co-workers" element={<NewCoWorkers />} />
                  <Route
                    path="financial/:financialYear"
                    element={<FinancialYearInitial />}
                  />
                  <Route
                    path="financial/:financialYear/:sheetId"
                    element={<FinancialYear />}
                  />
                  <Route
                    path="formationDiligence"
                    element={<FormationDiligence />}
                  />
                  <Route path="compliance" element={<Complaince />} />
                  <Route
                    path="documentGeneration"
                    element={<DocumentGeneration />}
                  />

                  <Route path="documentGeneration/nda" element={<NDA />} />
                  <Route
                    path="documentGeneration/cofounder"
                    element={<CoFounder />}
                  />
                  <Route
                    path="documentGeneration/serviceAgreement"
                    element={<ServiceAgreement />}
                  />
                  <Route
                    path="documentGeneration/consulting"
                    element={<ConsultingAgreement />}
                  />
                  <Route
                    path="documentGeneration/employment"
                    element={<EmploymentAgreement />}
                  />
                  <Route
                    path="documentGeneration/offerletter"
                    element={<OfferLetter />}
                  />
                  <Route
                    path="documentGeneration/requestCustom"
                    element={<RequestCustom />}
                  />
                  <Route
                    path="documentGeneration/privacyPolicy"
                    element={<PrivacyPolicy />}
                  />
                  <Route
                    path="documentGeneration/termsAndCondition"
                    element={<TermsAndCondition />}
                  />
                </Route>
                {/* <Route element={<AccountSideBar />}>
                    <Route path="account" element={<Account />} />
                    <Route path="gethelp" element={<GetHelp />} />
                    <Route path="invite" element={<InviteTeam />} />
                  </Route> */}
                <Route path="login" element={<Authentication />} />
                <Route path="resetpassword" element={<ResetPassword />} />
                {/* Todo - check below */}
                {/* Make this as well a private route and change this workspace component name to Comapnies */}
                {/* <Route path="companies" element={<Companies />} /> */}
                <Route path="/" element={<Companies />} />
                <Route
                  path="companies/invited"
                  element={<CompaniesInvitedSuccess />}
                />
                <Route path="companies/create" element={<CreateNewCompany />} />
                <Route
                  path="companies/invite"
                  element={<InvitePeopleInCompany />}
                />
                <Route
                  path="companies/completed"
                  element={<CompanyCreationCompleted />}
                />
                <Route path="profile-details" element={<ProfileDetails />} />
                <Route path="confirm" element={<Confirm />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
              {/* </ErrorBoundary> */}
            </DocumentDataProvider>
          </CompanyDataProvider>
        </AuthProvider>
      </AxiosInterceptor>
    </>
  );
}

export default App;
