import React, { useState } from "react";
import NewSignUp from "src/components/SignUp/NewSignUp";
import Login from "../../components/LogIn";
import SignUp from "../../components/SignUp";

export default function Authentication() {
  const [authMethod, setAuthMethod] = useState({
    signup_screen: true,
    login_screen: false,
    forgot_password: false,
  });

  return (
    <>
      {authMethod?.signup_screen && <NewSignUp setAuthMethod={setAuthMethod} />}

      {authMethod?.login_screen && <Login setAuthMethod={setAuthMethod} />}
    </>
  );
}
