// export const getCurrentFinancialYear = () =>
//   `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`;

export const getCurrentFinancialYear = () => {
  var fiscalyear = "";
  var today = new Date();
  if (today.getMonth() + 1 <= 3) {
    const stringLastTwoDigit = `${today.getFullYear()}`.slice(2, 4);
    const lastTwoDigit = +stringLastTwoDigit;
    fiscalyear = today.getFullYear() - 1 + "-" + lastTwoDigit;
  } else {
    const stringLastTwoDigit = `${today.getFullYear()}`.slice(2, 4);
    const lastTwoDigit = +stringLastTwoDigit + 1;

    fiscalyear = today.getFullYear() + "-" + lastTwoDigit;
  }

  return fiscalyear;
};
