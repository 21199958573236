import axios from "axios";
import { useState } from "react";

export const useFormationDocuments = () => {
  const getSearchData = (companyId, idToken) => {
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/formation-docs/${companyId}/doc-search-data`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const shareDriveLink = (companyId, idToken, docType, link) => {
    let body = {
      docType,
      link,
    };
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/company/${companyId}/shared-gdrive-link`,
      body,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const createShareLink = (companyId, idToken, arrRecordIds) => {
    let body = {
      recordIds: arrRecordIds,
    };
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/company/${companyId}/doc-collection/create-share-link`,
      body,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const deleteFormationFile = (companyId, itemId, recordId, idToken) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/formation-docs/${companyId}/record/${recordId}/item/${itemId}/delete`,
      {},
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const renameFormationFile = (
    companyId,
    itemId,
    recordId,
    idToken,
    newFileName
  ) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/formation-docs/${companyId}/record/${recordId}/item/${itemId}/rename`,
      {
        newFileName,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const downloadFormationFile = (companyId, recordId, itemId, idToken) => {
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/formation-docs/${companyId}/record/${recordId}/item/${itemId}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        responseType: "blob",
      }
    );
  };

  const uploadFormationFile = (companyId, idToken, recordId, fileData) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/formation-docs/${companyId}/record/${recordId}/item`,
      fileData,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  const addFundingRound = async (idToken, companyId, fundingRound) => {
    const body = {
      fundingRound,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/formation-docs/${companyId}/add-funding-round`,
        body,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      if (error) {
        throw error;
      }
    }
  };

  const getFormationDocs = async (idToken, companyId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/formation-docs/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      console.log("error", error);
      if (
        error?.response?.status === 404 &&
        error?.response?.data?.message === "Company not found"
      ) {
        throw error?.response?.data?.message;
      }
    }
  };

  const deleteSelectedDocuments = (companyId, idToken, arrRecordIds) => {
    let body = {
      recordIds: arrRecordIds,
    };
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/company/${companyId}/record/delete`,
      body,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const addCustomDocuments = (
    companyId,
    idToken,
    documentName: string,
    uploadType: boolean = false,
    documentCategory: string,
    pathStrArray: Array<string> = []
  ) => {
    let body = {
      kind: documentName,
      multipleUpload: uploadType || false,
      category: documentCategory,
      path: pathStrArray,
    };

    console.log("body", body);

    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/company/${companyId}/record/add`,
      body,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  return {
    getFormationDocs,
    addFundingRound,
    uploadFormationFile,
    deleteFormationFile,
    renameFormationFile,
    downloadFormationFile,
    createShareLink,
    deleteSelectedDocuments,
    shareDriveLink,
    getSearchData,
    addCustomDocuments,
  };
};
