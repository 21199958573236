import {
  useState,
  useEffect,
  useContext,
  createContext,
  useReducer,
  useRef,
} from "react";
import jwt_decode from "jwt-decode";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import {
  getAuth,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signOut,
  onAuthStateChanged,
  onIdTokenChanged,
} from "firebase/auth";
import { useLocation, useNavigate } from "react-router";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";
import axios from "axios";

let fireBaseInitializeObj = {};
if (
  process.env.REACT_APP_ENVIORNMENT === "local" ||
  process.env.REACT_APP_ENVIORNMENT === "development"
) {
  fireBaseInitializeObj = {
    apiKey: "AIzaSyC5xPke2Lbs3a2WH1FshJVDEbnzaCpRL_s",
    authDomain: "cashwise-dev.firebaseapp.com",
    projectId: "cashwise-dev",
    storageBucket: "cashwise-dev.appspot.com",
    messagingSenderId: "743636714284",
    appId: "1:743636714284:web:29be3e82aad851fb3aa289",
    measurementId: "G-PFYFRMJ3BC",
  };
} else {
  fireBaseInitializeObj = {
    apiKey: "AIzaSyDjWb6R4aZ1xJ7sX9xBeetQKZc3KpVD8rw",
    authDomain: "cashwise-prod.firebaseapp.com",
    projectId: "cashwise-prod",
    storageBucket: "cashwise-prod.appspot.com",
    messagingSenderId: "1092780411821",
    appId: "1:1092780411821:web:4c400812845635b075af24",
    measurementId: "G-6YT25K5BPK",
  };
}

const app = initializeApp(fireBaseInitializeObj);

// const app = initializeApp({
//   apiKey: "AIzaSyC5xPke2Lbs3a2WH1FshJVDEbnzaCpRL_s",
//   authDomain: "cashwise-dev.firebaseapp.com",
//   projectId: "cashwise-dev",
//   storageBucket: "cashwise-dev.appspot.com",
//   messagingSenderId: "743636714284",
//   appId: "1:743636714284:web:29be3e82aad851fb3aa289",
//   measurementId: "G-PFYFRMJ3BC",
// });

// const app = initializeApp({
//   apiKey: "AIzaSyC90Db-h30eilqh_OgMhHEQvsFb5-ry2vE",
//   authDomain: "astu-dev.firebaseapp.com",
//   projectId: "astu-dev",
//   storageBucket: "astu-dev.appspot.com",
//   messagingSenderId: "106063389178",
//   appId: "1:106063389178:web:b9b390f80383829fe74443",
//   measurementId: "G-EPFV3GELD8",
// });

const authContext = createContext(null);

export enum UserActions {
  INTIAL = "INTIAL_DATA",
  RELOAD = "USER_DATA_RELOAD",
  RESET = "USER_RESET",
}

const initialUserState = {
  idTokenUpdated: null,
  isAuthenticated: false,
  companyClicked: false,
  customer: null,
};

function init(initialUserState) {
  return initialUserState;
}

function reducer(state, action) {
  switch (action.type) {
    case UserActions.INTIAL:
      return {
        ...state,
        ...action.payload,
      };
    case UserActions.RELOAD:
      return {
        ...state,
        ...action.payload,
      };
    case UserActions.RESET:
      return {
        ...initialUserState,
      };
    default:
      return state;
  }
}

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialUserState, init);
  const [checkData, setCheckData] = useState("Ayush");
  const [userData, setUserData] = useState({});
  const [idToken, setIdToken] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const history = useLocation();
  const navigate = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const { acceptInvite, getAllCompanies, getUserDetails } =
    useAuthOnBoardFlow();
  const auth = getAuth(app);
  const analytics = getAnalytics(app);

  const user = auth?.currentUser;

  const sendSignInLinkToEmailFunc = async (email) => {
    // return sendSignInLinkToEmail(auth, email, {
    //   url: `${process.env.REACT_APP_FRONTEND_URL}confirm`,
    //   handleCodeInApp: true,
    // })
    //   .then(() => {
    //     window.localStorage.setItem("emailForSignIn", email);
    //     console.log("email set in local storage and link sent, please check");
    //     return true;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     // ...
    //   });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/send-auth-email`,
        {
          email,
        }
      );

      if (res) {
        window.localStorage.setItem("emailForSignIn", email);
        console.log("email set in local storage and link sent, please check");
        return res;
      }
    } catch (error) {
      throw error;
    }
  };

  const signInWithEmailLinkFunc = (email, code) => {
    return signInWithEmailLink(auth, email, code)
      .then((result) => {
        // TODO - check this email remove - not major
        // window.localStorage.removeItem("emailForSignIn");
        setUserData(result.user);
        setIsLoading(false);
        // setUserData((prevData) => ({ ...prevData, result }));

        return result.user;
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        console.log(error);
      });
  };

  const logout = () => {
    return signOut(auth)
      .then(() => {
        console.log("signout");
        // window.location.href = "/login";
      })
      .catch((error) => {
        console.log(error);
        // An error happened.
      });
  };

  useEffect(() => {
    const subscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        console.log("onIdTokenChanged user present", user);
        const refetechToken = await user.getIdToken();
        if (refetechToken) {
          dispatch({
            type: UserActions.INTIAL,
            payload: {
              idTokenUpdated: refetechToken,
            },
          });
          setIdToken(refetechToken);
          setUserData(user);
          setIsLoading(false);
        }
        // user.getIdToken().then((token) => {
        //   console.log("onIdTokenChanged token present", token);
        //   dispatch({
        //     type: UserActions.INTIAL,
        //     payload: {
        //       idTokenUpdated: token,
        //     },
        //   });
        //   token && setIdToken(token);
        // });
        // setUserData(user);
        // setIsLoading(false);
      } else {
        console.log("onIdTokenChanged", auth, user);
        setUserData({});
      }
    });

    return subscribe;
  }, []);

  useEffect(() => {
    if (user) {
      user.getIdToken().then((token) => {
        console.log("normal authprovider useEffect user", user, token);
        token && setIdToken(token);
      });
    }
  }, []);

  useEffect(() => {
    if (idToken) {
      const fetchUser = async () => {
        const userData = await getUserDetails(idToken);
        dispatch({
          type: UserActions.INTIAL,
          payload: {
            customer: userData,
            isAuthenticated: true,
          },
        });
      };

      fetchUser();
    }
  }, []);

  useEffect(() => {
    console.log("idToken", idToken);
  }, [idToken]);

  const reloadUserData = (data) => {
    dispatch({
      type: UserActions.RELOAD,
      payload: data,
    });
  };

  const fetchTokenManually = async () => {
    console.log("calling fetchTokenManually");
    var idTokenRefetched;
    if (user) {
      console.log("calling fetchTokenManually user present", user);
      idTokenRefetched = await user.getIdToken(true);
      console.log(
        "calling fetchTokenManually token present 1",
        idTokenRefetched
      );
      dispatch({
        type: UserActions.INTIAL,
        payload: {
          idTokenUpdated: idTokenRefetched,
        },
      });
      return idTokenRefetched;

      // user
      //   .getIdToken(true)
      //   .then((token) => {
      //     console.log("calling fetchTokenManually token present 1", token);
      //     if (token) {
      //       console.log("calling fetchTokenManually token present 2", token);
      //       token && setIdToken(token);
      //       idTokenRefetched = token;

      //     }
      //   })
      //   .catch((err) => {
      //     console.log("user fetchtokenmanually", err);
      //   });
    }
  };

  const values = {
    state,
    dispatch,
    auth,
    analytics,
    logEvent,
    user,
    userData,
    isAuthenticating,
    isLoading,
    idToken,
    checkData,
    reloadUserData,
    sendSignInLinkToEmailFunc,
    signInWithEmailLinkFunc,
    logout,
    fetchTokenManually,
  };

  return (
    <>
      <authContext.Provider value={values}>{children}</authContext.Provider>
    </>
  );
};

export const useAuth = () => {
  return useContext(authContext);
};
