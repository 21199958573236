import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "src/components/Button";
import DownloadPDFWorkModal from "./DownloadPDFWordModal";
import { useCompanyData } from "src/context/CompanyDataProvider";
import { useAuth } from "src/context/AuthProvider";
import useDocumentGeneration from "src/hooks/useDocumentGeneration";
import { useNavigate } from "react-router";

export default function DownloadTemplateModal({
  open,
  closeModal,
  templateCode,
}) {
  console.log("templateCode", templateCode);
  // const [showDownloadTemplate, setShowDownloadTemplate] = useState(false);
  const { state: CompanyData } = useCompanyData();
  const { state: authData, fetchTokenManually, idToken } = useAuth();
  const { downloadSampleTemplate } = useDocumentGeneration();
  const navigate = useNavigate();

  const handleDownloadLink = async () => {
    const updatedToken = await fetchTokenManually();
    const token = updatedToken || authData?.idTokenUpdated || idToken;
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");

    downloadSampleTemplate(token, companyId, templateCode)
      .then((res) => {
        console.log(res);
        const href = URL.createObjectURL(res.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${templateCode}.docx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        // reset();
        closeModal(false);
        // navigate("/documentGeneration");
      })
      .catch((err) => {
        console.log("error", err);
        closeModal(false);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => closeModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[620px] sm:px-6 sm:py-6">
                <>
                  <div className="flex justify-center">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="text-center mt-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-6 h-6 m-auto text-center mb-5"
                        >
                          <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
                          <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                        </svg>

                        <p className="text-xl text-center text-neutral-900">
                          Don't wish to generate automatically edited document?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 text-center flex justify-between gap-6">
                    <div
                      onClick={handleDownloadLink}
                      className="w-full cursor-pointer z-10 mt-1 px-3 py-2.5 text-base rounded inline-flex gap-2 items-center justify-center text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-4 h-4"
                      >
                        <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
                        <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                      </svg>
                      <span>No, Download Template</span>
                    </div>
                    <div
                      onClick={() => closeModal(false)}
                      className="w-full cursor-pointer z-10 mt-1 px-3 py-2.5 text-base rounded inline-flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                        />
                      </svg>

                      <span>Generate Document instead</span>
                    </div>
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
