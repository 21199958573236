import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import Button from "src/components/Button";
import DownloadPDFWorkModal from "../DownloadPDFWordModal";
import DownloadTemplateModal from "../DownloadTemplateModal";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

let cofounderDoCode = "";

export default function CoFounder() {
  const [formData, setFormData] = useState({});
  const [showCopyDownloadModal, setShowCopyDownloadModal] = useState(false);
  const [showDownloadTemplate, setShowDownloadTemplate] = useState(false);
  const [step, setStep] = useState(1);
  const [numberOfFounder, setNumberOfFounder] = useState(2);

  const navigate = useNavigate();
  const { docName } = useParams();
  const { state } = useLocation();

  const methods = useForm({ mode: "onChange" });
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = methods;

  const coFounderNo = watch("cofounderNumber");

  const onSubmit = (data) => {
    console.log(data);
    let updatedObj = {};
    if (data.cofounderNumber === "2") {
      updatedObj = {
        effective_date: data.date_format || "",
        Company_Name: data.companyName || "",
        company_address: data.registeredAddress || "",
        business_domain: data.buisnessDomain || "",
        first_founder_name: data.firstName0 || "",
        first_founder_percent: data.shareHolding0 || "",
        first_founder_ckra: data.chiefArea0 || "",
        first_founder_dkra: data.detailedRole0 || "",
        second_founder_name: data.firstName1 || "",
        second_founder_percent: data.shareHolding1 || "",
        second_founder_ckra: data.chiefArea1 || "",
        second_founder_dkra: data.detailedRole1 || "",
        lock_in_years: data.period || "",
        first_vesting_date: data.date_format_final || "",
      };
      cofounderDoCode = "two-cofounders-agreement";
    } else if (data.cofounderNumber === "3") {
      updatedObj = {
        effective_date: data.date_format || "",
        Company_Name: data.companyName || "",
        company_address: data.registeredAddress || "",
        business_domain: data.buisnessDomain || "",
        first_founder_name: data.firstName0 || "",
        first_founder_percent: data.shareHolding0 || "",
        first_founder_ckra: data.chiefArea0 || "",
        first_founder_dkra: data.detailedRole0 || "",
        second_founder_name: data.firstName1 || "",
        second_founder_percent: data.shareHolding1 || "",
        second_founder_ckra: data.chiefArea1 || "",
        second_founder_dkra: data.detailedRole1 || "",
        third_founder_name: data.firstName2 || "",
        third_founder_percent: data.shareHolding2 || "",
        third_founder_ckra: data.chiefArea2 || "",
        third_founder_dkra: data.detailedRole2 || "",
        lock_in_years: data.period || "",
        first_vesting_date: data.date_format_final || "",
      };
      cofounderDoCode = "three-cofounders-agreement";
    } else if (data.cofounderNumber === "4") {
      updatedObj = {
        effective_date: data.date_format || "",
        Company_Name: data.companyName || "",
        company_address: data.registeredAddress || "",
        business_domain: data.buisnessDomain || "",
        first_founder_name: data.firstName0 || "",
        first_founder_percent: data.shareHolding0 || "",
        first_founder_ckra: data.chiefArea0 || "",
        first_founder_dkra: data.detailedRole0 || "",
        second_founder_name: data.firstName1 || "",
        second_founder_percent: data.shareHolding1 || "",
        second_founder_ckra: data.chiefArea1 || "",
        second_founder_dkra: data.detailedRole1 || "",
        third_founder_name: data.firstName2 || "",
        third_founder_percent: data.shareHolding2 || "",
        third_founder_ckra: data.chiefArea2 || "",
        third_founder_dkra: data.detailedRole2 || "",
        fourth_founder_name: data.firstName3 || "",
        fourth_founder_percent: data.shareHolding3 || "",
        fourth_founder_ckra: data.chiefArea3 || "",
        fourth_founder_dkra: data.detailedRole3 || "",
        lock_in_years: data.period || "",
        first_vesting_date: data.date_format_final || "",
      };
      cofounderDoCode = "four-cofounders-agreement";
    } else {
      updatedObj = {
        effective_date: data.date_format || "",
        Company_Name: data.companyName || "",
        company_address: data.registeredAddress || "",
        business_domain: data.buisnessDomain || "",
        first_founder_name: data.firstName0 || "",
        first_founder_percent: data.shareHolding0 || "",
        first_founder_ckra: data.chiefArea0 || "",
        first_founder_dkra: data.detailedRole0 || "",
        second_founder_name: data.firstName1 || "",
        second_founder_percent: data.shareHolding1 || "",
        second_founder_ckra: data.chiefArea1 || "",
        second_founder_dkra: data.detailedRole1 || "",
        lock_in_years: data.period || "",
        first_vesting_date: data.date_format_final || "",
      };
      cofounderDoCode = "two-cofounders-agreement";
    }

    console.log(updatedObj);
    setFormData((prev) => ({ ...prev, updatedObj }));
    setShowCopyDownloadModal(true);
  };

  console.log("numberOfFounder", numberOfFounder);

  return (
    <>
      <div className="coFounder">
        <div className="flex items-center justify-between border-b border-neutral-400 gap-4 pl-[30px] pr-5">
          <div className="flex items-center gap-6">
            <span className="text-xl text-neutral-900 font-semibold inline-block py-5">
              Generate {state?.documentName || "Co-founders' Agreement"}
            </span>
          </div>
          <div className="flex gap-4">
            <div
              onClick={() => setShowDownloadTemplate(!showDownloadTemplate)}
              className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>

              <span>Download Template</span>
            </div>
            <div
              onClick={() => navigate("/documentGeneration/requestCustom")}
              // onClick={() => setShowShareModal(!showShareModal)}
              className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>

              <span>Request Custom</span>
            </div>
          </div>
        </div>
        <div className="activeFromComp ml-[30px] mr-5 mt-6">
          <div className="flex justify-between">
            <span className="text-neutral-900">
              Enter below details to generate a prefilled Co-founders'
              Agreement!{" "}
            </span>
            <span>{`Page ${step}/3`}</span>
          </div>

          <FormProvider {...methods}>
            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
              {step === 1 && (
                <>
                  <div>
                    <label className="text-neutral-900 font-semibold">
                      Date on which the agreement will be entered into:
                    </label>
                    <input
                      className="mt-4 relative block appearance-none rounded border border-neutral-500 px-3 py-1 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      type="date"
                      id="date_format"
                      name="date_format"
                      //   onKeyDown={(event) => {
                      //     event.preventDefault();
                      //   }}
                      {...register("date_format", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                      // max={new Date().toISOString().split("T")[0]}
                    />
                    <ErrorTest errors={errors} field="date_format" />
                  </div>

                  <div className="toGroup space-y-4 pt-5">
                    <label className="text-neutral-900 font-semibold">
                      Name and registered address of the company:{" "}
                    </label>
                    <div>
                      <input
                        {...register("companyName", {
                          required: {
                            value: true,
                            message: "Company name is required",
                          },
                        })}
                        id="companyName"
                        name="companyName"
                        type="text"
                        className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Company Name"
                      />
                      <ErrorTest errors={errors} field="companyName" />
                    </div>
                    <div>
                      <input
                        {...register("registeredAddress", {
                          required: {
                            value: true,
                            message: "Address is required",
                          },
                        })}
                        id="registeredAddress"
                        name="registeredAddress"
                        type="text"
                        className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Registered Address"
                      />
                      <ErrorTest errors={errors} field="registeredAddress" />
                    </div>

                    <div>
                      <label className="text-neutral-900 font-semibold ">
                        The company is engaged in the business of:
                      </label>
                      <input
                        {...register("buisnessDomain", {
                          required: {
                            value: true,
                            message: "Domain is required",
                          },
                        })}
                        id="buisnessDomain"
                        name="buisnessDomain"
                        type="text"
                        className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 mt-3 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Business Domain. Ex: manufacturing of television parts, invoicing tool, consumer lending, etc."
                      />
                      <ErrorTest errors={errors} field="buisnessDomain" />
                    </div>

                    <div>
                      <label className="text-neutral-900 font-semibold">
                        Number of co-founders:
                      </label>
                      <select
                        {...register(`cofounderNumber`, {
                          required: {
                            value: true,
                            message: "Please select number of co-founders",
                          },
                        })}
                        id={`cofounderNumber`}
                        name={`cofounderNumber`}
                        className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 mt-3 text-gray-900 placeholder-gray-500 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="" disabled selected hidden>
                          Number of Co-founders
                        </option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      <ErrorTest errors={errors} field="cofounderNumber" />
                    </div>
                  </div>

                  <Button
                    onClick={async () => {
                      const result = await trigger();
                      if (result) {
                        setNumberOfFounder(coFounderNo || 2);
                        setStep(2);
                      }
                    }}
                    type="button"
                    className="text-white rounded my-4"
                  >
                    {" "}
                    Continue
                  </Button>
                </>
              )}

              {step === 2 &&
                [...Array(Number(numberOfFounder)).keys()].map(
                  (item, index) => {
                    return (
                      <div key={index}>
                        <p className="font-semibold text-neutral-900 my-5">
                          Founder {index + 1} details:
                        </p>
                        <div className="toGroup space-y-6">
                          <div className="flex gap-6">
                            <div className="w-full">
                              <input
                                {...register(`firstName${index}`, {
                                  required: {
                                    value: true,
                                    message: "First name is required",
                                  },
                                })}
                                id={`firstName${index}`}
                                name={`firstName${index}`}
                                type="text"
                                className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="First and Middle Name"
                              />
                              <ErrorTest
                                errors={errors}
                                field={`firstName${index}`}
                              />
                            </div>
                            <div className="w-full">
                              <input
                                {...register(`lastName${index}`, {
                                  required: {
                                    value: true,
                                    message: "Last name is required",
                                  },
                                })}
                                id={`lastName${index}`}
                                name={`lastName${index}`}
                                type="text"
                                className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="Last Name"
                              />
                              <ErrorTest
                                errors={errors}
                                field={`lastName${index}`}
                              />
                            </div>
                          </div>
                          <div className="flex gap-6">
                            <div className="w-full">
                              <input
                                {...register(`shareHolding${index}`, {
                                  required: {
                                    value: true,
                                    message: "This field is required",
                                  },
                                })}
                                id={`shareHolding${index}`}
                                name={`shareHolding${index}`}
                                type="text"
                                className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="Shareholding in Percentage"
                              />
                              <ErrorTest
                                errors={errors}
                                field={`shareHolding${index}`}
                              />
                            </div>
                            <div className="w-full">
                              <input
                                {...register(`chiefArea${index}`, {
                                  required: {
                                    value: true,
                                    message: "This field is required",
                                  },
                                })}
                                id={`chiefArea${index}`}
                                name={`chiefArea${index}`}
                                type="text"
                                className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="Chief KRA (Key responsibility Area)"
                              />
                              <ErrorTest
                                errors={errors}
                                field={`chiefArea${index}`}
                              />
                            </div>
                          </div>
                          <div>
                            <input
                              {...register(`detailedRole${index}`, {
                                required: {
                                  value: true,
                                  message: "This field is required",
                                },
                              })}
                              id={`detailedRole${index}`}
                              name={`detailedRole${index}`}
                              type="text"
                              className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              placeholder="Detailed KRA (Key responsibility Area)"
                            />
                            <ErrorTest
                              errors={errors}
                              field={`detailedRole${index}`}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}

              {step === 2 && (
                <div className="flex gap-4 my-4">
                  <div
                    onClick={() => setStep(1)}
                    className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>

                    <span>Back</span>
                  </div>
                  <div
                    onClick={async () => {
                      const result = await trigger();
                      if (result) {
                        setStep(3);
                      }
                    }}
                    className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                  >
                    <span>Continue</span>
                  </div>
                </div>
              )}

              {step === 3 && (
                <>
                  <div>
                    <label className="text-neutral-900 font-semibold">
                      Lock in period or vesting period for founders in years:
                    </label>
                    <select
                      {...register(`period`, {
                        required: {
                          value: true,
                          message: "Please select Lock-in period",
                        },
                      })}
                      id={`period`}
                      name={`period`}
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 mt-3 text-gray-900 placeholder-gray-500 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="" disabled selected hidden>
                        Lock in or vesting period in years
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <ErrorTest errors={errors} field="period" />
                  </div>
                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Date of first vesting (or date for ending cliff period):
                    </label>
                    <input
                      className="mt-4 relative block appearance-none rounded border border-neutral-500 px-3 py-1 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      type="date"
                      id="date_format_final"
                      name="date_format_final"
                      //   onKeyDown={(event) => {
                      //     event.preventDefault();
                      //   }}
                      {...register("date_format_final", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                      // max={new Date().toISOString().split("T")[0]}
                    />
                    <ErrorTest errors={errors} field="date_format_final" />
                  </div>

                  <div className="flex gap-4 my-4">
                    <div
                      onClick={() => setStep(2)}
                      className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                        />
                      </svg>

                      <span>Back</span>
                    </div>
                    <button
                      type="submit"
                      className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                    >
                      <span>Generate Document</span>
                    </button>
                  </div>
                </>
              )}
            </form>
          </FormProvider>
        </div>
      </div>
      <DownloadPDFWorkModal
        open={showCopyDownloadModal}
        closeModal={setShowCopyDownloadModal}
        updatedObj={formData}
        docType={cofounderDoCode}
        reset={reset}
      />
      <DownloadTemplateModal
        open={showDownloadTemplate}
        closeModal={setShowDownloadTemplate}
        templateCode="cofounders-agreement"
      />
    </>
  );
}
