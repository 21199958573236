import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import Button from "src/components/Button";
import FullScreenLoader from "src/components/FullScreenLoader";
import { useAuth } from "src/context/AuthProvider";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function InvitePeopleInCompany() {
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const [items, setItems] = useState([1, 2]);
  const navigate = useNavigate();
  const { state } = useLocation();

  const { state: authData, isLoading, idToken } = useAuth();
  const { sendInvite } = useAuthOnBoardFlow();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onInviteSubmit = (data) => {
    console.log(data);
    setLoading(true);
    const inviteObj = {};
    items.forEach((item) => {
      inviteObj[data[`email${item}`]] = "ADMIN";
    });
    console.log(inviteObj);
    const token = authData?.idTokenUpdated || idToken;

    const companyId =
      state?.companyId || window.localStorage.getItem("companyId");
    sendInvite(token, companyId, inviteObj)
      .then((res) => {
        console.log(res);
        setLoading(false);
        navigate("/companies/completed", { state: companyId });
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message ||
            "Something went wrong!! Please try again later",
          {
            className: "toast-position",
            position: "bottom-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setLoading(false);
      });
  };

  const handleAddMore = () => {
    const arrL = items.length + 1;
    setItems([...items, arrL]);
  };

  const handleDeleteRow = (item) => {
    items.length > 1 && items.pop();
    const newItems = items;
    console.log(newItems);
    setItems([...newItems]);
  };

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  useEffect(() => {
    const isUser = window.localStorage.getItem("user");
    if (!isUser) {
      navigate("/login");
    }
  }, []);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <img
        className="companyLogo absolute top-5 left-5 mx-auto w-auto h-8"
        src="/logo/cashwise.png"
        alt="Your Company"
      />
      <div className="hor-ver-center">
        <h2 className="text-center text-3xl text-neutral-900">
          Invite people from your team
        </h2>
        <p className="text-center mt-4 mb-6 text-neutral-900">
          Collaborate with your internal and external stakeholders
        </p>
        <div className="w-[675px] shadow-md p-5">
          <p className="text-left text-neutral-900">
            Invite people to {state?.companyName}
          </p>
          <form className="relative" onSubmit={handleSubmit(onInviteSubmit)}>
            {items.map((item, index) => (
              <div
                key={index}
                className="flex flex-start items-center gap-4 mt-4"
              >
                <div className="w-full max-w-[88%]">
                  <input
                    id={`email${item}`}
                    name={`email${item}`}
                    type="email"
                    autoComplete="current-password"
                    className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Email"
                    // {...register(`email${item}`)}
                    {...register(`email${item}`, {
                      required: {
                        value: true,
                        message: "Email is required",
                      },
                    })}
                  />
                  <ErrorTest errors={errors} field={`email${item}`} />
                </div>

                {items.length > 1 && (
                  <div
                    className="cursor-pointer"
                    onClick={() => handleDeleteRow(item)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </div>
                )}
              </div>
            ))}
            {/* <button
              type="button"
              className="my-3 text-tertiary4-500"
              // onClick={() => setItems([...items, 1])}
              onClick={handleAddMore}
            >
              + Add more
            </button> */}
            <Button
              type="button"
              btnType="btnLink"
              className="my-3 text-tertiary4-500"
              onClick={handleAddMore}
            >
              + Add more
            </Button>
            <div>
              {/* <button
                type="submit"
                className="group relative flex w-full justify-center rounded border border-transparent bg-tertiary4-500 py-2 px-4 text-sm font-medium text-white hover:bg-primary-550 active:bg-neutal-600"
              >
                Send Invites
              </button> */}
              <Button
                disabled={loading}
                loading={loading}
                type="submit"
                className="group relative text-center justify-center flex w-full rounded text-base text-white font-medium"
              >
                Send Invites
              </Button>
            </div>
            <div
              className={`${
                items.length === 1
                  ? "absolute z-5 left-[572px] top-[8px]"
                  : "absolute z-5 left-[608px] top-[8px]"
              }`}
              onMouseEnter={onHover}
              onMouseLeave={onLeave}
              role="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                />
              </svg>
              {hover && (
                <div className="absolute lg:w-[200px] xl:w-[300px] z-10 top-[35px] lg:top-[30px] left-[-450px] lg:left-[-40px] bg-secondary-500 rounded-lg p-4 text-white">
                  <div className="flex">
                    <p className="text-neutral-400">
                      Invited co-workers would have all the permissions except
                      the option to delete the company
                    </p>
                  </div>
                </div>
                // <div className="absolute lg:w-[400px] xl:w-[500px] z-10 top-[35px] lg:top-[30px] left-[-450px] lg:left-[-40px] bg-secondary-500 rounded-lg p-4 text-white">
                //   <p className="mb-2">Roles</p>
                //   <div className="flex">
                //     <span className="mr-2">Owner:</span>
                //     <p className="text-neutral-400">Full permission</p>
                //   </div>

                //   <div className="flex">
                //     <span className="mr-2">Admin:</span>
                //     <p className="text-neutral-400">
                //       Access to all functions except delete company and
                //       invite/delete admins
                //     </p>
                //   </div>

                //   <div className="flex">
                //     <span className="mr-2">Manager:</span>
                //     <p className="text-neutral-400">
                //       Invite/delete members, add/delete invoices, access
                //       settings, view bank account details.
                //     </p>
                //   </div>

                //   <div className="flex">
                //     <span className="mr-2">Member:</span>
                //     <p className="text-neutral-400">
                //       View bank accounts, invoices and financials
                //     </p>
                //   </div>
                // </div>
              )}
            </div>
          </form>
          <div>
            {/* <button
              onClick={() =>
                navigate("/companies/completed", { state: state?.companyId })
              }
              type="button"
              className="mt-4 group relative flex w-full justify-center rounded border border-tertiary4-500 bg-white py-2 px-4 text-sm font-medium text-tertiary4-500 hover:bg-primary-100 hover:border-none  active:bg-neutal-600"
            >
              I'll do this later
            </button> */}
            <Button
              btnType="secondary"
              type="button"
              className="mt-4 inline-flex w-full justify-center items-center rounded text-tertiary4-500 text-base font-medium focus:outline-none sm:text-base"
              onClick={() =>
                navigate("/companies/completed", { state: state?.companyId })
              }
            >
              I'll do this later
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
