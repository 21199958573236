import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import DownloadPDFWorkModal from "../DownloadPDFWordModal";
import DownloadTemplateModal from "../DownloadTemplateModal";
import CompanyAgree from "./CompanyAgree";
import IndividualAgree from "./IndividualAgree";
import OtherAgree from "./OtherAgree";
import CompanyClientAgree from "./ServiceClientAgreement/CompanyClientAgree";
import IndividualClientAgree from "./ServiceClientAgreement/IndividualClientAgree";
import OtherClientAgree from "./ServiceClientAgreement/OtherClientAgree";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function ServiceAgreement() {
  const [formData, setFormData] = useState({});
  const [showCopyDownloadModal, setShowCopyDownloadModal] = useState(false);
  const [showClientFunnel, setShowClientFunnel] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [clientType, setClientType] = useState("");
  const [hideClientTypeDropdown, setHideClientTypeDropdown] = useState(false);
  const [showDownloadTemplate, setShowDownloadTemplate] = useState(false);

  const navigate = useNavigate();
  const { docName } = useParams();
  const { state } = useLocation();

  const methods = useForm({ mode: "onChange" });
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    console.log(data);

    // let spName = "";
    // let spCountry = "";
    // let spAddress = "";
    // let spCommunicationAddress = "";
    // let spRepTitle = "";
    // let spRepName = "";
    // let clientTypeEntity = "";
    // let clientName = "";
    // let clientCountry = "";
    // let clientAddress = "";
    // let clientCommunicationAddress = "";
    // let clientRepTitle = "";
    // let clientRepName = "";
    // let serviceProvided = "";
    // let partyRespoForApproval = "";
    // let serviceLocation = "";
    // let partyRespoForTravelExpense = "";
    // let completionDate = "";
    // let currencyValue = "";
    // let amountValue = "";
    // let paymentFrequency = "";
    // let dayForPayment = "";
    // let ROIForDelay = "";
    // let commencementDate = "";
    // let noticePeriodDays = "";
    // let partyOwnIP = "";
    // let courtCity = "";

    // if (serviceType === "Company") {
    //   spName = data.companyName;
    //   spCountry = data.COI;
    //   spAddress = data.registeredAddress;
    //   spCommunicationAddress =
    //     data.communication == "yes"
    //       ? data.registeredAddress
    //       : data.communicationAddress;
    //   spRepName = data.serviceProviderName;
    //   clientTypeEntity = data.clientProvider || clientType;
    // } else if (serviceType === "Individual") {
    // } else {
    // }

    const updatedObj = {
      effective_date: data.date_format || "",
      sp_type_of_entity: data.serviceProvider || serviceType,
      sp_name: data.spName || "",
      sp_country: data.COI || "",
      sp_address: data.registeredAddress || "",
      sp_communication_address:
        data.communication == "yes"
          ? data.registeredAddress
          : data.communicationAddress,
      sp_rep_title: data.designation || "",
      sp_rep_name: data.serviceProviderName || "",
      client_type_of_entity: data.clientProvider || clientType,
      client_name: data.clientName || "",
      client_country: data.COI_client || "",
      client_address: data.registeredClientAddress || "",
      client_communication_address:
        data.communication_client == "yes"
          ? data.registeredClientAddress
          : data.clientCommunicationAddress,
      client_rep_title: data.designation_client || "",
      client_rep_name: data.clientProviderName || "",
      services_provided: data.comment || "",
      party_responsible_for_approval: data.partyProvider || "",
      service_location: data.city || "",
      party_for_travel_expense: data.partyResponsible || "",
      completion_date: data.date_format_completion || "",
      currency: data.currency || "",
      Amount: data.amount || "",
      payment_frequency: data.frequency || "",
      days_for_payment: data.no_of_days || "",
      roi_for_delay: data.percentageInterest || "",
      commencement_date: data.date_format_service || "",
      notice_period_days: data.no_of_days_final || "",
      party_own_IP: data.partyProvider_final || "",
      court_city: data.city_final || "",
    };

    console.log(updatedObj);
    setFormData((prev) => ({ ...prev, updatedObj }));
    setShowCopyDownloadModal(true);
  };

  const handleChange = (data) => {
    setServiceType(data.target.value);
  };

  const handleClientChange = (data) => {
    setClientType(data.target.value);
  };

  return (
    <>
      <div className="flex items-center justify-between border-b border-neutral-400 gap-4 pl-[30px] pr-5">
        <div className="flex items-center gap-6">
          <span className="text-xl text-neutral-900 font-semibold inline-block py-5">
            Generate {state?.documentName || "Service Agreement"}
          </span>
        </div>
        <div className="flex gap-4">
          <div
            onClick={() => setShowDownloadTemplate(!showDownloadTemplate)}
            className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>

            <span>Download Template</span>
          </div>
          <div
            onClick={() => navigate("/documentGeneration/requestCustom")}
            // onClick={() => setShowShareModal(!showShareModal)}
            className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
              />
            </svg>

            <span>Request Custom</span>
          </div>
        </div>
      </div>
      <div className="activeFromComp ml-[30px] mr-5 mt-6">
        <FormProvider {...methods}>
          <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
            {!showClientFunnel ? (
              <>
                <div className="flex justify-between">
                  <span className="text-neutral-900">
                    Enter below details to generate a prefilled Services
                    Agreement!{" "}
                  </span>
                  <span>{`Page 1/5`}</span>
                </div>
                <div className="mt-6">
                  <label className="text-neutral-900 font-semibold">
                    Date on which the agreement will be entered into:
                  </label>
                  <input
                    className="my-4 relative block appearance-none rounded border border-neutral-500 px-3 py-1 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    type="date"
                    id="date_format"
                    name="date_format"
                    //   onKeyDown={(event) => {
                    //     event.preventDefault();
                    //   }}
                    {...register("date_format", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                    // max={new Date().toISOString().split("T")[0]}
                  />
                  <ErrorTest errors={errors} field="date_format" />
                </div>

                <div>
                  <label className="text-neutral-900 font-semibold">
                    Nature of entity of the service provider:
                  </label>
                  <select
                    {...register(`serviceProvider`, {
                      onChange: (e) => {
                        handleChange(e);
                        // setServiceType(e)
                      },
                      required: {
                        value: true,
                        message: "Please select service provider",
                      },
                    })}
                    id={`serviceProvider`}
                    name={`serviceProvider`}
                    className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 mt-3 text-gray-900 placeholder-gray-500 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="" disabled selected hidden>
                      Nature of entity of the service provider:
                    </option>
                    <option value="Company">Company</option>
                    <option value="Individual">Individual</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Society">Society</option>
                    <option value="Trust">Trust</option>
                    <option value="Limited Liability Partnership">
                      Limited Liability Partnership
                    </option>
                    <option value="Sole Proprietorship">
                      Sole Proprietorship
                    </option>
                    <option value="HUF">HUF</option>
                  </select>
                  <ErrorTest errors={errors} field="serviceProvider" />
                </div>

                {serviceType === "Company" && (
                  <CompanyAgree setShowClientFunnel={setShowClientFunnel} />
                )}

                {serviceType === "Individual" && (
                  <IndividualAgree setShowClientFunnel={setShowClientFunnel} />
                )}

                {serviceType === "Partnership" && (
                  <OtherAgree setShowClientFunnel={setShowClientFunnel} />
                )}

                {serviceType === "Society" && (
                  <OtherAgree setShowClientFunnel={setShowClientFunnel} />
                )}

                {serviceType === "Trust" && (
                  <OtherAgree setShowClientFunnel={setShowClientFunnel} />
                )}

                {serviceType === "Limited Liability Partnership" && (
                  <OtherAgree setShowClientFunnel={setShowClientFunnel} />
                )}

                {serviceType === "Sole Proprietorship" && (
                  <OtherAgree setShowClientFunnel={setShowClientFunnel} />
                )}

                {serviceType === "HUF" && (
                  <OtherAgree setShowClientFunnel={setShowClientFunnel} />
                )}
              </>
            ) : (
              <>
                {!hideClientTypeDropdown && (
                  <>
                    <div className="flex justify-between">
                      <span className="text-neutral-900">
                        Enter below details to generate a prefilled Services
                        Agreement!{" "}
                      </span>
                      <span>{`Page 2/5`}</span>
                    </div>
                    <div className="mt-6">
                      <label className="text-neutral-900 font-semibold">
                        Nature of entity of the client{" "}
                      </label>
                      <select
                        {...register(`clientProvider`, {
                          onChange: (e) => {
                            handleClientChange(e);
                            // setServiceType(e)
                          },
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                        })}
                        id={`clientProvider`}
                        name={`clientProvider`}
                        className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 mt-3 text-gray-900 placeholder-gray-500 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="" disabled selected hidden>
                          Nature of Entity of the Client{" "}
                        </option>
                        <option value="Company">Company</option>
                        <option value="Individual">Individual</option>
                        <option value="Partnership">Partnership</option>
                        <option value="Society">Society</option>
                        <option value="Trust">Trust</option>
                        <option value="Limited Liability Partnership">
                          Limited Liability Partnership
                        </option>
                        <option value="Sole Proprietorship">
                          Sole Proprietorship
                        </option>
                        <option value="HUF">HUF</option>
                      </select>
                      <ErrorTest errors={errors} field="clientProvider" />
                    </div>
                  </>
                )}

                {clientType === "Company" && (
                  <CompanyClientAgree
                    setClientType={setClientType}
                    setHideClientTypeDropdown={setHideClientTypeDropdown}
                  />
                )}
                {clientType === "Individual" && (
                  <IndividualClientAgree
                    setClientType={setClientType}
                    setHideClientTypeDropdown={setHideClientTypeDropdown}
                  />
                )}

                {clientType === "Partnership" && (
                  <OtherClientAgree
                    setClientType={setClientType}
                    setHideClientTypeDropdown={setHideClientTypeDropdown}
                  />
                )}

                {clientType === "Society" && (
                  <OtherClientAgree
                    setClientType={setClientType}
                    setHideClientTypeDropdown={setHideClientTypeDropdown}
                  />
                )}

                {clientType === "Trust" && (
                  <OtherClientAgree
                    setClientType={setClientType}
                    setHideClientTypeDropdown={setHideClientTypeDropdown}
                  />
                )}

                {clientType === "Limited Liability Partnership" && (
                  <OtherClientAgree
                    setClientType={setClientType}
                    setHideClientTypeDropdown={setHideClientTypeDropdown}
                  />
                )}

                {clientType === "Sole Proprietorship" && (
                  <OtherClientAgree
                    setClientType={setClientType}
                    setHideClientTypeDropdown={setHideClientTypeDropdown}
                  />
                )}

                {clientType === "HUF" && (
                  <OtherClientAgree
                    setClientType={setClientType}
                    setHideClientTypeDropdown={setHideClientTypeDropdown}
                  />
                )}
              </>
            )}
          </form>
        </FormProvider>
      </div>
      <DownloadPDFWorkModal
        open={showCopyDownloadModal}
        closeModal={setShowCopyDownloadModal}
        updatedObj={formData}
        docType="services-agreement"
        reset={reset}
      />
      <DownloadTemplateModal
        open={showDownloadTemplate}
        closeModal={setShowDownloadTemplate}
        templateCode="services-agreement"
      />
    </>
  );
}
