import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "src/context/AuthProvider";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";
import { getCurrentFinancialYear } from "src/services/helpers/getCurrentFinancialYear";

export default function CompaniesInvitedSuccess() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleContinue = () => {
    localStorage.setItem("pop_status", "1");
    const companyId = state?.id || window.localStorage.getItem("companyId");
    // Commenting as we disable bankaccount for now and our / is changed from bankaccount to companies page
    // navigate("/", { state: companyId });
    // Commneting as client dont need invoice page for now

    // navigate(`/invoice/${getCurrentFinancialYear()}`, {
    //   state: companyId,
    // });
    navigate(`/formationDiligence`, {
      state: companyId,
    });
  };

  return (
    <>
      <img
        className="companyLogo absolute top-5 left-5 mx-auto w-auto h-8"
        src="/logo/cashwise.png"
        alt="Your Company"
      />
      <div className="hor-ver-center">
        <h2 className="text-center text-neutral-700 text-3xl mb-4">
          You have been added to {state?.name}
        </h2>
        <p className="text-center text-neutral-900">
          You've been invited by {state?.creatorFirstName}{" "}
          {state?.creatorlastName}. We have added you to their team.
        </p>

        <button
          onClick={handleContinue}
          type="submit"
          className="group relative mt-6 w-[454px] flex mx-auto justify-center rounded border border-transparent bg-tertiary4-500 py-2 px-4 font-medium text-white hover:bg-primary-550 active:bg-neutal-600"
        >
          Continue
        </button>
      </div>
    </>
  );
}
