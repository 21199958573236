import axios from "axios";

export const useSettings = () => {
  const deleteCompany = async (idToken, companyId) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/company/${companyId}/delete`,
        {},
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            ContentLength: 0,
          },
        }
      );
      console.log(res);
      const data = await res.data;
      console.log("data", data);
      return data;
    } catch (error) {
      throw error;
    }
  };

  const leaveCompany = async (idToken, companyId) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/company/${companyId}/leave`,
        {},
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      console.log(res);
      const data = await res.data;
      console.log("data", data);
      return data;
    } catch (error) {
      throw error;
    }
  };

  const deleteLogo = (companyId, idToken) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/company/${companyId}/delete-logo`,
      {},
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const uploadLogo = (companyId, idToken, fileData) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/company/${companyId}/upload-logo`,
      fileData,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  return {
    deleteCompany,
    leaveCompany,
    deleteLogo,
    uploadLogo,
  };
};
