import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useCompanyData } from "src/context/CompanyDataProvider";
import { useSettings } from "src/hooks/useSettings";
import { useAuth } from "src/context/AuthProvider";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import Button from "../Button";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function ChangeLogoModal({ open, closeModal, logoPresent }) {
  const [loading, setLoading] = useState(false);
  const { state: CompanyData, reloadCompnayData } = useCompanyData();
  const { uploadLogo, deleteLogo } = useSettings();
  const { idToken } = useAuth();

  const methods = useForm();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = methods;

  const companyId = CompanyData?.id || window.localStorage.getItem("companyId");

  const onSubmit = async (data) => {
    setLoading(true);
    console.log(data);
    const formData = new FormData();
    formData.append("brand-logo", data.file_input[0]);

    try {
      const res = await uploadLogo(companyId, idToken, formData);

      if (res) {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/company/${CompanyData?.id}`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setLoading(false);
        reloadCompnayData(res?.data);
        toast.success("Logo uploaded successfully!!", {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        reset();
        closeModal(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong!! Please try again",
        {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const handleRemoveLogo = async () => {
    // setLoading(true);
    try {
      const res = await deleteLogo(companyId, idToken);
      if (res) {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/company/${CompanyData?.id}`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        // setLoading(false);
        reloadCompnayData(res?.data);
        console.log(res);
        toast.success("Logo removed successfully!!", {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        reset();
        closeModal(false);
      }
    } catch (error) {
      console.log(error);
      // setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong!! Please try again",
        {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[12]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => closeModal(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start pb-4 border-b border-neutral-400">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-neutral-900">
                        Change Logo
                      </Dialog.Title>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-5 sm:mt-4 flex flex-col gap-4 ">
                      <input
                        type="file"
                        id="file_input"
                        name="file_input"
                        accept="image/*"
                        {...register("file_input", {
                          required: {
                            value: true,
                            message: "Please choose a file to upload logo",
                          },
                        })}
                        //   onChange={handleFileChange}
                        className="block w-full  text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-medium
                                    file:bg-primary-100 file:text-tertiary4-500
                                    hover:file:bg-[#4895ef]
                                    hover:file:text-white
                                    focus:outline-none 
                                    focus:border-none
                                    file:cursor-pointer 
                                    "
                      />

                      <ErrorTest errors={errors} field="file_input" />
                      {/* <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded border border-transparent bg-tertiary4-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-550 focus:outline-none  sm:w-auto sm:text-sm"
                      >
                        Upload New Logo
                      </button> */}
                      <Button
                        disabled={loading}
                        loading={loading}
                        className="inline-flex w-full justify-center rounded text-base font-medium text-white focus:outline-none "
                      >
                        Upload New Logo
                      </Button>
                      {logoPresent && (
                        // <button
                        //   onClick={handleRemoveLogo}
                        //   type="button"
                        //   className="mt-3 inline-flex w-full justify-center rounded border border-transparent bg-white hover:bg-primary-100 hover:border-none border border-tertiary4-500 text-tertiary4-500 px-4 py-2 text-base font-medium  shadow-sm focus:outline-none  sm:mt-0 sm:w-auto"

                        //   // className="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-primary-100 hover:border-primary-100 focus:outline-none  sm:mt-0 sm:w-auto"
                        // >
                        //   Remove Logo
                        // </button>
                        <Button
                          btnType="secondary"
                          type="button"
                          className="mt-3 inline-flex w-full justify-center items-center rounded text-tertiary4-500 text-base font-medium shadow-sm focus:outline-none sm:mt-0"
                          onClick={handleRemoveLogo}
                        >
                          Remove Logo
                        </Button>
                      )}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
}
