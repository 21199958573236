import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "src/components/Button";

export default function InitialModal({ open, closeModal }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          localStorage.removeItem("pop_status");
          closeModal(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] sm:px-10 sm:py-6">
                {/* <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => closeModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div> */}
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    {/* <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Delete your bank Account
                    </Dialog.Title> */}
                    <div className="mt-2">
                      {/* <img src="/icons/Invoice/check.png" alt="" /> */}

                      <p className="text-xl text-center text-neutral-900">
                        Welcome onboard!
                      </p>
                      <p className="text-base mt-3 text-center text-neutral-900">
                        You are all good to go! Manage all the company documents
                        at a single place with Cashwise.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 text-center">
                  {/* <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-tertiary4-500 hover:bg-primary-550 focus:outline-none focus:border-none px-4 py-2 text-base font-medium text-white sm:w-[350px] sm:text-sm"
                    onClick={() => closeModal(false)}
                  >
                    Continue
                  </button> */}
                  <Button
                    onClick={() => {
                      localStorage.removeItem("pop_status");
                      closeModal(false);
                    }}
                    className="inline-flex w-full justify-center rounded-md text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-[350px]"
                  >
                    Continue
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
