import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useAuth } from "src/context/AuthProvider";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";
import { toast, ToastContainer } from "react-toastify";
import Button from "src/components/Button";
import { useDocumentData } from "src/context/DocumentProvider";
import { useFormationDocuments } from "src/hooks/useFormationDocuments";
import { useCompanyData } from "src/context/CompanyDataProvider";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function ShareLinkModal({ open, closeModal }) {
  const [loading, setLoading] = useState(false);
  const {
    state: authData,
    fetchTokenManually,
    idToken,
    state: UserData,
    reloadUserData,
  } = useAuth();
  const {
    state: docData,
    selectedValues,
    setSelectedValues,
    selectChooseAction,
  } = useDocumentData();
  const { createShareLink } = useFormationDocuments();
  const { state: CompanyData } = useCompanyData();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = () => {};

  const handleCopyLink = async () => {
    const arrRecordIds = selectedValues?.map((item) => item.id);
    console.log("arrRecordIds", arrRecordIds);

    setLoading(true);
    const updatedToken = await fetchTokenManually();
    const token = updatedToken || authData?.idTokenUpdated || idToken;
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");

    try {
      const res: any = await createShareLink(companyId, token, arrRecordIds);
      if (res) {
        console.log("res link", res);
        await navigator.clipboard.writeText(
          res?.data?.shareLink || "Text copied"
        );
        setLoading(false);
        closeModal(false);
        setSelectedValues([]);
        selectChooseAction("");
        toast.success("Your link has beed copied!!", {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong!! Please try again later",
        {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      setLoading(false);
      closeModal(false);
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[12]" onClose={() => null}>
          {/* <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden"> */}
          <div className=" inset-0 overflow-hidden">
            <div className=" inset-0 overflow-hidden">
              <div className="fixed top-[76px] inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white pb-6 shadow-xl">
                      <div className="border-b border-neutral-400 px-4 sm:px-6">
                        <div className="flex items-center justify-between w-full h-[72px]">
                          <Dialog.Title className="text-xl font-medium text-gray-900">
                            Share
                          </Dialog.Title>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                              onClick={() => {
                                closeModal(false);
                                reset();
                                // const targetElement =
                                //   document.getElementsByTagName("html")[0];
                                // targetElement.style.overflow = "scroll";
                                document.body.style.overflow = "auto";
                              }}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-5 flex-1 px-4 sm:px-6">
                        <div className="absolute inset-0 px-4 sm:px-6">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            {selectedValues?.length > 0 ? (
                              <div>
                                <p className="text-base text-neutral-900 font-medium">
                                  List of Selected Documents
                                </p>
                                <p className="text-sm text-neutral-900 my-4">
                                  {" "}
                                  A zip of the selected documents can be shared
                                  using the below link:
                                </p>
                                <div
                                  onClick={handleCopyLink}
                                  className="cursor-pointer z-10 mt-1 px-3 py-1.5 text-base rounded inline-flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#ffffff"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-4 h-4 text-white"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                                    />
                                  </svg>
                                  <span>Copy Link</span>
                                </div>
                                <ul className="mt-6 space-y-4 list-decimal list-inside">
                                  {selectedValues.map((item, index) => (
                                    <div className="flex items-center">
                                      {/* <div className="border border-neutral-900 mr-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="currentColor"
                                          className="w-4 h-4 font-bold text-neutral-900"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M6 18L18 6M6 6l12 12"
                                          />
                                        </svg>
                                      </div> */}
                                      <li
                                        key={item?.id || index}
                                        className="text-base text-neutral-900"
                                      >
                                        {item?.kind || "refresh"}
                                      </li>
                                    </div>
                                  ))}
                                </ul>
                              </div>
                            ) : (
                              <div>
                                <p className="text-base text-neutral-900 font-medium">
                                  List of Selected Documents
                                </p>{" "}
                                <p className="mt-4 text-base text-neutral-900">
                                  Select documents to share!
                                </p>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
}
