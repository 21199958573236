import { createContext, useContext, useReducer, useState } from "react";

const documentDataContext = createContext(null);

export enum DocumentActions {
  RELOAD = "DOCUMENT_DATA_RELOAD",
  RESET = "DOCUMENT_RESET",
}

const initialState = {
  // testArr: [],
  // testString: "",
  // testObj: {},
  documentData: [],
  complianceDocumentData: [],
  path: [],
  chooseAction: "",
};

function init(initialState) {
  return initialState;
}

function reducer(state, action) {
  switch (action.type) {
    case DocumentActions.RELOAD:
      return {
        ...state,
        ...action.payload,
      };
    case DocumentActions.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export const DocumentDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const [selectedValues, setSelectedValues] = useState([]);

  const reloadDocumentData = (data) => {
    console.log("reloadDocumentData", data);
    dispatch({
      type: DocumentActions.RELOAD,
      payload: { documentData: data },
    });
  };

  const reloadComplainceDocumentData = (data) => {
    console.log("reloadComplainceDocumentData", data);
    dispatch({
      type: DocumentActions.RELOAD,
      payload: { complianceDocumentData: data },
    });
  };

  const selectChooseAction = (typeAction) => {
    dispatch({
      type: DocumentActions.RELOAD,
      payload: { chooseAction: typeAction },
    });
  };

  const reloadPath = (pathArr) => {
    dispatch({
      type: DocumentActions.RELOAD,
      payload: { path: pathArr },
    });
  };

  const values = {
    state,
    selectedValues,
    setSelectedValues,
    reloadDocumentData,
    reloadComplainceDocumentData,
    selectChooseAction,
    reloadPath,
  };

  return (
    <documentDataContext.Provider value={values}>
      {children}
    </documentDataContext.Provider>
  );
};

export const useDocumentData = () => useContext(documentDataContext);
