import React from "react";
import { Oval } from "react-loader-spinner";

export default function Button({
  type = "submit",
  disabled = false,
  loading = false,
  className,
  children,
  onClick,
  btnType = "primary",
}: any) {
  const btnTypeStyle = {
    primary:
      "py-2 px-4 bg-tertiary4-500 hover:bg-primary-550 border-transparent",
    secondary:
      "px-4 py-2 bg-white hover:bg-primary-100 hover:border-none border border-tertiary4-500",
    btnLink: "border-transparent",
  };

  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={` ${className} ${btnTypeStyle[btnType]} border disabled:bg-secondary-200 disabled:cursor-not-allowed`}
    >
      {!loading ? (
        children
      ) : (
        <Oval
          height={18}
          width={18}
          color="#2B85EDFF"
          wrapperStyle={{}}
          // wrapperClass="hor-ver-center"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#ffffff"
          strokeWidth={3}
          strokeWidthSecondary={2}
        />
      )}
      {/* {children} */}
    </button>
  );
}
