import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "src/components/Button";
import useDocumentGeneration from "src/hooks/useDocumentGeneration";
import { useAuth } from "src/context/AuthProvider";
import { useCompanyData } from "src/context/CompanyDataProvider";
import { Navigate, useNavigate } from "react-router";
import { Oval } from "react-loader-spinner";

export default function DownloadPDFWorkModal({
  open,
  closeModal,
  updatedObj,
  docType,
  reset,
}) {
  const [loading, setLoading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const { state: CompanyData } = useCompanyData();
  const { state: authData, fetchTokenManually, idToken } = useAuth();
  const { downloadDocument, downloadDocumentPDF } = useDocumentGeneration();
  const navigate = useNavigate();

  const handleWordDownload = async () => {
    setLoading(true);
    const updatedToken = await fetchTokenManually();
    const token = updatedToken || authData?.idTokenUpdated || idToken;
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");

    downloadDocument(token, companyId, docType, updatedObj.updatedObj)
      .then((res) => {
        console.log(res);
        const href = URL.createObjectURL(res.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${docType}.docx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setLoading(false);
        // reset();
        closeModal(false);
        // navigate("/documentGeneration");
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
        closeModal(false);
      });

    // try {
    //   const result = await downloadDocument(
    //     idToken,
    //     companyId,
    //     docType,
    //     updatedObj
    //   );
    //   console.log("object", result);
    // } catch (error) {
    //   console.log("error", error);
    // }
  };

  const handlePDFDownload = async () => {
    setLoadingPDF(true);
    const updatedToken = await fetchTokenManually();
    const token = updatedToken || authData?.idTokenUpdated || idToken;
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");

    downloadDocumentPDF(token, companyId, docType, updatedObj.updatedObj)
      .then((res) => {
        console.log(res);
        const href = URL.createObjectURL(res.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${docType}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setLoadingPDF(false);
        // reset();
        closeModal(false);
        // navigate("/documentGeneration");
      })
      .catch((err) => {
        console.log("error", err);
        setLoadingPDF(false);
        closeModal(false);
      });

    // try {
    //   const result = await downloadDocument(
    //     idToken,
    //     companyId,
    //     docType,
    //     updatedObj
    //   );
    //   console.log("object", result);
    // } catch (error) {
    //   console.log("error", error);
    // }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => closeModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[500px] sm:px-6 sm:py-6">
                <>
                  <div className="flex justify-center">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="text-center mt-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-6 h-6 m-auto text-center mb-5"
                        >
                          <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
                          <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                        </svg>

                        <p className="text-xl text-center text-neutral-900">
                          Download your document!
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 text-center flex justify-between gap-6">
                    <div
                      onClick={handleWordDownload}
                      className="w-full cursor-pointer z-10 mt-1 px-3 py-2.5 text-base rounded inline-flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                    >
                      {!loading ? (
                        <span>Download Word</span>
                      ) : (
                        <Oval
                          height={18}
                          width={18}
                          color="#2B85EDFF"
                          wrapperStyle={{}}
                          // wrapperClass="hor-ver-center"
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#ffffff"
                          strokeWidth={3}
                          strokeWidthSecondary={2}
                        />
                      )}
                    </div>
                    <div
                      onClick={handlePDFDownload}
                      className="w-full cursor-pointer z-10 mt-1 px-3 py-2.5 text-base rounded inline-flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                    >
                      {!loadingPDF ? (
                        <span>Download PDF</span>
                      ) : (
                        <Oval
                          height={18}
                          width={18}
                          color="#2B85EDFF"
                          wrapperStyle={{}}
                          // wrapperClass="hor-ver-center"
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#ffffff"
                          strokeWidth={3}
                          strokeWidthSecondary={2}
                        />
                      )}
                    </div>
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
