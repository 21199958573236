import React from "react";
import { useLocation, useNavigate } from "react-router";
import Button from "src/components/Button";
import { useAuth } from "src/context/AuthProvider";
import { getCurrentFinancialYear } from "src/services/helpers/getCurrentFinancialYear";

export default function CompanyCreationCompleted() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    state: authData,
    fetchTokenManually,
    isLoading,
    idToken,
    dispatch,
    state: UserData,
  } = useAuth();

  const companyId = state || window.localStorage.getItem("companyId");

  console.log("idToken", idToken);
  return (
    <>
      <img
        className="companyLogo absolute top-5 left-5 mx-auto w-auto h-8"
        src="/logo/cashwise.png"
        alt="Your Company"
      />
      <div className="hor-ver-center">
        <h2 className="text-center text-neutral-900 text-3xl mb-4">
          Setup Complete
        </h2>
        <p className="text-center text-neutral-900">
          Nice work! Your account is all setup and ready.
        </p>
        {/* Commenting as we disable bankaccount for now and our / is changed from
        bankaccount to invoice */}
        {/* <button
          // onClick={() => navigate("/", { state: companyId })}
          onClick={() =>
            navigate(`/invoice/${getCurrentFinancialYear()}`, {
              state: companyId,
            })
          }
          type="submit"
          className="group relative mt-6 w-[454px] flex  justify-center rounded border border-transparent bg-tertiary4-500 py-2 px-4 text-sm font-medium text-white hover:bg-primary-550"
        >
          Go to Dashboard
        </button> */}
        <Button
          type="button"
          className="group mt-6 w-[454px] relative text-center justify-center flex w-full rounded text-base text-white font-medium"
          onClick={() => {
            // navigate(`/invoice/${getCurrentFinancialYear()}`, {
            //   state: companyId,
            // })
            localStorage.setItem("pop_status", "1");
            navigate(`/formationDiligence`, {
              state: companyId,
            });
          }}
        >
          Go to Dashboard
        </Button>
      </div>
    </>
  );
}
