import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import Button from "src/components/Button";
import { stateUT } from "src/services/helpers/stateUT";
import DownloadPDFWorkModal from "../DownloadPDFWordModal";
import DownloadTemplateModal from "../DownloadTemplateModal";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function ConsultingAgreement() {
  const [formData, setFormData] = useState({});
  const [showCopyDownloadModal, setShowCopyDownloadModal] = useState(false);
  const [showDownloadTemplate, setShowDownloadTemplate] = useState(false);
  const [step, setStep] = useState(1);

  const navigate = useNavigate();
  const { docName } = useParams();
  const { state } = useLocation();

  const methods = useForm({ mode: "onChange" });
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = methods;

  const clientTypeValue = watch("clientType");
  const consultantTypeValue = watch("consultantType");
  const clientPaid = watch("clientPaid");

  console.log("check", consultantTypeValue);

  const onSubmit = (data) => {
    console.log(data);
    const updatedObj = {
      effective_date: data.date_format || "",
      agreement_state: data.state || "",
      client_type: data.clientType || clientTypeValue,
      client_name: data.clientName || "",
      client_address: data.clientAddress || "",
      client_PAN: data.clientPAN || "",
      client_business_domain: data.majorBuisness || "",
      consultant_type: data.consultantType || "",
      consultant_name: data.consultantName || "",
      consultant_address: data.consultantAddress || "",
      consultant_PAN: data.consultantPAN || "",
      consultant_expertise: data.specializeConsultant || "",
      statement_of_work: data.comment || "",
      currency: data.currency || "",
      Amount: data.amount || "",
      payment_frequency: data.frequency || "",
      days_for_payment: data.no_of_days || "",
      mode_of_payment: data.paymentMethod || "",
      retainer: data.clientPaid || "",
      retainer_fee: data.retainerFee || "",
      report_frequency: data.interval || "",
      start_date: data.date_format_final || "",
      client_rep_name: data.fullNameClient || "",
      consultant_rep_name: data.fullNameConsultant || "",
    };
    setFormData((prev) => ({ ...prev, updatedObj }));
    setShowCopyDownloadModal(true);
  };

  return (
    <>
      <div className="consultingAgree">
        <div className="flex items-center justify-between border-b border-neutral-400 gap-4 pl-[30px] pr-5">
          <div className="flex items-center gap-6">
            <span className="text-xl text-neutral-900 font-semibold inline-block py-5">
              Generate {state?.documentName || "Consulting Agreement"}
            </span>
          </div>
          <div className="flex gap-4">
            <div
              onClick={() => setShowDownloadTemplate(!showDownloadTemplate)}
              className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>

              <span>Download Template</span>
            </div>
            <div
              onClick={() => navigate("/documentGeneration/requestCustom")}
              // onClick={() => setShowShareModal(!showShareModal)}
              className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>

              <span>Request Custom</span>
            </div>
          </div>
        </div>
        <div className="activeFromComp ml-[30px] mr-5 mt-6">
          <div className="flex justify-between">
            <span className="text-neutral-900">
              Enter below details to generate a prefilled Consulting Agreement!{" "}
            </span>
            <span>{`Page ${step}/4`}</span>
          </div>
          <FormProvider {...methods}>
            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
              {step === 1 && (
                <>
                  <div>
                    <label className="text-neutral-900 font-semibold">
                      Date on which the agreement will be entered into:{" "}
                    </label>
                    <input
                      className="my-4 relative block appearance-none rounded border border-neutral-500 px-3 py-1 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      type="date"
                      id="date_format"
                      name="date_format"
                      {...register("date_format", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                      // max={new Date().toISOString().split("T")[0]}
                    />
                    <ErrorTest errors={errors} field="date_format" />
                  </div>

                  <div>
                    <label className="text-neutral-900 font-semibold">
                      The state from where this agreement is entered into:{" "}
                    </label>
                    <select
                      {...register(`state`, {
                        required: {
                          value: true,
                          message: "Please select State",
                        },
                      })}
                      id={`state`}
                      name={`state`}
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 mt-3 text-gray-900 placeholder-gray-500 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="" disabled selected hidden>
                        State
                      </option>
                      {/* <option value="Rajasthan">Rajasthan</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Goa">Goa</option>
                      <option value="Tamil Nadu">Tamil Nadu</option> */}
                      {stateUT.map((item, index) => (
                        <option value={item.label}>{item.label}</option>
                      ))}
                    </select>
                    <ErrorTest errors={errors} field="state" />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Type of the client:
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("clientType")}
                      type="radio"
                      value="Business"
                      id="clientType"
                    />
                    {"  "}
                    Business
                  </div>

                  <div className="mt-2">
                    <input
                      {...register("clientType")}
                      type="radio"
                      value="Individual"
                      id="clientType"
                    />
                    {"  "}
                    Individual
                  </div>

                  {clientTypeValue === "Business" && (
                    <>
                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Business name and office address of the client:{" "}
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("clientName", {
                            required: {
                              value: true,
                              message: "Buisness name is required",
                            },
                          })}
                          id="clientName"
                          name="clientName"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Business Name"
                        />
                        <ErrorTest errors={errors} field="clientName" />
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("clientAddress", {
                            required: {
                              value: true,
                              message: "Office Address is required",
                            },
                          })}
                          id="clientAddress"
                          name="clientAddress"
                          type="text"
                          className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Office Address"
                        />
                        <ErrorTest errors={errors} field="clientAddress" />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Major business areas of the client:{" "}
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("majorBuisness", {
                            required: {
                              value: true,
                              message: "This is required",
                            },
                          })}
                          id="majorBuisness"
                          name="majorBuisness"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Business Area 1, Business Area 2, and so on"
                        />
                        <ErrorTest errors={errors} field="majorBuisness" />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Name of the person representing the client:{" "}
                        </label>
                      </div>
                      <div className="mt-4">
                        <input
                          {...register("fullNameClient", {
                            required: {
                              value: true,
                              message: "Full name is required",
                            },
                          })}
                          id="fullNameClient"
                          name="fullNameClient"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Full Name"
                        />
                        <ErrorTest errors={errors} field="fullNameClient" />
                      </div>
                      <Button
                        onClick={async () => {
                          const result = await trigger();
                          console.log(result);
                          if (result) {
                            // setShowClientFunnel(true);
                            setStep(2);
                          }
                        }}
                        type="button"
                        className="text-white rounded my-4"
                      >
                        {" "}
                        Continue
                      </Button>
                    </>
                  )}

                  {clientTypeValue === "Individual" && (
                    <>
                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Full name and correspondence address of the client:{" "}
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("clientName", {
                            required: {
                              value: true,
                              message: "Full name is required",
                            },
                          })}
                          id="clientName"
                          name="clientName"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Full Name"
                        />
                        <ErrorTest errors={errors} field="clientName" />
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("clientAddress", {
                            required: {
                              value: true,
                              message: "Correspondence Address is required",
                            },
                          })}
                          id="clientAddress"
                          name="clientAddress"
                          type="text"
                          className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Correspondence Address"
                        />
                        <ErrorTest errors={errors} field="clientAddress" />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          PAN of the client:
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("clientPAN", {
                            required: {
                              value: true,
                              message: "PAN is required",
                            },
                          })}
                          id="clientPAN"
                          name="clientPAN"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="PAN"
                        />
                        <ErrorTest errors={errors} field="clientPAN" />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Major business areas of the client:{" "}
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("majorBuisness", {
                            required: {
                              value: true,
                              message: "This is required",
                            },
                          })}
                          id="majorBuisness"
                          name="majorBuisness"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Business Area 1, Business Area 2, and so on"
                        />
                        <ErrorTest errors={errors} field="majorBuisness" />
                      </div>
                      <Button
                        onClick={async () => {
                          const result = await trigger();
                          console.log(result);
                          if (result) {
                            // setShowClientFunnel(true);
                            setStep(2);
                          }
                        }}
                        type="button"
                        className="text-white rounded my-4"
                      >
                        {" "}
                        Continue
                      </Button>
                    </>
                  )}
                </>
              )}

              {step === 2 && (
                <>
                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Type of the consultant:
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("consultantType")}
                      type="radio"
                      value="Business"
                      id="consultantType"
                    />
                    {"  "}
                    Business
                  </div>

                  <div className="mt-2">
                    <input
                      {...register("consultantType")}
                      type="radio"
                      value="Individual"
                      id="consultantType"
                    />
                    {"  "}
                    Individual
                  </div>
                  {!consultantTypeValue && (
                    <div className="flex mt-6">
                      <div
                        onClick={() => setStep(1)}
                        className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>

                        <span>Back</span>
                      </div>
                    </div>
                  )}

                  {consultantTypeValue === "Business" && (
                    <>
                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Business name and office address of the consultant:{" "}
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("consultantName", {
                            required: {
                              value: true,
                              message: "Buisness name is required",
                            },
                          })}
                          id="consultantName"
                          name="consultantName"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Business Name"
                        />
                        <ErrorTest errors={errors} field="consultantName" />
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("consultantAddress", {
                            required: {
                              value: true,
                              message: "Office Address is required",
                            },
                          })}
                          id="consultantAddress"
                          name="consultantAddress"
                          type="text"
                          className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Office Address"
                        />
                        <ErrorTest errors={errors} field="consultantAddress" />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Area of specialization of the consultant:{" "}
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("specializeConsultant", {
                            required: {
                              value: true,
                              message: "This is required",
                            },
                          })}
                          id="specializeConsultant"
                          name="specializeConsultant"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Specialization Area 1, Specialization Area 2, and so on"
                        />
                        <ErrorTest
                          errors={errors}
                          field="specializeConsultant"
                        />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Name of the person representing the consultant:{" "}
                        </label>
                      </div>
                      <div className="mt-4">
                        <input
                          {...register("fullNameConsultant", {
                            required: {
                              value: true,
                              message: "Full name is required",
                            },
                          })}
                          id="fullNameConsultant"
                          name="fullNameConsultant"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Full Name"
                        />
                        <ErrorTest errors={errors} field="fullNameConsultant" />
                      </div>

                      <div className="flex gap-4 my-4">
                        <div
                          onClick={() => setStep(1)}
                          className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                            />
                          </svg>

                          <span>Back</span>
                        </div>
                        <div
                          onClick={async () => {
                            const result = await trigger();
                            if (result) {
                              setStep(3);
                            }
                          }}
                          className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                        >
                          <span>Continue</span>
                        </div>
                      </div>
                    </>
                  )}

                  {consultantTypeValue === "Individual" && (
                    <>
                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Full name and correspondence address of the
                          consultant:{" "}
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("consultantName", {
                            required: {
                              value: true,
                              message: "Full name is required",
                            },
                          })}
                          id="consultantName"
                          name="consultantName"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Full Name"
                        />
                        <ErrorTest errors={errors} field="consultantName" />
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("consultantAddress", {
                            required: {
                              value: true,
                              message: "Correspondence address is required",
                            },
                          })}
                          id="consultantAddress"
                          name="consultantAddress"
                          type="text"
                          className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Correspondence address"
                        />
                        <ErrorTest errors={errors} field="consultantAddress" />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          PAN of the consultant:
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("consultantPAN", {
                            required: {
                              value: true,
                              message: "PAN is required",
                            },
                          })}
                          id="consultantPAN"
                          name="consultantPAN"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="PAN"
                        />
                        <ErrorTest errors={errors} field="consultantPAN" />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Area of specialization of the consultant:{" "}
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("specializeConsultant", {
                            required: {
                              value: true,
                              message: "This is required",
                            },
                          })}
                          id="specializeConsultant"
                          name="specializeConsultant"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Specialization Area 1, Specialization Area 2, and so on"
                        />
                        <ErrorTest
                          errors={errors}
                          field="specializeConsultant"
                        />
                      </div>

                      <div className="flex gap-4 my-4">
                        <div
                          onClick={() => setStep(1)}
                          className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                            />
                          </svg>

                          <span>Back</span>
                        </div>
                        <div
                          onClick={async () => {
                            const result = await trigger();
                            if (result) {
                              setStep(3);
                            }
                          }}
                          className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                        >
                          <span>Continue</span>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {step === 3 && (
                <>
                  <div>
                    <label
                      htmlFor="comment"
                      className="text-neutral-900 font-semibold"
                    >
                      The specific services in detail to be rendered by the
                      Consultant under this Agreement (Statement of Work):
                    </label>
                    <div className="mt-2">
                      <textarea
                        {...register("comment", {
                          required: {
                            value: true,
                            message: "This is required",
                          },
                        })}
                        rows={4}
                        name="comment"
                        id="comment"
                        className="block w-full rounded-md border-0 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-500 placeholder:text-neutral-900 focus:ring-inset focus:ring-indigo-500 sm:py-1.5 sm:text-sm sm:leading-6"
                        defaultValue={""}
                        placeholder={`1. First service and detail
2. Second service and detail
and so on...
                        `}
                      />
                    </div>
                    <ErrorTest errors={errors} field="comment" />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Fee:{" "}
                    </label>
                    <div className="flex items-center gap-4 mt-4">
                      <select
                        {...register(`currency`)}
                        id={`currency`}
                        name={`currency`}
                        className="relative block w-full max-w-[125px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="" disabled selected hidden>
                          Currency{" "}
                        </option>
                        <option value="INR">INR</option>
                        <option value="USD">USD</option>
                      </select>

                      <input
                        {...register("amount")}
                        id="amount"
                        name="amount"
                        type="text"
                        className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Amount"
                      />

                      <select
                        {...register(`frequency`)}
                        id={`frequency`}
                        name={`frequency`}
                        className="relative block w-full max-w-[125px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="" disabled selected hidden>
                          Frequency{" "}
                        </option>
                        <option value="Fixed Fee">Fixed Fee</option>
                        <option value="Annualy">Annualy</option>
                        <option value="Semi-anually">Semi-anually</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Daily">Daily</option>
                        <option value="Hourly">Hourly</option>
                      </select>
                    </div>
                  </div>

                  <div className="mt-4">
                    <label className="mt-4 text-neutral-900 font-semibold">
                      Number of days within which payment is to be made by the
                      client after receipt of invoice:
                    </label>
                  </div>
                  <div className="mt-4">
                    <input
                      {...register("no_of_days", {
                        required: {
                          value: true,
                          message: "Number of Days is required",
                        },
                      })}
                      id="no_of_days"
                      name="no_of_days"
                      type="number"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Number of Days. Ex: 7, 14, 30, etc"
                    />
                    <ErrorTest errors={errors} field="no_of_days" />
                  </div>

                  <div className="mt-4">
                    <label className="mt-4 text-neutral-900 font-semibold">
                      Method in which the payment will be made to the
                      Consultant:
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("paymentMethod", {
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                      id="paymentMethod"
                      name="paymentMethod"
                      type="text"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Payment method. Ex: Bank transfer, cheque, etc"
                    />
                    <ErrorTest errors={errors} field="paymentMethod" />
                  </div>

                  <div className="flex gap-4 my-4">
                    <div
                      onClick={() => setStep(2)}
                      className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                        />
                      </svg>

                      <span>Back</span>
                    </div>
                    <div
                      onClick={async () => {
                        const result = await trigger();
                        if (result) {
                          setStep(4);
                        }
                      }}
                      className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                    >
                      <span>Continue</span>
                    </div>
                  </div>
                </>
              )}

              {step === 4 && (
                <>
                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Will the Client pay the Consultant a retainer before
                      starting the Consultancy? A retainer is a fee paid to
                      secure the Service in advance.
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("clientPaid")}
                      type="radio"
                      value="Yes"
                      id="clientPaid"
                    />
                    {"  "}
                    Yes
                  </div>
                  <div className="mt-2">
                    <input
                      {...register("clientPaid")}
                      type="radio"
                      value="No"
                      id="clientPaid"
                    />
                    {"  "}
                    No
                  </div>

                  {!clientPaid && (
                    <div className="flex mt-6">
                      <div
                        onClick={() => {
                          setStep(3);
                        }}
                        className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                          />
                        </svg>

                        <span>Back</span>
                      </div>
                    </div>
                  )}

                  {clientPaid === "Yes" && (
                    <>
                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Retainer fee:{" "}
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("retainerFee", {
                            required: {
                              value: true,
                              message: "This is required",
                            },
                          })}
                          id="retainerFee"
                          name="retainerFee"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Retainer Fee"
                        />
                        <ErrorTest errors={errors} field="retainerFee" />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Intervals at which the Consultant has to submit a
                          report to the Client about the work status:
                        </label>
                        <select
                          {...register(`interval`, {
                            required: {
                              value: true,
                              message: "Please select interval",
                            },
                          })}
                          id={`interval`}
                          name={`interval`}
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 mt-3 text-gray-900 placeholder-gray-500 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          <option value="" disabled selected hidden>
                            Interval
                          </option>
                          <option value="Annualy">Annualy</option>
                          <option value="Semi-annualy">Semi-annualy</option>
                          <option value="Quartely">Quartely</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Weekly">Weekly</option>

                          <option value="Daily">Daily</option>
                          <option value="Hourly">Hourly</option>
                        </select>
                        <ErrorTest errors={errors} field="interval" />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Starting date of the consultancy service:{" "}
                        </label>
                        <input
                          className="my-4 relative block appearance-none rounded border border-neutral-500 px-3 py-1 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          type="date"
                          id="date_format_final"
                          name="date_format_final"
                          {...register("date_format_final", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                          // max={new Date().toISOString().split("T")[0]}
                        />
                        <ErrorTest errors={errors} field="date_format_final" />
                      </div>

                      <div className="flex gap-4 items-center">
                        <div
                          onClick={() => {
                            setStep(3);
                          }}
                          className="cursor-pointer px-3 py-2 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                            />
                          </svg>

                          <span>Back</span>
                        </div>
                        <Button
                          //   onClick={async () => {
                          //     const result = await trigger();
                          //     if (result) {
                          //       setStep(4);
                          //     }
                          //   }}
                          type="submit"
                          className="text-white rounded my-4"
                        >
                          {" "}
                          Generate Document
                        </Button>
                      </div>
                    </>
                  )}

                  {clientPaid === "No" && (
                    <>
                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Intervals at which the Consultant has to submit a
                          report to the Client about the work status:
                        </label>
                        <select
                          {...register(`interval`, {
                            required: {
                              value: true,
                              message: "Please select interval",
                            },
                          })}
                          id={`interval`}
                          name={`interval`}
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 mt-3 text-gray-900 placeholder-gray-500 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          <option value="" disabled selected hidden>
                            Interval
                          </option>
                          <option value="Annualy">Annualy</option>
                          <option value="Semi-annualy">Semi-annualy</option>
                          <option value="Quartely">Quartely</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Weekly">Weekly</option>

                          <option value="Daily">Daily</option>
                          <option value="Hourly">Hourly</option>
                        </select>
                        <ErrorTest errors={errors} field="interval" />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Starting date of the consultancy service:{" "}
                        </label>
                        <input
                          className="my-4 relative block appearance-none rounded border border-neutral-500 px-3 py-1 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          type="date"
                          id="date_format_final"
                          name="date_format_final"
                          {...register("date_format_final", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                          // max={new Date().toISOString().split("T")[0]}
                        />
                        <ErrorTest errors={errors} field="date_format_final" />
                      </div>

                      <div className="flex gap-4 items-center">
                        <div
                          onClick={() => {
                            setStep(3);
                          }}
                          className="cursor-pointer px-3 py-2 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                            />
                          </svg>

                          <span>Back</span>
                        </div>
                        <Button
                          //   onClick={async () => {
                          //     const result = await trigger();
                          //     if (result) {
                          //       setStep(4);
                          //     }
                          //   }}
                          type="submit"
                          className="text-white rounded my-4"
                        >
                          {" "}
                          Generate Document
                        </Button>
                      </div>
                    </>
                  )}
                  <DownloadPDFWorkModal
                    open={showCopyDownloadModal}
                    closeModal={setShowCopyDownloadModal}
                    updatedObj={formData}
                    docType="consulting-agreement"
                    reset={reset}
                  />
                </>
              )}
            </form>
          </FormProvider>
        </div>
      </div>
      <DownloadTemplateModal
        open={showDownloadTemplate}
        closeModal={setShowDownloadTemplate}
        templateCode="consulting-agreement"
      />
    </>
  );
}
