import {
  ArrowDownTrayIcon,
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useMemo, useState } from "react";
import DeleteFormationFileConfirmationModal from "src/components/FormationDiligenceModals/DeleteFormationFileConfirmationModal";
import RenameFormationFileModal from "src/components/FormationDiligenceModals/RenameFormationFileModal";
import UploadFormDillFileModal from "src/components/FormationDiligenceModals/UploadFormDillFileModal";
import FormationCustomContextMenu from "src/components/FormationContextMenu";
import { useAuth } from "src/context/AuthProvider";
import { useCompanyData } from "src/context/CompanyDataProvider";
import { useDocumentData } from "src/context/DocumentProvider";
import { useFormationDocuments } from "src/hooks/useFormationDocuments";

const menuItems = [
  {
    name: "Rename",
    icon: PencilSquareIcon,
  },
  {
    name: "Download",
    icon: ArrowDownTrayIcon,
  },
  {
    name: "Delete",
    icon: TrashIcon,
  },
];

const menuItemsMember = [
  {
    name: "Download",
    icon: ArrowDownTrayIcon,
  },
];

// const FileItem = ({
//   item,
//   index,
//   record,
//   fileIconList,
//   CompanyData,
//   handleFileClick,
// }) => {
//   const [showIconsDiv, setShowIconsDiv] = useState(false);
//   const [showIconHoverData, setShowIconHoverData] = useState({
//     visible: false,
//     iconActionName: "",
//     xPos: 0,
//     yPos: 0,
//   });
//   const [showHoverData, setshowHoverData] = useState({
//     visible: false,
//     xPos: 0,
//     yPos: 0,
//   });

//   const onHover = (e) => {
//     setshowHoverData({
//       visible: true,
//       xPos: e.clientX,
//       yPos: e.clientY,
//     });
//   };

//   const onLeave = (e) => {
//     setshowHoverData({
//       visible: false,
//       xPos: e.clientX,
//       yPos: e.clientY,
//     });
//   };

//   const style = {
//     top: showHoverData.yPos,
//     left: showHoverData.xPos,
//   };

//   const onIconHover = (e, iconAction) => {
//     if (iconAction === "Rename") {
//       setShowIconHoverData({
//         visible: true,
//         iconActionName: iconAction,
//         xPos: "10%" || e.clientX,
//         yPos: e.clientY,
//       });
//     } else if (iconAction === "Download") {
//       setShowIconHoverData({
//         visible: true,
//         iconActionName: iconAction,
//         xPos: "40%" || e.clientX,
//         yPos: e.clientY,
//       });
//     } else {
//       setShowIconHoverData({
//         visible: true,
//         iconActionName: iconAction,
//         xPos: "70%" || e.clientX,
//         yPos: e.clientY,
//       });
//     }
//   };

//   const onIconLeave = (e, iconAction) => {
//     setShowIconHoverData({
//       visible: false,
//       iconActionName: "",
//       xPos: e.clientX,
//       yPos: e.clientY,
//     });
//   };

//   const styleIcon = {
//     // top: showHoverData.yPos,
//     left: showIconHoverData.xPos,
//   };

//   const handleFileItemClick = (e) => {
//     setShowIconsDiv(true);
//   };

//   const handleFileIconClick = (fileItem) => {
//     handleFileClick(fileItem.name, item.id, record.id, item.name);
//     setShowIconsDiv(false);
//     setshowHoverData({
//       visible: false,
//       xPos: 0,
//       yPos: 0,
//     });
//   };

//   return (
//     <div className="relative" key={item.id}>
//       {!showIconsDiv ? (
//         <>
//           <div
//             onClick={handleFileItemClick}
//             onMouseEnter={onHover}
//             onMouseLeave={onLeave}
//             key={item.id}
//             id={item.id}
//             className={`fileTile ${item.name} hover:bg-neutral-200 cursor-default flex items-center justify-between p-4 w-[190px] h-[49px] border border-neutral-300 bg-white shadow-[0_4px_4px_rgba(23,26,31,0.1)] mr-5`}
//           >
//             <span className="text-sm text-neutral-900 ellipseFolderName">
//               {item.name}
//             </span>
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               strokeWidth={1.5}
//               stroke="currentColor"
//               className="w-6 h-6"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
//               />
//             </svg>
//           </div>
//           {showHoverData.visible && (
//             <div
//               className={`fixed w-[160px] py-2 shadow-md rounded-md bg-secondary-500 menuList`}
//               style={style}
//             >
//               <p className="text-white text-center text-sm px-2">
//                 Click for more options
//               </p>
//             </div>
//           )}
//         </>
//       ) : (
//         <>
//           <div
//             key={item.id}
//             id={item.id}
//             className={`fileItemIcons ${item.name} cursor-default flex items-center justify-between p-4 w-[190px] h-[49px] border border-neutral-300 bg-primary-100 shadow-[0_4px_4px_rgba(23,26,31,0.1)] mr-5`}
//           >
//             {fileIconList?.map((fileItem, index) => (
//               <div
//                 onMouseEnter={(e) => onIconHover(e, fileItem.name)}
//                 onMouseLeave={(e) => onIconLeave(e, fileItem.name)}
//                 key={index}
//                 onClick={() => handleFileIconClick(fileItem)}
//                 className="flex gap-2 items-center cursor-pointer px-2 py-2 text-neutral-700 text-sm hover:text-neutral-900"
//               >
//                 <fileItem.icon
//                   className="flex-shrink-0 h-6 w-6"
//                   aria-hidden="true"
//                 />
//               </div>
//             ))}
//           </div>
//           {showIconHoverData?.visible && (
//             <div
//               className={`absolute py-2 shadow-md rounded-md bg-secondary-500 menuList`}
//               style={styleIcon}
//             >
//               <p className="text-white text-center text-sm px-2">
//                 {showIconHoverData.iconActionName}
//               </p>
//             </div>
//           )}
//         </>
//       )}
//     </div>
//   );

//   return (
//     <FormationCustomContextMenu
//       // items={menuItems}
//       items={CompanyData?.userRole !== "MEMBER" ? menuItems : menuItemsMember}
//       targetId={item.id}
//       data={{
//         recordId: record.id,
//         fileName: item.name,
//       }}
//       handleClick={handleFileClick}
//     >
//       <div
//         onMouseEnter={onHover}
//         onMouseLeave={onLeave}
//         key={item.id}
//         id={item.id}
//         className={`${item.name}  hover:bg-neutral-200 cursor-default flex items-center justify-between p-4 w-[190px] h-[49px] border border-neutral-300 bg-white shadow-[0_4px_4px_rgba(23,26,31,0.1)] mr-5`}
//       >
//         <span
//           // title="Right click for options"
//           className="text-sm text-neutral-900 ellipseFolderName"
//         >
//           {item.name}
//         </span>
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 24 24"
//           strokeWidth={1.5}
//           stroke="currentColor"
//           className="w-6 h-6"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
//           />
//         </svg>
//       </div>
//       {showHoverData.visible && (
//         <div
//           // ref={menuRef}
//           className={`fixed w-[160px] py-2 shadow-md rounded-md bg-secondary-500 menuList`}
//           style={style}
//         >
//           <p className="text-white text-center text-sm px-2">
//             Click for more options
//           </p>
//         </div>
//       )}
//     </FormationCustomContextMenu>
//   );
// };

const FileItem = ({
  item,
  index,
  record,
  fileIconList,
  CompanyData,
  handleFileClick,
}) => {
  const [showIconsDiv, setShowIconsDiv] = useState(false);
  const [showIconHoverData, setShowIconHoverData] = useState({
    visible: false,
    iconActionName: "",
    xPos: 0,
    yPos: 0,
  });

  const onHover = (e) => {
    setShowIconsDiv(true);
  };

  const onLeave = (e) => {
    setShowIconsDiv(false);
  };

  const onIconHover = (e, iconAction) => {
    if (iconAction === "Rename") {
      setShowIconHoverData({
        visible: true,
        iconActionName: iconAction,
        xPos: "4%" || e.clientX,
        yPos: e.clientY,
      });
    } else if (iconAction === "Download") {
      setShowIconHoverData({
        visible: true,
        iconActionName: iconAction,
        xPos: "30%" || e.clientX,
        yPos: e.clientY,
      });
    } else {
      setShowIconHoverData({
        visible: true,
        iconActionName: iconAction,
        xPos: "60%" || e.clientX,
        yPos: e.clientY,
      });
    }
  };

  const onIconDivLeave = () => {
    setShowIconsDiv(false);
  };

  const onIconLeave = (e, iconAction) => {
    setShowIconHoverData({
      visible: false,
      iconActionName: "",
      xPos: e.clientX,
      yPos: e.clientY,
    });
  };

  const styleIcon = {
    // top: showHoverData.yPos,
    left: showIconHoverData.xPos,
  };

  const handleFileIconClick = (fileItem) => {
    setShowIconsDiv(false);
    handleFileClick(fileItem.name, item.id, record.id, item.name);
  };

  return (
    <div className="relative" key={item.id}>
      {!showIconsDiv ? (
        <>
          <div
            onMouseEnter={onHover}
            onMouseLeave={onLeave}
            key={item.id}
            id={item.id}
            className={`z-1 fileTile ${item.name} hover:bg-neutral-200 cursor-default flex items-center justify-between p-4 w-[190px] h-[49px] border border-neutral-300 bg-white shadow-[0_4px_4px_rgba(23,26,31,0.1)] mr-5`}
          >
            <span className="text-sm text-neutral-900 ellipseFolderName">
              {item.name}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
              />
            </svg>
          </div>
        </>
      ) : (
        <>
          <div
            onMouseLeave={onIconDivLeave}
            key={item.id}
            id={item.id}
            className={`fileItemIcons ${showIconsDiv ? "show" : ""} ${
              item.name
            }  cursor-default flex items-center justify-between p-4 w-[190px] h-[49px] border border-neutral-300 bg-primary-100 shadow-[0_4px_4px_rgba(23,26,31,0.1)] mr-5`}
          >
            {fileIconList?.map((fileItem, index) => (
              <div
                onMouseEnter={(e) => onIconHover(e, fileItem.name)}
                onMouseLeave={(e) => onIconLeave(e, fileItem.name)}
                key={index}
                onClick={() => handleFileIconClick(fileItem)}
                className="flex gap-2 items-center cursor-pointer px-2 py-2 text-neutral-700 text-sm hover:text-neutral-900"
              >
                <fileItem.icon
                  className="flex-shrink-0 h-6 w-6"
                  aria-hidden="true"
                />
              </div>
            ))}
          </div>
          {showIconHoverData?.visible && (
            <div
              className={`z-10 absolute py-1 shadow-md rounded-md bg-secondary-500 menuList`}
              style={styleIcon}
            >
              <p className="text-white text-center text-sm px-2">
                {showIconHoverData.iconActionName}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default function FemaDocuments(props) {
  // console.log("test", props);
  const [itemId, setItemId] = useState("");
  const [recordId, setRecordId] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [oldFileName, setOldFileName] = useState("");
  const [showDeleteFileConfirmation, setShowDeleteFileConfirmation] =
    useState(false);
  const [showRenameFileConfirmation, setShowRenameFileConfirmation] =
    useState(false);

  const {
    state: docData,
    selectedValues,
    setSelectedValues,
  } = useDocumentData();
  const { state: CompanyData } = useCompanyData();
  const { state: authData, fetchTokenManually, idToken } = useAuth();
  const { downloadFormationFile } = useFormationDocuments();
  const companyId = CompanyData?.id || window.localStorage.getItem("companyId");

  // console.log("docData", docData);

  // const FemaDocs = useMemo(() => {
  //   console.log("useMemo");
  //   return docData?.documentData?.find((item, index) => {
  //     return item.name === props.title;
  //   });
  // }, [props.title, idToken, fetchAgain]);

  const FemaDocs = docData?.documentData?.find(
    (item, index) => item.name === props.title
  );

  const handleCheckboxChange = (e, item) => {
    const itemChecked = {
      id: item.id || "",
      kind: item.kind || "",
    };
    setSelectedPeople(
      e.target.checked
        ? [...selectedPeople, item.kind]
        : selectedPeople.filter((p) => p !== item.kind)
    );
    // setSelectedValues(
    //   e.target.checked
    //     ? [...selectedValues, item.kind]
    //     : selectedValues.filter((p) => p !== item.kind)
    // );
    setSelectedValues(
      e.target.checked
        ? [...selectedValues, itemChecked]
        : selectedValues.filter((p) => p.id !== item.id)
    );
    // if (e) {
    //   // props?.closeShareModal();
    //   props?.closeActionModal();
    // }
    if (docData?.chooseAction === "delete") {
      props?.closeDeleteModal();
    } else if (docData?.chooseAction === "share") {
      props?.closeShareModal();
    } else {
      props?.closeActionModal();
    }
  };

  const handleAddFileClick = (id) => {
    // hiddenFileInput.current.click();
    setRecordId(id);
    setShowUploadModal(!showUploadModal);
  };

  const handleFileClick = async (
    clickAction,
    fileClickedId,
    recordId,
    olderfileName
  ) => {
    console.log(clickAction);
    console.log(fileClickedId);
    console.log(recordId);
    console.log(olderfileName);

    if (clickAction === "Download") {
      const updatedToken = await fetchTokenManually();
      const token = updatedToken || authData?.idTokenUpdated || idToken;
      downloadFormationFile(companyId, recordId, fileClickedId, token)
        .then((res) => {
          console.log(res);
          const href = URL.createObjectURL(res.data);
          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", olderfileName); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (clickAction === "Delete") {
      setItemId(fileClickedId);
      setRecordId(recordId);
      setShowDeleteFileConfirmation(!showDeleteFileConfirmation);
    } else {
      setOldFileName(olderfileName);
      setItemId(fileClickedId);
      setRecordId(recordId);
      setShowRenameFileConfirmation(!showRenameFileConfirmation);
    }
  };

  console.log("FEMA", FemaDocs);

  return (
    <>
      <div className="space-y-6 mt-8">
        {FemaDocs?.records?.length &&
          FemaDocs?.records?.map((record, index) => {
            return (
              <div
                key={record.id || index}
                className="flex items-center w-full"
              >
                <div className="flex items-center w-full max-w-[50%]">
                  <div className="flex h-5 items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-neutral-600 text-neutral-900 checked:text-tertiary4-500 checked:border checked:border-tertiary4-500"
                      value={record.kind}
                      checked={
                        selectedPeople.includes(record.kind) ||
                        selectedValues.some((elem) => elem?.id === record?.id)
                        // selectedValues.includes(record)
                      }
                      onChange={(e) => handleCheckboxChange(e, record)}
                    />
                  </div>
                  <div className="ml-3 text-base">
                    <span id="confirm" className="text-neutral-900">
                      {record.kind}:
                    </span>
                  </div>
                </div>
                {record.items.length > 0 ? (
                  <>
                    <div className="flex flex-wrap items-end w-full space-y-2">
                      {record.items.map((item, index) => (
                        <FileItem
                          item={item}
                          index={index}
                          record={record}
                          fileIconList={
                            CompanyData?.userRole !== "MEMBER"
                              ? menuItems
                              : menuItemsMember
                          }
                          CompanyData={CompanyData}
                          handleFileClick={handleFileClick}
                        />
                      ))}
                      {record.allowMultipleItems && (
                        <div
                          onClick={() => handleAddFileClick(record.id)}
                          className="cursor-pointer flex items-center justify-center p-4 w-[190px] h-[49px] bg-white hover:bg-primary-100 hover:border-none border-dashed border-neutral-700 border mr-5"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 text-tertiary4-500"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6v12m6-6H6"
                            />
                          </svg>
                          <span className="text-sm text-tertiary4-500">
                            Add More
                          </span>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {!record.allowMultipleItems ? (
                      <div
                        onClick={() => handleAddFileClick(record.id)}
                        className="cursor-pointer flex items-center justify-center p-4 w-[190px] h-[49px] bg-white hover:bg-primary-100 hover:border-none border-dashed border-neutral-700 border mr-5"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5 text-tertiary4-500"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6v12m6-6H6"
                          />
                        </svg>
                        <span className="text-sm text-tertiary4-500">
                          Add File
                        </span>
                      </div>
                    ) : (
                      <>
                        <div
                          onClick={() => handleAddFileClick(record.id)}
                          className="cursor-pointer flex items-center justify-center p-4 w-[190px] h-[49px] bg-white hover:bg-primary-100 hover:border-none border-dashed border-neutral-700 border mr-5"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 text-tertiary4-500"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6v12m6-6H6"
                            />
                          </svg>
                          <span className="text-sm text-tertiary4-500">
                            Add File
                          </span>
                        </div>
                        <div
                          onClick={() => handleAddFileClick(record.id)}
                          className="cursor-pointer flex items-center justify-center p-4 w-[190px] h-[49px] bg-white hover:bg-primary-100 hover:border-none border-dashed border-neutral-700 border mr-5"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 text-tertiary4-500"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6v12m6-6H6"
                            />
                          </svg>
                          <span className="text-sm text-tertiary4-500">
                            Add More
                          </span>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            );
          })}
      </div>
      <UploadFormDillFileModal
        open={showUploadModal}
        closeModal={setShowUploadModal}
        fetchAgain={fetchAgain}
        setFetchAgain={setFetchAgain}
        recordId={recordId}
      />
      <DeleteFormationFileConfirmationModal
        open={showDeleteFileConfirmation}
        closeModal={setShowDeleteFileConfirmation}
        fetchAgain={fetchAgain}
        setFetchAgain={setFetchAgain}
        itemId={itemId}
        recordId={recordId}
      />
      <RenameFormationFileModal
        open={showRenameFileConfirmation}
        closeModal={setShowRenameFileConfirmation}
        fetchAgain={fetchAgain}
        setFetchAgain={setFetchAgain}
        itemId={itemId}
        recordId={recordId}
        oldFileName={oldFileName}
      />
    </>
  );
}
