import React, { useEffect, useState } from "react";
import {
  useSearchParams,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
// import Accordian from "src/components/Accordian";

import AddNewFolderModal from "src/components/InvoiceModal/AddNewFolderModal";
import NewContextMenu from "src/components/NewContextMenu";
import { useCompanyData } from "src/context/CompanyDataProvider";
import { getCurrentFinancialYear } from "src/services/helpers/getCurrentFinancialYear";
import {
  PencilSquareIcon,
  XMarkIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";

import "./invoice.scss";
import OpenFolderModal from "src/components/InvoiceModal/OpenFolder";
import { useAuth } from "src/context/AuthProvider";
import { toast, ToastContainer } from "react-toastify";
import { useInvoice } from "src/hooks/useInvoice";
import UploadFileModal from "src/components/InvoiceModal/UploadFileModal";
import RenameFolderModal from "src/components/InvoiceModal/RenameFolderModal";
import RenameFileModal from "src/components/InvoiceModal/RenameFileModal";
import InitialModal from "src/components/BankAccountModal/InitialModal";
import AdvanceAccordian from "src/components/AdvanceAccordian";
import DeleteFolderConfirmationModal from "src/components/InvoiceModal/DeleteFolderConfirmationModal";
import DeleteFileConfirmationModal from "src/components/InvoiceModal/DeleteFileConfirmationModal";

// const monthWiseData = [
//   {
//     monthName: "January 2022",
//     data: {
//       folders: [
//         {
//           folderName: "Purchase Invoice",
//           children: {
//             folders: [
//               {
//                 folderName: "Expired Invoices",
//                 children: {
//                   folders: [
//                     {
//                       folderName: "Expired Invoices 2",
//                       children: {},
//                     },
//                   ],
//                   files: [
//                     {
//                       fileName: "Invoice 21212",
//                     },
//                   ],
//                 },
//               },
//             ],
//             files: [
//               {
//                 fileName: "Invoice 1212",
//               },
//             ],
//           },
//         },
//       ],
//       files: [
//         {
//           fileName: "Invoice1",
//         },
//         {
//           fileName: "Invoice2",
//         },
//         {
//           fileName: "Invoice3",
//         },
//         {
//           fileName: "Invoice4",
//         },
//         {
//           fileName: "Invoice5",
//         },
//         {
//           fileName: "Invoice6",
//         },
//         {
//           fileName: "Invoice7",
//         },
//         {
//           fileName: "Invoice8",
//         },
//       ],
//     },
//   },
//   {
//     monthName: "February 2022",
//     data: {
//       folders: [
//         {
//           folderName: "Sales Invoice",
//           children: {
//             folders: [
//               {
//                 folderName: "Expired Invoices",
//                 children: {
//                   folders: [
//                     {
//                       folderName: "Expired Invoices 2",
//                       children: {},
//                     },
//                   ],
//                   files: [
//                     {
//                       fileName: "Invoice 21212",
//                     },
//                   ],
//                 },
//               },
//             ],
//             files: [
//               {
//                 fileName: "Invoice 1212",
//               },
//             ],
//           },
//         },
//         {
//           folderName: "Purchase Invoice",
//           children: {
//             folders: [
//               {
//                 folderName: "Expired Invoices",
//                 children: {
//                   folders: [
//                     {
//                       folderName: "Expired Invoices 2",
//                       children: {},
//                     },
//                   ],
//                   files: [
//                     {
//                       fileName: "Invoice 21212",
//                     },
//                   ],
//                 },
//               },
//             ],
//             files: [
//               {
//                 fileName: "Invoice 1212",
//               },
//             ],
//           },
//         },
//       ],
//       files: [],
//     },
//   },
//   {
//     monthName: "March 2022",
//     data: {
//       folders: [],
//       files: [],
//     },
//   },
// ];

// const monthWiseData = [
//   {
//     monthName: "October 2022",
//     data: {
//       folders: [
//         {
//           folderName: "Purchase Invoice",
//           folderId: "122",
//         },
//       ],
//       files: [
//         {
//           fileName: "Invoice1",
//         },
//         {
//           fileName: "Invoice2",
//         },
//         {
//           fileName: "Invoice3",
//         },
//       ],
//     },
//   },
//   {
//     monthName: "November 2022",
//     data: {
//       folders: [
//         {
//           folderName: "Sales Invoice",
//           folderId: "123",
//         },
//         {
//           folderName: "Purchase Invoice",
//           folderId: "124",
//         },
//       ],
//       files: [],
//     },
//   },
//   {
//     monthName: "December 2022",
//     data: {
//       folders: [],
//       files: [],
//     },
//   },
// ];

const menuItems = [
  {
    name: "Rename",
    icon: PencilSquareIcon,
  },
  {
    name: "Download",
    icon: ArrowDownTrayIcon,
  },
  {
    name: "Delete",
    icon: XMarkIcon,
  },
];

const folderItems = [
  {
    name: "Rename",
    icon: PencilSquareIcon,
  },
  {
    name: "Delete",
    icon: XMarkIcon,
  },
];

const folderItemsMember = [];

const menuItemsMember = [
  {
    name: "Download",
    icon: ArrowDownTrayIcon,
  },
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Invoice() {
  const [fYearList, setFYearList] = useState([getCurrentFinancialYear()]);
  const [monthWiseData, setMonthWiseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [monthName, setMonthName] = useState("");
  const [folderClickedId, setFolderClickedId] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [oldFolderName, setOldFolderName] = useState("");
  const [fileClickedId, setFileClickedId] = useState("");
  const [oldFileName, setOldFileName] = useState("");
  const [fetchAgain, setFetchAgain] = useState(false);
  const [showAddFolder, setshowAddFolder] = useState(false);
  const [showRenameFolder, setShowRenameFolder] = useState(false);
  const [showRenameFile, setShowRenameFile] = useState(false);
  const [showDeleteFileConfirmation, setShowDeleteFileConfirmation] =
    useState(false);
  const [showDeleteFolderConfirmation, setShowDeleteFolderConfirmation] =
    useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showInitialModal, setShowInitialModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  // const [showOpenFolder, setShowOpenFolder] = useState(false);
  // const [initialFolderDetails, setInitialFolderDetails] = useState({});
  // const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { fYear } = useParams();
  // const hiddenFileInput = React.useRef(null);
  const { idToken } = useAuth();
  const { state: CompanyData } = useCompanyData();
  const {
    getListOfFinancialYear,
    getMonthWiseDataInFinancialYear,
    deleteFolder,
    downloadFile,
    deleteFile,
  } = useInvoice();

  // let fYear = search.get("fy") || getCurrentFinancialYear();
  const financialYear = fYear || getCurrentFinancialYear();
  const companyId = CompanyData?.id || window.localStorage.getItem("companyId");

  const handleChange = (e) => {
    // console.log(e.target.value);
    const splitFYear = e.target.value.split(" ");
    // console.log(splitFYear[1]);
    navigate(`/invoice/${splitFYear[1]}`);
    // setSearch({ fy: e.target.value });
  };

  const handleFileClick = async (
    clickAction,
    fileClickedId,
    monthName,
    oldFileName
  ) => {
    console.log(clickAction);
    console.log(fileClickedId);
    console.log(monthName);

    if (clickAction === "Download") {
      downloadFile(companyId, financialYear, monthName, fileClickedId, idToken)
        .then((res) => {
          console.log(res);
          const href = URL.createObjectURL(res.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", oldFileName); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (clickAction === "Delete") {
      setMonthName(monthName);
      setFileClickedId(fileClickedId);
      setDeleteName(oldFileName);
      setShowDeleteFileConfirmation(!showDeleteFileConfirmation);
    } else {
      setMonthName(monthName);
      setFileClickedId(fileClickedId);
      setOldFileName(oldFileName);
      setShowRenameFile(!showRenameFile);
    }
  };

  const handleFolderClick = async (
    clickAction,
    folderClickedId,
    monthName,
    oldFolderName
  ) => {
    console.log(clickAction);
    if (clickAction === "Rename") {
      setMonthName(monthName);
      setFolderClickedId(folderClickedId);
      setOldFolderName(oldFolderName);
      setShowRenameFolder(!showRenameFolder);
    } else {
      setMonthName(monthName);
      setFolderClickedId(folderClickedId);
      setDeleteName(oldFolderName);
      setShowDeleteFolderConfirmation(!showDeleteFolderConfirmation);
    }
  };

  const handleDoubleClick = (folderId, monthName) => {
    console.log("folderId", folderId);
    console.log("monthName", monthName);
    navigate(`${monthName}/${folderId}`);
    // const [monthParticularData] = monthWiseData
    //   .filter((item) => item?.monthName == monthName)
    //   .map((item) => item.data);
    // console.log(monthParticularData);
    // const folderData = monthParticularData.folders
    //   .filter((item) => item.folderName == folderName)
    //   .map((item) => item.children);
    // console.log(folderData);
    // navigate(`/invoice/${monthName}?fy=${fYear}`, { state: folderData[0] });
    // const data = monthParticularData.folders;
    // setInitialFolderDetails(data[0]);
    // setShowOpenFolder(true);
  };

  const handleFileDoubleClick = (fileClickedId, monthName, fileName) => {
    downloadFile(companyId, financialYear, monthName, fileClickedId, idToken)
      .then((res) => {
        console.log(res);
        const href = URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // const handleMonthDataClick = (monthName) => {
  //   console.log("monthName", monthName);
  //   navigate(`${monthName}`);
  //   // const [monthParticularData] = monthWiseData
  //   //   .filter((item) => item?.monthName == monthTitle)
  //   //   .map((item) => item.data);
  //   // console.log(monthParticularData);
  //   // const data = monthParticularData.folders
  //   // const data = monthParticularData.folders;
  //   // navigate(`/invoice/${monthTitle}?fy=${fYear}`);
  // };

  const handleAddFileClick = (monthName) => {
    // hiddenFileInput.current.click();
    setMonthName(monthName);
    setShowUploadModal(!showUploadModal);
  };

  useEffect(() => {
    const fetchAvailableYear = async () => {
      if (idToken) {
        const companyId =
          CompanyData?.id || window.localStorage.getItem("companyId");

        Promise.all([
          getListOfFinancialYear(companyId, idToken),
          getMonthWiseDataInFinancialYear(companyId, financialYear, idToken),
        ])
          .then((res) => {
            const [fYearList, monthWiseData] = res;
            setFYearList(fYearList.data);
            setMonthWiseData(monthWiseData.data);
            setActiveIndex(monthWiseData.data.length - 1);
            setLoading(false);
            console.log(res);
          })
          .catch((err) => {
            console.log("err", err);
            setLoading(false);
            toast.error(
              err?.response?.data?.message || "Something went wrong!!",
              {
                className: "toast-position",
                position: "bottom-center",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          });
      } else {
        console.log("token not found");
      }
    };

    fetchAvailableYear();
  }, [idToken, financialYear, fetchAgain]);

  useEffect(() => {
    let pop_status = localStorage.getItem("pop_status");
    if (!pop_status) {
      setShowInitialModal(true);
      localStorage.setItem("pop_status", "1");
    }
  }, []);

  const getCurrentTitle = () => {
    if (location.pathname.includes("/financial")) {
      return "Financials";
    }

    if (location.pathname.includes("/invoice")) {
      return "Monthly Invoices";
    }

    switch (location.pathname) {
      case "/":
        return "Bank Accounts";
      case "/invoice":
        return "Monthly Invoices";
      case "/settings":
        return "Settings";
      case "/co-workers":
        return "Co-workers";
      case "/account":
        return "Your Account";
      case "/gethelp":
        return "Get Help";
      case "/invite":
        return "Invite";
      default:
        return "";
    }
  };

  console.log(monthWiseData);

  return (
    <div className="invoice">
      {/* <div className="fixed top-2.5 ml-64 md:ml-52 z-10">
        <select
          id="fYear"
          name="fYear"
          className="mt-1 block cursor-pointer rounded-md border-tertiary4-500 py-2 pl-3 pr-10 text-base hover:bg-primary-100 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500 sm:text-sm"
          onChange={handleChange}
          defaultValue={fYearList[0] || `FY ${getCurrentFinancialYear()}`}
        >
          <option value="" disabled selected hidden>
            Select Year
          </option>

          {fYearList.map((item) => {
            return <option value={item}>{item}</option>;
          })}
        </select>
      </div> */}
      <div className="flex items-center gap-5 z-10 pl-[30px] border-b border-neutral-400">
        <span className="text-xl text-neutral-900 font-medium inline-block py-5">
          {getCurrentTitle()}
        </span>
        <select
          id="fYear"
          name="fYear"
          className="mt-1 block cursor-pointer rounded-md border-neutral-500 py-2 pl-3 pr-10 text-base hover:bg-primary-100 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500 sm:text-sm"
          onChange={handleChange}
          defaultValue={fYearList[0] || `FY ${getCurrentFinancialYear()}`}
        >
          <option value="" disabled selected hidden>
            Select Year
          </option>

          {fYearList.map((item) => {
            return <option value={item}>{item}</option>;
          })}
        </select>
      </div>
      <div className="ml-[30px] mr-5 mt-6 mb-20">
        <p className="text-neutral-900 ">
          {/* Start adding invoices and other cashflow documents like expenses,
          payroll etc. for the month of {months[new Date().getMonth()]}{" "}
          {new Date().getFullYear()}. They would be picked up to generate your
          monthly financial report along with all the necessary tax filings. */}
          Add your monthly invoices and other cashflow documents like expenses,
          payroll etc. here. They would be picked up to generate your monthly
          financial report along with all the necessary tax filings.
        </p>
        {loading ? (
          <div className="hor-ver-center fixed mt-36">
            <p>Loading...</p>
          </div>
        ) : (
          // <div className="divide-y-[3px] divide-neutral-400 mt-4">
          //   {monthWiseData.map((monthItem) => {
          //     const content = (
          //       <div>
          //         <div className="mt-5">
          //           <p className="mb-4 text-neutral-900 text-sm font-semibold">
          //             Folders
          //           </p>
          //           <div className="flex flex-wrap flex-1 gap-y-5">
          //             {monthItem.folders.length > 0 &&
          //               monthItem.folders.map((item) => (
          //                 <NewContextMenu
          //                   items={folderItems}
          //                   targetId={item.folderId}
          //                   data={{
          //                     monthName: monthItem.monthName,
          //                     folderName: item.folderName,
          //                   }}
          //                   // monthName={monthItem.monthName}
          //                   handleClick={handleFolderClick}
          //                 >
          //                   <div
          //                     onDoubleClick={() =>
          //                       handleDoubleClick(
          //                         item.folderId,
          //                         monthItem.monthName
          //                       )
          //                     }
          //                     id={item.folderId}
          //                     className={`cursor-default flex items-center justify-between p-4 w-[190px] h-[49px] border border-neutral-300 bg-white shadow-[0_4px_4px_rgba(23,26,31,0.1)] mr-5`}
          //                   >
          //                     <span
          //                       title={item.folderName}
          //                       className="text-sm text-neutral-900 ellipseFolderName"
          //                     >
          //                       {item.folderName}
          //                     </span>
          //                     <svg
          //                       xmlns="http://www.w3.org/2000/svg"
          //                       fill="none"
          //                       viewBox="0 0 24 24"
          //                       strokeWidth={1.5}
          //                       stroke="currentColor"
          //                       className="w-6 h-6"
          //                     >
          //                       <path
          //                         strokeLinecap="round"
          //                         strokeLinejoin="round"
          //                         d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
          //                       />
          //                     </svg>
          //                   </div>
          //                 </NewContextMenu>
          //               ))}
          //             <div
          //               onClick={() => {
          //                 setMonthName(monthItem.monthName);
          //                 setshowAddFolder(true);
          //               }}
          //               className="cursor-pointer flex items-center justify-center p-4 w-[190px] h-[49px] bg-white hover:bg-primary-100 hover:border-none border-dashed border-neutral-700 border mr-5"
          //             >
          //               <svg
          //                 xmlns="http://www.w3.org/2000/svg"
          //                 fill="none"
          //                 viewBox="0 0 24 24"
          //                 strokeWidth={1.5}
          //                 stroke="currentColor"
          //                 className="w-5 h-5 text-tertiary4-500"
          //               >
          //                 <path
          //                   strokeLinecap="round"
          //                   strokeLinejoin="round"
          //                   d="M12 6v12m6-6H6"
          //                 />
          //               </svg>
          //               <span className="text-sm text-tertiary4-500">
          //                 Make New Folder
          //               </span>
          //             </div>
          //           </div>
          //         </div>

          //         <div className="mt-5">
          //           <p className="mb-4 text-neutral-900 text-sm font-semibold">
          //             Files
          //           </p>
          //           <div className="flex flex-wrap flex-1 gap-y-5">
          //             {/* {item.data.files.map((item) => (
          //             <ContextMenuContainer
          //               className={item.fileName}
          //               menuItems={menuItems}
          //             >
          //               <div className="flex items-center justify-between p-4 w-[190px] h-[49px] bg-neutral-300 mr-5">
          //                 <span className="text-sm text-neutral-900">{item.fileName}</span>
          //                 <svg
          //                   xmlns="http://www.w3.org/2000/svg"
          //                   fill="none"
          //                   viewBox="0 0 24 24"
          //                   strokeWidth={1.5}
          //                   stroke="currentColor"
          //                   className="w-6 h-6"
          //                 >
          //                   <path
          //                     strokeLinecap="round"
          //                     strokeLinejoin="round"
          //                     d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
          //                   />
          //                 </svg>
          //               </div>
          //             </ContextMenuContainer>
          //           ))} */}

          //             {/* {item.data.files.map((item) => (
          //             <>
          //               <div
          //                 id={item.fileName}
          //                 className={`${item.fileName} flex items-center justify-between p-4 w-[190px] h-[49px] bg-neutral-300 mr-5`}
          //               >
          //                 <span className="text-sm text-neutral-900">{item.fileName}</span>
          //                 <svg
          //                   xmlns="http://www.w3.org/2000/svg"
          //                   fill="none"
          //                   viewBox="0 0 24 24"
          //                   strokeWidth={1.5}
          //                   stroke="currentColor"
          //                   className="w-6 h-6"
          //                 >
          //                   <path
          //                     strokeLinecap="round"
          //                     strokeLinejoin="round"
          //                     d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
          //                   />
          //                 </svg>
          //               </div>
          //               <CustomContextMenu
          //                 targetId={item.fileName}
          //                 options={["View", "Delete"]}
          //               />
          //             </>
          //           ))} */}

          //             {monthItem.files.length > 0 &&
          //               monthItem?.files?.map((item) => (
          //                 <NewContextMenu
          //                   items={menuItems}
          //                   targetId={item.fileId}
          //                   data={{
          //                     monthName: monthItem.monthName,
          //                     folderName: item.fileName,
          //                   }}
          //                   handleClick={handleFileClick}
          //                 >
          //                   <div
          //                     id={item.fileId}
          //                     className={`${item.fileId} cursor-default flex items-center justify-between p-4 w-[190px] h-[49px] border border-neutral-300 bg-white shadow-[0_4px_4px_rgba(23,26,31,0.1)] mr-5`}
          //                   >
          //                     <span
          //                       title={item.fileName}
          //                       className="text-sm text-neutral-900 ellipseFolderName"
          //                     >
          //                       {item.fileName}
          //                     </span>
          //                     <svg
          //                       xmlns="http://www.w3.org/2000/svg"
          //                       fill="none"
          //                       viewBox="0 0 24 24"
          //                       strokeWidth={1.5}
          //                       stroke="currentColor"
          //                       className="w-6 h-6"
          //                     >
          //                       <path
          //                         strokeLinecap="round"
          //                         strokeLinejoin="round"
          //                         d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
          //                       />
          //                     </svg>
          //                   </div>
          //                 </NewContextMenu>
          //               ))}

          //             <div
          //               onClick={() => handleAddFileClick(monthItem.monthName)}
          //               className="cursor-pointer flex items-center justify-center p-4 w-[190px] h-[49px] bg-white hover:bg-primary-100 hover:border-none border-dashed border-neutral-700 border mr-5"
          //             >
          //               <svg
          //                 xmlns="http://www.w3.org/2000/svg"
          //                 fill="none"
          //                 viewBox="0 0 24 24"
          //                 strokeWidth={1.5}
          //                 stroke="currentColor"
          //                 className="w-5 h-5 text-tertiary4-500"
          //               >
          //                 <path
          //                   strokeLinecap="round"
          //                   strokeLinejoin="round"
          //                   d="M12 6v12m6-6H6"
          //                 />
          //               </svg>
          //               <span className="text-sm text-tertiary4-500">
          //                 Add File
          //               </span>
          //             </div>
          //             {/* <input
          //               type="file"
          //               ref={hiddenFileInput}
          //               onChange={handleFileChange}
          //               style={{ display: "none" }}
          //             /> */}
          //           </div>
          //         </div>
          //       </div>
          //     );
          //     return (
          //       <Accordian
          //         title={monthItem.monthName}
          //         content={content}
          //         className="invoice_accordian"
          //         handleMonthDataClick={handleMonthDataClick}
          //       />
          //     );
          //   })}
          // </div>

          <div className="accordian divide-y divide-neutral-400 mt-4">
            {monthWiseData.map((monthItem, key) => {
              const content = (
                <div>
                  <div className="mt-5">
                    <p className="mb-4 text-neutral-900 text-sm font-semibold">
                      Folders
                    </p>
                    <div
                      key={`Folders${key}`}
                      className="flex flex-wrap flex-1 gap-y-5"
                    >
                      {monthItem.folders.length > 0 ? (
                        monthItem.folders.map((item) => (
                          <NewContextMenu
                            items={
                              CompanyData?.userRole !== "MEMBER"
                                ? folderItems
                                : folderItemsMember
                            }
                            targetId={item.folderId}
                            data={{
                              monthName: monthItem.monthName,
                              folderName: item.folderName,
                            }}
                            // monthName={monthItem.monthName}
                            handleClick={handleFolderClick}
                          >
                            <div
                              onDoubleClick={() =>
                                handleDoubleClick(
                                  item.folderId,
                                  monthItem.monthName
                                )
                              }
                              id={item.folderId}
                              className={`folderTile cursor-default flex items-center justify-between p-4 w-[190px] h-[49px] border border-neutral-300 bg-white shadow-[0_4px_4px_rgba(23,26,31,0.1)] mr-5`}
                            >
                              <span
                                title={item.folderName}
                                className="text-sm text-neutral-900 ellipseFolderName"
                              >
                                {item.folderName}
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                                />
                              </svg>
                            </div>
                          </NewContextMenu>
                        ))
                      ) : (
                        <p className="block w-full text-neutral-900 text-sm">
                          There are no folders added for{" "}
                          {/* {months[new Date().getMonth()]}{" "} */}
                          {monthItem.monthName}
                          {/* {new Date().getFullYear()}. */}
                        </p>
                      )}
                      {CompanyData?.userRole !== "MEMBER" && (
                        <div
                          onClick={() => {
                            setMonthName(monthItem.monthName);
                            setshowAddFolder(true);
                          }}
                          className="cursor-pointer flex items-center justify-center p-4 w-[190px] h-[49px] bg-white hover:bg-primary-100 hover:border-none border-dashed border-neutral-700 border mr-5"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 text-tertiary4-500"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6v12m6-6H6"
                            />
                          </svg>
                          <span className="text-sm text-tertiary4-500">
                            Make New Folder
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-5">
                    <p className="mb-4 text-neutral-900 text-sm font-semibold">
                      Files
                    </p>
                    <div
                      key={`Files${key}`}
                      className="flex flex-wrap flex-1 gap-y-5"
                    >
                      {/* {item.data.files.map((item) => (
                    <ContextMenuContainer
                      className={item.fileName}
                      menuItems={menuItems}
                    >
                      <div className="flex items-center justify-between p-4 w-[190px] h-[49px] bg-neutral-300 mr-5">
                        <span className="text-sm text-neutral-900">{item.fileName}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>
                      </div>
                    </ContextMenuContainer>
                  ))} */}

                      {/* {item.data.files.map((item) => (
                    <>
                      <div
                        id={item.fileName}
                        className={`${item.fileName} flex items-center justify-between p-4 w-[190px] h-[49px] bg-neutral-300 mr-5`}
                      >
                        <span className="text-sm text-neutral-900">{item.fileName}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>
                      </div>
                      <CustomContextMenu
                        targetId={item.fileName}
                        options={["View", "Delete"]}
                      />
                    </>
                  ))} */}

                      {monthItem.files.length > 0 ? (
                        monthItem?.files?.map((item) => (
                          <NewContextMenu
                            // items={menuItems}
                            items={
                              CompanyData?.userRole !== "MEMBER"
                                ? menuItems
                                : menuItemsMember
                            }
                            targetId={item.fileId}
                            data={{
                              monthName: monthItem.monthName,
                              folderName: item.fileName,
                            }}
                            handleClick={handleFileClick}
                          >
                            <div
                              onDoubleClick={() =>
                                handleFileDoubleClick(
                                  item.fileId,
                                  monthItem.monthName,
                                  item.fileName
                                )
                              }
                              id={item.fileId}
                              className={`${item.fileId} cursor-default flex items-center justify-between p-4 w-[190px] h-[49px] border border-neutral-300 bg-white shadow-[0_4px_4px_rgba(23,26,31,0.1)] mr-5`}
                            >
                              <span
                                title={item.fileName}
                                className="text-sm text-neutral-900 ellipseFolderName"
                              >
                                {item.fileName}
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                />
                              </svg>
                            </div>
                          </NewContextMenu>
                        ))
                      ) : (
                        <p className="block w-full text-neutral-900 text-sm">
                          There are no files added for {monthItem.monthName}
                          {/* {months[new Date().getMonth()]}{" "} */}
                          {/* {new Date().getFullYear()}. */}
                        </p>
                      )}

                      {CompanyData?.userRole !== "MEMBER" && (
                        <div
                          onClick={() =>
                            handleAddFileClick(monthItem.monthName)
                          }
                          className="cursor-pointer flex items-center justify-center p-4 w-[190px] h-[49px] bg-white hover:bg-primary-100 hover:border-none border-dashed border-neutral-700 border mr-5"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 text-tertiary4-500"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6v12m6-6H6"
                            />
                          </svg>
                          <span className="text-sm text-tertiary4-500">
                            Add File
                          </span>
                        </div>
                      )}
                      {/* <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    /> */}
                    </div>
                  </div>
                </div>
              );
              return (
                <AdvanceAccordian
                  className="invoice"
                  title={monthItem.monthName}
                  content={content}
                  key={key}
                  index={key}
                  activeIndex={activeIndex}
                  onClick={setActiveIndex}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className="stickyNotification mt-8 fixed bottom-4 cursor-default flex items-center justify-between p-2.5 flex gap-4 border border-neutral-300 bg-white shadow-[0_4px_4px_rgba(23,26,31,0.1)] mx-[30px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>

        <p className="text-neutral-900">
          Generally, teams update their invoices and documents regularly to not
          miss the correctness of the financial report. The report of each month
          is generated on the 5th of next month.
        </p>
      </div>
      <AddNewFolderModal
        fYear={financialYear}
        monthName={monthName}
        open={showAddFolder}
        fetchAgain={fetchAgain}
        closeModal={setshowAddFolder}
        setFetchAgain={setFetchAgain}
      />
      {/* {showOpenFolder && (
        <OpenFolderModal
          open={showOpenFolder}
          closeModal={setShowOpenFolder}
          data={monthWiseData}
          initialFolderDetails={initialFolderDetails}
        />
      )} */}
      <RenameFolderModal
        fYear={financialYear}
        monthName={monthName}
        folderClickedId={folderClickedId}
        open={showRenameFolder}
        fetchAgain={fetchAgain}
        oldFolderName={oldFolderName}
        closeModal={setShowRenameFolder}
        setFetchAgain={setFetchAgain}
      />
      <RenameFileModal
        fYear={financialYear}
        monthName={monthName}
        fileClickedId={fileClickedId}
        open={showRenameFile}
        fetchAgain={fetchAgain}
        oldFileName={oldFileName}
        closeModal={setShowRenameFile}
        setFetchAgain={setFetchAgain}
      />
      <UploadFileModal
        fYear={financialYear}
        monthName={monthName}
        open={showUploadModal}
        fetchAgain={fetchAgain}
        closeModal={setShowUploadModal}
        setFetchAgain={setFetchAgain}
      />
      <DeleteFolderConfirmationModal
        open={showDeleteFolderConfirmation}
        closeModal={setShowDeleteFolderConfirmation}
        fYear={financialYear}
        monthName={monthName}
        folderClickedId={folderClickedId}
        name={deleteName}
        fetchAgain={fetchAgain}
        setFetchAgain={setFetchAgain}
      />
      <DeleteFileConfirmationModal
        open={showDeleteFileConfirmation}
        closeModal={setShowDeleteFileConfirmation}
        fYear={financialYear}
        monthName={monthName}
        fileClickedId={fileClickedId}
        name={deleteName}
        fetchAgain={fetchAgain}
        setFetchAgain={setFetchAgain}
      />
      <InitialModal open={showInitialModal} closeModal={setShowInitialModal} />

      <ToastContainer />
    </div>
  );
}
