import { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import Button from "src/components/Button";
import ChangeLogoModal from "src/components/SettingsModal/ChangeLogoModal";
import DeleteCompanyModal from "src/components/SettingsModal/DeleteCompanyModal";
import EditCompanyModal from "src/components/SettingsModal/EditCompanyModal";
import LeaveCompanyModal from "src/components/SettingsModal/LeaveCompanyModal";
import { useAuth } from "src/context/AuthProvider";
import { useCompanyData } from "src/context/CompanyDataProvider";

export default function Settings() {
  const [showLogoChangeModal, setShowLogoChangeModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { state: CompanyData, reloadCompnayData } = useCompanyData();
  const { state: UserData } = useAuth();

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const showLogoModal = () => {
    setShowLogoChangeModal(true);
  };

  const getCurrentTitle = () => {
    if (location.pathname.includes("/financial")) {
      return "Financials";
    }

    if (location.pathname.includes("/invoice")) {
      return "Invoices and Documents";
    }

    switch (location.pathname) {
      case "/":
        return "Bank Accounts";
      case "/invoice":
        return "Invoices and Documents";
      case "/settings":
        return "Settings";
      case "/co-workers":
        return "Co-workers";
      case "/account":
        return "Your Account";
      case "/gethelp":
        return "Get Help";
      case "/invite":
        return "Invite";
      default:
        return "";
    }
  };

  return (
    <div className="settingsPage">
      <div className="flex items-center gap-5 z-10 pl-[30px] border-b border-neutral-400">
        <span className="text-xl text-neutral-900 font-medium inline-block py-5">
          {getCurrentTitle()}
        </span>
      </div>
      <div className="flex flex-1 justify-between border-b border-neutral-400 pb-6 ml-[30px] mr-5 mt-6">
        <div className="flex  md:ml-0 items-center text-neutral-900 font-semibold">
          <div className="flex gap-4">
            {CompanyData?.logoUrl ? (
              <div
                className="cursor-pointer relative flex flex-col items-center justify-center w-[64px] h-[64px] rounded"
                onMouseEnter={onHover}
                onMouseLeave={onLeave}
              >
                <img
                  className="w-auto h-full"
                  src={CompanyData?.logoUrl}
                  alt={CompanyData?.logoUrl}
                />
                {hover && (
                  <div
                    onClick={showLogoModal}
                    className="bg-neutral-900 h-[32px] w-auto opacity-40 text-center align-middle absolute bottom-0 text-sm underline hover:no-underline text-white"
                  >
                    Change
                  </div>
                )}
              </div>
            ) : (
              <div
                onMouseEnter={onHover}
                onMouseLeave={onLeave}
                className="cursor-pointer relative flex flex-col items-center justify-center w-[64px] h-[64px] bg-[#FF7D32FF] rounded"
              >
                <span className="text-3xl text-white">
                  {CompanyData?.name?.slice(0, 1)}
                </span>
                {hover && (
                  <div
                    onClick={showLogoModal}
                    className="bg-neutral-900 h-[32px] w-full opacity-40 text-center align-middle absolute bottom-0 text-sm underline hover:no-underline text-white"
                  >
                    Change
                  </div>
                )}
              </div>
            )}
            <div className="flex flex-col justify-center">
              <div className="flex text-neutral-900 font-normal text-xl">
                <span>{CompanyData?.name}</span>
                {CompanyData?.userRole !== "MEMBER" && (
                  // <img
                  //   onClick={() => setShowEditModal(!showEditModal)}
                  //   src="/icons/Settings/Pencil.png"
                  //   alt="edit"
                  //   className="cursor-pointer ml-2 h-[24px] w-[24px] hover:text-gray-500"
                  // />
                  <div
                    className="cursor-pointer ml-2 hover:text-gray-500"
                    onClick={() => setShowEditModal(!showEditModal)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      />
                    </svg>
                  </div>
                )}
              </div>
              {CompanyData?.userRole !== "MEMBER" && (
                <span
                  onClick={() => setShowEditModal(!showEditModal)}
                  className="cursor-pointer underline hover:no-underline text-tertiary4-500 font-normal text-base pt-1"
                >
                  {CompanyData?.website || "Add website"}
                </span>
              )}
            </div>
          </div>
        </div>
        {CompanyData?.userRole !== "MEMBER" && (
          // <button
          //   onClick={() => navigate("/co-workers")}
          //   type="button"
          //   className="h-[45px] lg:h-[36px] inline-flex items-center rounded-md border border-transparent bg-tertiary4-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-550 focus:outline-none"
          // >
          //   Manage Co-workers
          // </button>
          <Button
            onClick={() => navigate("/co-workers")}
            type="button"
            className="h-[45px] lg:h-[36px] inline-flex items-center justify-center rounded-md text-base font-medium text-white focus:outline-none sm:w-auto"
          >
            Manage Co-workers
          </Button>
        )}
      </div>
      {CompanyData?.userRole === "OWNER" ? (
        <div className="cursor-pointer text-tertiary4-500 underline hover:no-underline py-4 ml-[30px] mr-5">
          <span onClick={() => setShowDeleteModal(!showDeleteModal)}>
            {/* <span onClick={() => setShowLeaveModal(!showLeaveModal)}> */}{" "}
            Delete this Company from Cashwise?
          </span>
        </div>
      ) : (
        <div className="cursor-pointer text-tertiary4-500 underline hover:no-underline py-4 ml-[30px] mr-5">
          <span onClick={() => setShowLeaveModal(!showLeaveModal)}>
            Leave Company from Cashwise?
          </span>
        </div>
      )}
      <ChangeLogoModal
        logoPresent={CompanyData?.logoUrl}
        open={showLogoChangeModal}
        closeModal={setShowLogoChangeModal}
      />
      <DeleteCompanyModal
        open={showDeleteModal}
        closeModal={setShowDeleteModal}
        companyData={CompanyData}
      />
      <LeaveCompanyModal
        open={showLeaveModal}
        closeModal={setShowLeaveModal}
        name={UserData?.customer?.lastName}
        companyData={CompanyData}
      />
      <EditCompanyModal open={showEditModal} closeModal={setShowEditModal} />
    </div>
  );
}
