import Modal from "../../components/Modal/index";
import React, { useContext, useEffect, useRef, useState } from "react";
import IndianBankAccountModal from "../../components/BankAccountModal/IndianBankAccountModal";
import USBankAccountModal from "../../components/BankAccountModal/USBankAccountModal";
import DeleteBankAccountModal from "../../components/BankAccountModal/DeleteBankAccountModal";
import InitialModal from "src/components/BankAccountModal/InitialModal";
import { useLocation } from "react-router";
import { useAuth } from "src/context/AuthProvider";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";
import { useCompanyData } from "src/context/CompanyDataProvider";

const bankAccountDetails = [
  {
    balance: "1,88,75,433",
    bankName: "SBI",
    bankAccountNumber: "1234567890",
    bankIFSC: "SBIN00000890",
    branchAddress: "Branch: 12G,MG Road, Bangalore",
  },
  {
    balance: "2,98,75,433",
    bankName: "HDFC",
    bankAccountNumber: "7821923290",
    bankIFSC: "HDFC11113333",
    branchAddress: "Branch: 12G,MG Road, Bangalore",
  },
  {
    balance: "2,98,75,433",
    bankName: "HDFC",
    bankAccountNumber: "7821923290",
    bankIFSC: "HDFC11113333",
    branchAddress: "Branch: 12G,MG Road, Bangalore",
  },
];

export default function BankAccount() {
  const bankAccountAdded = true;
  const [showInitialModal, setShowInitialModal] = useState(false);
  const [showIndianModal, setShowIndianModal] = useState(false);
  const [showUSModal, setShowUSModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const isInitialRender = useRef(true);
  const { state } = useLocation();

  const { idToken } = useAuth();

  console.log(idToken);

  const { state: CompanyData, reloadCompnayData } = useCompanyData();

  useEffect(() => {
    let pop_status = localStorage.getItem("pop_status");
    if (!pop_status) {
      setShowInitialModal(true);
      localStorage.setItem("pop_status", "1");
    }
  }, []);

  return (
    <div className="bankAccount">
      {CompanyData.bankAccounts.length > 0 ? (
        <div className="flex gap-x-6 textBank text-lg text-neutral-900">
          <div className="text-sm">
            Total Balance:{" "}
            <span className="text-lg font-semibold">₹1,98,75,433</span>{" "}
          </div>
          <button className="flex items-center text-sm bg-neutral-500 text-white rounded px-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
                clipRule="evenodd"
              />
            </svg>
            <span>Refresh Balance</span>
          </button>
          <span className="text-sm mt-auto">
            Last Refresh on 10th Oct 2022 at 10:22pm
          </span>
        </div>
      ) : (
        <div className="textBank text-lg text-secondary-500">
          <p>Add your bank accounts! </p>
          <p>Get a consolidated view of all your bank accounts at one place.</p>
        </div>
      )}

      {CompanyData.bankAccounts.length > 0 && (
        <div className="flex flex-col sm:flex-row gap-14 my-8">
          {CompanyData.bankAccounts.map((item, index) => {
            return (
              <div
                key={index}
                className="flex flex-col text-sm rounded-lg border-neutral-500 border w-[333px] h-[254px]"
              >
                <p className="text-center py-3.5">Balance: {item.balance}</p>

                <div className="flex flex-col h-full justify-around rounded-lg border-t border-neutral-500 px-4">
                  <div>
                    <p className="mb-2">{item.bankName}</p>
                    <p className="mt-2">A/c No: {item.bankAccountNumber}</p>
                    <p className="mb-2">IFSC: {item.bankIFSC}</p>
                    <p className="mt-2">{item.branchAddress}</p>
                  </div>

                  <div
                    onClick={() => setShowDeleteModal(true)}
                    className="flex justify-end cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="addBankAccount flex gap-14 mt-12">
        <div
          onClick={() => setShowIndianModal(true)}
          className="cursor-pointer flex flex-col items-center justify-center  h-[301px] w-[333px] bg-primary-500 rounded-lg"
        >
          <div className="cursor-pointer">
            <p className="text-center">+</p>
            <p className="text-neutral-900">Add Indian Bank Accounts</p>
          </div>
        </div>
        <div
          onClick={() => setShowUSModal(true)}
          className="cursor-pointer flex flex-col items-center justify-center  h-[301px] w-[333px] bg-primary-500 rounded-lg"
        >
          <div className="cursor-pointer">
            <p className="text-center">+</p>
            <p className="text-neutral-900">Add US Bank Accounts</p>
          </div>
        </div>
      </div>
      <IndianBankAccountModal
        open={showIndianModal}
        closeModal={setShowIndianModal}
      />
      <USBankAccountModal open={showUSModal} closeModal={setShowUSModal} />
      <DeleteBankAccountModal
        open={showDeleteModal}
        closeModal={setShowDeleteModal}
      />
      <InitialModal open={showInitialModal} closeModal={setShowInitialModal} />
    </div>
  );
}

{
  /* <div className="bankAccount">
{bankAccountAdded ? (
  <div className="flex gap-x-6 textBank text-lg text-neutral-900">
    <div className="text-sm">
      Total Balance:{" "}
      <span className="text-lg font-semibold">₹1,98,75,433</span>{" "}
    </div>
    <button className="flex items-center text-sm bg-neutral-500 text-white rounded px-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-5 h-5"
      >
        <path
          fillRule="evenodd"
          d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
          clipRule="evenodd"
        />
      </svg>
      <span>Refresh Balance</span>
    </button>
    <span className="text-sm mt-auto">
      Last Refresh on 10th Oct 2022 at 10:22pm
    </span>
  </div>
) : (
  <div className="textBank text-lg text-neutral-900">
    <p>Add your bank accounts! </p>
    <p>Get a consolidated view of all your bank accounts at one place.</p>
  </div>
)}

{bankAccountAdded && (
  <div className="flex flex-col sm:flex-row gap-14 my-8">
    {bankAccountDetails.map((item, index) => {
      return (
        <div
          key={index}
          className="flex flex-col text-sm rounded-lg border-neutral-500 border w-[333px] h-[254px]"
        >
          <p className="text-center py-3.5">Balance: {item.balance}</p>

          <div className="flex flex-col h-full justify-around rounded-lg border-t border-neutral-500 px-4">
            <div>
              <p className="mb-2">{item.bankName}</p>
              <p className="mt-2">A/c No: {item.bankAccountNumber}</p>
              <p className="mb-2">IFSC: {item.bankIFSC}</p>
              <p className="mt-2">{item.branchAddress}</p>
            </div>

            <div
              onClick={() => setShowDeleteModal(true)}
              className="flex justify-end cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </div>
          </div>
        </div>
      );
    })}
  </div>
)}

<div className="addBankAccount flex gap-14 mt-12">
  <div
    onClick={() => setShowIndianModal(true)}
    className="cursor-pointer flex flex-col items-center justify-center  h-[301px] w-[333px] bg-neutral-300 rounded-lg"
  >
    <div className="cursor-pointer">
      <p className="text-center">+</p>
      <p>Add Indian Bank Accounts</p>
    </div>
  </div>
  <div
    onClick={() => setShowUSModal(true)}
    className="cursor-pointer flex flex-col items-center justify-center  h-[301px] w-[333px] bg-neutral-300 rounded-lg"
  >
    <div className="cursor-pointer">
      <p className="text-center">+</p>
      <p>Add US Bank Accounts</p>
    </div>
  </div>
</div>
<IndianBankAccountModal
  open={showIndianModal}
  closeModal={setShowIndianModal}
/>
<USBankAccountModal open={showUSModal} closeModal={setShowUSModal} />
<DeleteBankAccountModal
  open={showDeleteModal}
  closeModal={setShowDeleteModal}
/>
<InitialModal open={showInitialModal} closeModal={setShowInitialModal} />
</div> */
}
