import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// const instance = axios.create({
//   baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
// });

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const resInterceptor = (response) => {
      console.log("RESPONSE INTERCEPTOR", response);
      return response;
    };

    const errInterceptor = (error) => {
      if (error.response.data?.message === "Company not found") {
        navigate("/");
      } else if (error.response.data?.message === "Signup incomplete") {
        navigate("/profile-details");
      } else if (error.response.data?.message === "Unauthorized") {
        navigate("/login");
      } else {
        console.log("error interceptor", error);
      }

      return Promise.reject(error);
    };

    const reqInterceptor = (request) => {
      console.log("REQUEST INTERCEPTOR", request);
      return request;
    };

    const errReqInterceptor = (error) => {
      if (error.request.data?.message === "Company not found") {
        navigate("/");
      } else if (error.request.data?.message === "Signup incomplete") {
        navigate("/profile-details");
      } else if (error.request.data?.message === "Unauthorized") {
        navigate("/login");
      } else {
        console.log("error request interceptor", error);
      }

      return Promise.reject(error);
    };

    const interceptor = axios.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    // const requestInterceptor = axios.interceptors.request.use(
    //   reqInterceptor,
    //   errReqInterceptor
    // );

    return () => axios.interceptors.response.eject(interceptor);
  }, [navigate]);

  return children;
};

// export default instance;
export { AxiosInterceptor };
