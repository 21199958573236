import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import "./index.scss";

const tileDocs = [
  {
    category: "Start Up",
    docName: "Co-founders' Agreement",
    description:
      "A founders agreement is a legal contract that all co-founders agree to — ideally set by the company prior to launching.",
    url: "cofounder",
  },
  {
    category: "Business Operations",
    docName: "Non-Disclosure Agreement",
    description:
      "A non-disclosure agreement (NDA) is a legally binding contract that establishes a confidential relationship.",
    url: "nda",
  },
  {
    category: "Business Operations",
    docName: "Consulting Agreement",
    description:
      "A Consulting Agreement is a contract between a consultant and client that outlines the terms of their arrangement.",
    url: "consulting",
  },

  {
    category: "Business Operations",
    docName: "Services Agreement",
    description:
      "A services agreement is a written contract between a service provider and a client.",
    url: "serviceAgreement",
  },

  {
    category: "Human Resources",
    docName: "Employment Agreement",
    description:
      "Employment contract is a formal, signed agreement between the new employee and the employer.",
    url: "employment",
  },
  {
    category: "Human Resources",
    docName: "Offer Letter",
    description:
      "An offer letter is a letter to a potential employee that provides key terms of the prospective employment.",
    url: "offerletter",
  },
  {
    category: "Product",
    docName: "Privacy Policy",
    description:
      "A privacy policy is a legal document that outlines how a website/app collects, uses, stores, & shares users' information.",
    url: "privacyPolicy",
  },
  {
    category: "Product",
    docName: "Terms and Conditions",
    description:
      "Terms and Conditions are the legal agreements between a service provider and a party that wants to use it.",
    url: "termsAndCondition",
  },
];

export default function DocumentGeneration() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchDivRef = useRef();

  const getCurrentTitle = () => {
    if (location.pathname.includes("/financial")) {
      return "Financials";
    }

    if (location.pathname.includes("/invoice")) {
      return "Invoices and Documents";
    }

    switch (location.pathname) {
      case "/":
        return "Bank Accounts";
      case "/invoice":
        return "Invoices and Documents";
      case "/settings":
        return "Settings";
      case "/co-workers":
        return "Co-workers";
      case "/account":
        return "Your Account";
      case "/gethelp":
        return "Get Help";
      case "/invite":
        return "Invite";
      case "/formationDiligence":
        return "Formation and Diligence Documents";
      case "/documentGeneration":
        return "Document Generation";
      default:
        return "";
    }
  };

  return (
    <div className="documentGeneration">
      <div className="flex items-center justify-between border-b border-neutral-400 gap-4 pl-[30px] pr-5">
        <div className="flex items-center gap-6">
          <span className="text-xl text-neutral-900 font-semibold inline-block py-5">
            {getCurrentTitle()}
          </span>
        </div>
        <div
          onClick={() => navigate("requestCustom")}
          // onClick={() => setShowShareModal(!showShareModal)}
          className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
            />
          </svg>

          <span>Request Custom</span>
        </div>
      </div>
      <div className="ml-[30px] mr-5 mt-6">
        <p className="text-neutral-900">
          Generate important documents with pre-built templates in 30 seconds!
        </p>
        <p className="text-neutral-900 my-4 font-semibold">How does it work?</p>
        <div className="mt-6 border border-neutral-400 rounded-[3px]">
          <ul className="documentSteps flex justify-between py-6 px-8 ">
            <li className="flex items-center gap-2">
              <img height="30px" src="/icons/docGenerate/info.png" />
              <div>
                <p className="text-neutral-900 font-semibold">
                  Select Document
                </p>
                <p className="text-neutral-900 text-sm">
                  Choose any document from the given list
                </p>
              </div>
            </li>
            <li className="flex items-center gap-2">
              <img height="30px" src="/icons/docGenerate/details.png" />
              <div>
                <p className="text-neutral-900 font-semibold">Enter Details</p>
                <p className="text-neutral-900 text-sm">
                  The document is automatically edited according to your
                  responses
                </p>
              </div>
            </li>
            <li className="flex items-center gap-2">
              <img height="30px" src="/icons/docGenerate/downloads.png" />
              <div>
                <p className="text-neutral-900 font-semibold">
                  Share Link or Download file
                </p>
                <p className="text-neutral-900 text-sm">
                  The required document would be created and be available to
                  share
                </p>
              </div>
            </li>
          </ul>
        </div>
        {/* <div className="flex flex-wrap mt-8  justify-start">
          {tileDocs.map((item, index) => {
            return (
              <div className="box-border flex flex-col justify-center items-center p-4 border border-secondary-200 rounded-lg xl:basis-3/12">
                <img
                  height="78px"
                  width="78px"
                  src="/icons/docGenerate/fileAdd.png"
                  alt="fileAdd"
                  className="my-5"
                />
                <div className="flex flex-col items-center">
                  <p className="text-neutral-500 text-sm">{item.category}</p>
                  <p className="text-neutral-900 font-semibold mt-1 mb-2">
                    {item.docName}
                  </p>
                  <p className="text-left text-xs text-neutral-900">
                    {item.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div> */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-4 mt-8 cursor-pointer">
          {tileDocs?.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() =>
                  navigate(`/documentGeneration/${item.url}`, {
                    state: {
                      documentName: item.docName,
                    },
                  })
                }
                className="docTile box-border flex flex-col justify-center items-center p-4 border border-secondary-200 rounded-lg xl:basis-3/12"
              >
                <img
                  height="78px"
                  width="78px"
                  src="/icons/docGenerate/fileAdd.png"
                  alt="fileAdd"
                  className="my-5"
                />
                <div className="flex flex-col items-center">
                  <p className="text-neutral-500 text-sm text-center">
                    {item.category}
                  </p>
                  <p className="text-neutral-900 text-center font-semibold mt-1 mb-2">
                    {item.docName}
                  </p>
                  <p className="text-center text-xs text-neutral-900">
                    {item.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
