import { createContext, useContext, useReducer } from "react";

const companyDataContext = createContext(null);

export enum CompanyActions {
  RELOAD = "COMPANY_DATA_RELOAD",
  RESET = "CART_RESET",
}

const initialState = {
  bankAccounts: [],
  // brandName: "",
  creatorFirstName: "",
  creatorlastName: "",
  description: "",
  financials: {},
  id: "",
  logoUrl: "",
  name: "",
  numberOfEmployees: "",
  userInviteStatus: "",
  userRole: "",
  website: "",
};

function init(initialState) {
  return initialState;
}

function reducer(state, action) {
  switch (action.type) {
    case CompanyActions.RELOAD:
      return {
        ...state,
        ...action.payload,
      };
    case CompanyActions.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export const CompanyDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState, init);

  const reloadCompnayData = (data) => {
    console.log("reloadCompnayData", data);
    dispatch({
      type: CompanyActions.RELOAD,
      payload: data,
    });
  };

  const values = {
    state,
    reloadCompnayData,
  };

  return (
    <companyDataContext.Provider value={values}>
      {children}
    </companyDataContext.Provider>
  );
};

export const useCompanyData = () => useContext(companyDataContext);
