import { Transition } from "@headlessui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import "./index.scss";

export default function AdvanceAccordian({
  title,
  content,
  index,
  activeIndex = null,
  onClick,
  className,
}) {
  const navigate = useNavigate();

  const handleMonthDataClick = (e, monthName) => {
    const targetElement = document.getElementsByClassName(
      "invoice_heading_active"
    )[0];
    console.log(targetElement);
    console.log(e.target);
    console.log("monthName", monthName);
    // To only navigate when accordian is opened and than title clicked
    if (targetElement && targetElement.contains(e.target)) {
      console.log("Ayush");
      navigate(`${monthName}`);
    }

    // navigate(`${monthName}`);
  };

  return (
    <div className={`accordian_item ${className}`}>
      <div
        onClick={() => {
          // If user click again on the active accordian than close it
          // When user click on same accordian, remove class
          if (activeIndex === index) {
            if (
              document.getElementsByClassName(`${className}_heading_active`)
                .length === 0
            ) {
              document
                .getElementsByClassName(`${className}_heading`)
                [activeIndex].classList.add(`${className}_heading_active`);
              document
                .getElementsByClassName(`${className}_content`)
                [activeIndex].classList.add(`${className}_content_active`);
            } else {
              document
                .getElementsByClassName(`${className}_heading`)
                [activeIndex].classList.remove(`${className}_heading_active`);
              document
                .getElementsByClassName(`${className}_content`)
                [activeIndex].classList.remove(`${className}_content_active`);
            }
          }
          onClick(index);
        }}
        className={`${className}_heading ${
          index === activeIndex ? `${className}_heading_active` : ``
        } `}
      >
        <div
          onClick={(e) => handleMonthDataClick(e, title)}
          className={"title"}
        >
          {title}
        </div>
      </div>

      <div
        className={`${className}_content ${
          index === activeIndex ? `${className}_content_active` : ""
        }`}
        // dangerouslySetInnerHTML={{ __html: content }}
      >
        {content}
      </div>
    </div>
  );
}
