import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useAuth } from "src/context/AuthProvider";
import { useCompanyData } from "src/context/CompanyDataProvider";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function EditCompanyModal({ open, closeModal }) {
  const [loading, setLoading] = useState(false);
  const { state: CompanyData, reloadCompnayData } = useCompanyData();
  const { idToken } = useAuth();
  const methods = useForm();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      let compData = null;
      const teamEdit = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/company/${CompanyData?.id}`,
        {
          companyName: data.companyName,
          // brandName: data.brandName,
          description: data.description,
          numberOfEmployees: data.numberOfEmployees,
          website: data.website,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      const teamEditRes = await teamEdit.data;
      if (teamEditRes) {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/company/${CompanyData?.id}`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        console.log(res);
        compData = await res.data;
        setLoading(false);
      }

      console.log(compData);
      reloadCompnayData(compData);
      toast.success("Company Details Updated!!", {
        className: "toast-position",
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      closeModal(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong!! Please try again",
        {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      closeModal(false);
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[12]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => closeModal(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start pb-4 border-b border-neutral-400">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="font-medium leading-6 text-gray-900"
                      >
                        Edit Company Info
                      </Dialog.Title>
                    </div>
                  </div>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-4 pt-4">
                      <div>
                        <label
                          htmlFor="CompanyName"
                          className="block text-sm font-medium text-neutral-900"
                        >
                          Company Name
                        </label>
                        <input
                          {...register("companyName", {
                            required: {
                              value: true,
                              message: "Company name is required",
                            },
                            minLength: {
                              value: 3,
                              message:
                                "Company name should be between 3 to 50 characters",
                            },
                            maxLength: {
                              value: 50,
                              message:
                                "Company name should be between 3 to 50 characters",
                            },
                          })}
                          defaultValue={CompanyData?.name || ""}
                          id="companyName"
                          name="companyName"
                          type="companyName"
                          className="relative block w-[450px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Company Name"
                        />
                        <ErrorTest errors={errors} field="companyName" />
                      </div>

                      {/* <div>
                        <label
                          htmlFor="BrandName"
                          className="block text-sm font-medium text-neutral-900"
                        >
                          Brand Name
                        </label>
                        <input
                          {...register("brandName", {
                            required: {
                              value: true,
                              message: "Brand name is required",
                            },
                            minLength: {
                              value: 3,
                              message:
                                "Brand name should be between 3 to 50 characters",
                            },
                            maxLength: {
                              value: 50,
                              message:
                                "Brand name should be between 3 to 50 characters",
                            },
                          })}
                          defaultValue={CompanyData?.brandName || ""}
                          id="brandName"
                          name="brandName"
                          type="brandName"
                          className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                        invalid:border-pink-500 invalid:text-pink-600
                        focus:invalid:border-pink-500 focus:invalid:ring-pink-500 relative block w-[450px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Brand Name"
                        />
                        <ErrorTest errors={errors} field="brandName" />
                      </div> */}

                      <div>
                        <label
                          htmlFor="numberOfEmployees"
                          className="block text-sm font-medium text-neutral-900"
                        >
                          Number of Employees
                        </label>
                        <select
                          {...register("numberOfEmployees", {
                            required: {
                              value: true,
                              message: "Please select number of employees",
                            },
                          })}
                          defaultValue={CompanyData?.numberOfEmployees || ""}
                          id="numberOfEmployees"
                          name="numberOfEmployees"
                          className="relative block w-[450px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          <option value="" disabled hidden>
                            Number of Employees
                          </option>
                          <option value="OneToTen">1-10</option>
                          <option value="ElevenToFifty">11-50</option>
                          <option value="FiftyOneToHundred">50-100</option>
                          <option value="AboveHundred">more than 100</option>
                        </select>
                        <ErrorTest errors={errors} field="numberOfEmployees" />
                      </div>
                      <div>
                        <label
                          htmlFor="website"
                          className="block text-sm font-medium text-neutral-900"
                        >
                          Website (optional)
                        </label>
                        <input
                          {...register("website", {
                            maxLength: {
                              value: 50,
                              message:
                                "Website should be between 1 to 50 characters",
                            },
                          })}
                          defaultValue={CompanyData?.website || ""}
                          id="website"
                          name="website"
                          type="website"
                          className="relative block w-[450px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Company Name"
                        />
                        <ErrorTest errors={errors} field="website" />
                      </div>
                      <div>
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-neutral-900"
                        >
                          Description (optional)
                        </label>
                        <input
                          {...register("description", {
                            maxLength: {
                              value: 200,
                              message:
                                "Description should be between 1 to 200 characters",
                            },
                          })}
                          defaultValue={CompanyData?.description || ""}
                          id="description"
                          name="description"
                          type="description"
                          className="relative block w-[450px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Company Name"
                        />
                        <ErrorTest errors={errors} field="description" />
                      </div>
                      <div className="mt-5 sm:mt-4  gap-4 ">
                        <button
                          disabled={loading}
                          type="submit"
                          className="inline-flex w-full justify-center rounded border border-transparent bg-tertiary4-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-550 focus:outline-none sm:w-auto"
                        >
                          {loading ? (
                            <Oval
                              height={20}
                              width={20}
                              color="#2B85EDFF"
                              wrapperStyle={{}}
                              // wrapperClass="hor-ver-center"
                              visible={true}
                              ariaLabel="oval-loading"
                              secondaryColor="#ffffff"
                              strokeWidth={3}
                              strokeWidthSecondary={2}
                            />
                          ) : (
                            <span>Save</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
}
