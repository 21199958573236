import React, { useEffect, useRef, useState } from "react";

export default function NewCustomContextMenu({
  items,
  targetId,
  handleClick,
  data = null,
}) {
  const [showMenuData, setshowMenuData] = useState({
    visible: false,
    xPos: 0,
    yPos: 0,
  });
  const menuRef = useRef(null);

  useEffect(() => {
    const handleLeftClick = (e) => {
      const targetElement = document.getElementById(targetId);
      if (targetElement && targetElement.contains(e.target)) {
        console.log(targetElement);
        targetElement.style.backgroundColor = "#F1F7FE";
        // targetElement.getElementsByTagName("span")[0].style.color = "#4895efff";
      } else {
        targetElement.style.backgroundColor = "#ffffff";
      }
      setshowMenuData({ ...showMenuData, visible: false });
    };

    const handleRightClick = (e) => {
      const targetElement = document.getElementById(targetId);
      if (targetElement && targetElement.contains(e.target)) {
        targetElement.style.backgroundColor = "#F1F7FE";
        e.preventDefault();
        setshowMenuData({
          visible: true,
          xPos: e.clientX,
          yPos: e.clientY,
        });
      } else if (menuRef.current && !menuRef.current.contains(e.target)) {
        targetElement.style.backgroundColor = "#ffffff";
        setshowMenuData({ ...showMenuData, visible: false });
      } else {
        targetElement.style.backgroundColor = "#ffffff";
      }
    };

    document.addEventListener("contextmenu", handleRightClick);
    document.addEventListener("click", handleLeftClick);

    return () => {
      document.removeEventListener("contextmenu", handleRightClick);
      document.removeEventListener("click", handleLeftClick);
    };
  }, [showMenuData, targetId]);

  const style = {
    top: showMenuData.yPos,
    left: showMenuData.xPos,
  };

  return (
    showMenuData.visible &&
    items.length > 0 && (
      <div
        ref={menuRef}
        className={`fixed w-[150px] py-2 shadow-md rounded-md bg-white menuList`}
        style={style}
      >
        {items.map((item, index) => (
          <div
            key={index}
            onClick={() =>
              handleClick(item.name, targetId, data.monthName, data.folderName)
            }
            className="flex gap-2 items-center cursor-pointer px-2 py-2 text-neutral-900 text-sm hover:bg-primary-550 hover:text-white"
          >
            <item.icon className="flex-shrink-0 h-6 w-6" aria-hidden="true" />
            {item.name}
          </div>
        ))}
      </div>
    )
  );
}
