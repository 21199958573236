import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useAuth } from "src/context/AuthProvider";
import { useCompanyData } from "src/context/CompanyDataProvider";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";
import Button from "src/components/Button";
import { toast, ToastContainer } from "react-toastify";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function FinancialShareModal({ open, closeModal }) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([1]);
  const [hover, setHover] = useState(false);
  const { state: CompanyData } = useCompanyData();
  const { idToken } = useAuth();
  const { sendInvite } = useAuthOnBoardFlow();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onInviteSubmit = (data) => {
    setLoading(true);
    const inviteObj = {};
    items.forEach((item) => {
      inviteObj[data[`email${item}`]] = data[`role${item}`];
    });
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");
    sendInvite(idToken, companyId, inviteObj)
      .then((res) => {
        console.log(res);
        reset();
        setLoading(false);
        toast.success(
          "The email has been sent! Please also check the spam folder.",
          {
            className: "toast-position",
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        closeModal(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(
          err?.response?.data?.message ||
            "Something went wrong!! Please try again later",
          {
            className: "toast-position",
            position: "bottom-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        reset();
        closeModal(false);
      });
  };

  const handleAddMore = () => {
    const arrL = items.length + 1;
    setItems([...items, arrL]);
  };

  const handleDeleteRow = (item) => {
    items.length > 1 && items.pop();
    const newItems = items;
    console.log(newItems);
    setItems([...newItems]);
  };

  // const onHover = () => {
  //   setHover(true);
  // };

  // const onLeave = () => {
  //   setHover(false);
  // };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[12]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[630px] sm:p-6">
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => closeModal(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start pb-4">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-normal leading-6 text-gray-900"
                      >
                        Invite co-workers to {CompanyData?.name}
                      </Dialog.Title>
                    </div>
                  </div>

                  <form
                    className="relative pb-4"
                    onSubmit={handleSubmit(onInviteSubmit)}
                  >
                    <div>
                      {items.map((item, index) => (
                        <>
                          <div
                            key={index}
                            className="flex flex-start items-center gap-4 mt-4"
                          >
                            <div>
                              <input
                                id={`email${item}`}
                                name={`email${item}`}
                                type="email"
                                autoComplete="current-password"
                                className="relative block w-[340px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="Email"
                                {...register(`email${item}`, {
                                  required: {
                                    value: true,
                                    message: "Email is required",
                                  },
                                })}
                              />
                              <ErrorTest
                                errors={errors}
                                field={`email${item}`}
                              />
                            </div>
                            {CompanyData?.userRole === "OWNER" && (
                              <div>
                                <select
                                  {...register(`role${item}`, {
                                    required: {
                                      value: true,
                                      message: "Please select a role",
                                    },
                                  })}
                                  id={`role${item}`}
                                  name={`role${item}`}
                                  className="relative block w-[200px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                >
                                  <option value="" disabled selected hidden>
                                    Assign Role
                                  </option>
                                  <option value="ADMIN">Admin</option>
                                  <option value="MANAGER">Manager</option>
                                  <option value="MEMBER">Member</option>
                                </select>
                                <ErrorTest
                                  errors={errors}
                                  field={`role${item}`}
                                />
                              </div>
                            )}

                            {CompanyData?.userRole === "ADMIN" && (
                              <div>
                                <select
                                  {...register(`role${item}`, {
                                    required: {
                                      value: true,
                                      message: "Please select a role",
                                    },
                                  })}
                                  id={`role${item}`}
                                  name={`role${item}`}
                                  className="relative block w-[200px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                >
                                  <option value="" disabled selected hidden>
                                    Assign Role
                                  </option>
                                  <option value="MANAGER">Manager</option>
                                  <option value="MEMBER">Member</option>
                                </select>
                                <ErrorTest
                                  errors={errors}
                                  field={`role${item}`}
                                />
                              </div>
                            )}

                            {CompanyData?.userRole === "MANAGER" && (
                              <div>
                                <select
                                  {...register(`role${item}`, {
                                    required: {
                                      value: true,
                                      message: "Please select a role",
                                    },
                                  })}
                                  id={`role${item}`}
                                  name={`role${item}`}
                                  className="relative block w-[200px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                >
                                  <option value="" disabled selected hidden>
                                    Assign Role
                                  </option>
                                  <option value="MEMBER">Member</option>
                                </select>
                                <ErrorTest
                                  errors={errors}
                                  field={`role${item}`}
                                />
                              </div>
                            )}

                            {CompanyData?.userRole === "MEMBER" && (
                              <div>
                                <select
                                  {...register(`role${item}`, {
                                    required: {
                                      value: true,
                                      message: "Please select a role",
                                    },
                                  })}
                                  disabled={true}
                                  id={`role${item}`}
                                  name={`role${item}`}
                                  className="relative block w-[200px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                >
                                  <option value="" disabled selected hidden>
                                    Assign Role
                                  </option>
                                  <option value="MEMBER">Member</option>
                                </select>
                                <ErrorTest
                                  errors={errors}
                                  field={`role${item}`}
                                />
                              </div>
                            )}

                            {items.length > 1 && (
                              <div
                                className="cursor-pointer"
                                onClick={() => handleDeleteRow(item)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="#4895EFFF"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  />
                                </svg>
                              </div>
                            )}
                          </div>
                        </>
                      ))}

                      <Button
                        type="button"
                        btnType="btnLink"
                        className="my-3 text-tertiary4-500"
                        onClick={handleAddMore}
                      >
                        + Add more
                      </Button>

                      <div>
                        <Button
                          loading={loading}
                          disabled={loading}
                          type="submit"
                          className="rounded flex w-full justify-center text-center text-base text-white font-medium"
                        >
                          Send Invites
                        </Button>
                      </div>
                      {/* <div
                      className={`${
                        items.length === 1
                          ? "absolute z-5 left-[572px] top-[8px]"
                          : "absolute z-5 left-[608px] top-[8px]"
                      }`}
                      onMouseEnter={onHover}
                      onMouseLeave={onLeave}
                      role="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>
                      {hover && (
                        <div className="absolute lg:w-[400px] xl:w-[500px] z-10 top-[35px] lg:top-[30px] left-[-450px] lg:left-[-40px] bg-secondary-500 rounded-lg p-4 text-white">
                          <p className="mb-2">Roles</p>
                          <div className="flex">
                            <span className="mr-2">Owner:</span>
                            <p className="text-neutral-400">Full permission</p>
                          </div>

                          <div className="flex">
                            <span className="mr-2">Admin:</span>
                            <p className="text-neutral-400">
                              Access to all functions except delete company and
                              invite/delete admins
                            </p>
                          </div>

                          <div className="flex">
                            <span className="mr-2">Manager:</span>
                            <p className="text-neutral-400">
                              Invite/delete members, add/delete invoices, access
                              settings, view bank account details.
                            </p>
                          </div>

                          <div className="flex">
                            <span className="mr-2">Member:</span>
                            <p className="text-neutral-400">
                              View bank accounts, invoices and financials
                            </p>
                          </div>
                        </div>
                      )}
                    </div> */}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
}
