import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3BottomLeftIcon,
  BellIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Outlet, useMatch, useNavigate, useParams } from "react-router";
import { Link, NavLink, useLocation } from "react-router-dom";
import Accordian from "../Accordian";
import { getCurrentFinancialYear } from "src/services/helpers/getCurrentFinancialYear";
import { useAuth, UserActions } from "src/context/AuthProvider";
import { useCompanyData } from "src/context/CompanyDataProvider";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";
import ProfileEditNameSlider from "../ProfileSlider/ProfileEditNameSlider";
import EmailSendSlider from "../ProfileSlider/EmailSendSlider";
import LogOutSlider from "../ProfileSlider/LogOutSlider";
import FullScreenLoader from "../FullScreenLoader";
import { toast } from "react-toastify";
import "./sidebar.scss";
// import CloudLogo from "../../logo.svg";

// Commenting this as we need this structure when we open first sheet automatically when clicked on FY
// const secondNavigation = [
//   {
//     financialYear: "2022-2023",
//     href: "/financial/2022-2023",
//     children: [
//       { name: "sheet1", href: "/financial/2022-2023/sheet1" },
//       { name: "sheet2", href: "/financial/2022-2023/sheet2" },
//       { name: "sheet3", href: "/financial/2022-2023/sheet3" },
//     ],
//   },
//   {
//     financialYear: "2021-2022",
//     href: "/financial/2021-2022",
//     children: [
//       { name: "sheet5", href: "/financial/2021-2022/sheet5" },
//       { name: "sheet6", href: "/financial/2021-2022/sheet6" },
//     ],
//   },
//   {
//     financialYear: "2020-2021",
//     href: "/financial/2020-2021",
//     children: [],
//   },
// ];

// Commenting as when we need multiple compnay in one worksapce than this JSON strucuture can be used
// const secondNavigation = [
//   {
//     companyName: "Growisto",
//     children: [
//       {
//         yearName: "2022-2023",
//         href: "/financial/growisto/2022-2023",
//         child: [
//           { name: "sheet1", href: "/financial/growisto/2022-2023/sheet1" },
//           { name: "sheet2", href: "/financial/growisto/2022-2023/sheet2" },
//           { name: "sheet3", href: "/financial/growisto/2022-2023/sheet3" },
//         ],
//       },
//       {
//         yearName: "2021-2022",
//         href: "/financial/growisto/2021-2022",
//         child: [
//           { name: "sheet2", href: "/financial/growisto/2021-2022/sheet2" },
//           { name: "sheet3", href: "/financial/growisto/2021-2022/sheet3" },
//         ],
//       },
//     ],
//   },
//   {
//     companyName: "CashWise",
//     children: [
//       {
//         yearName: "2022-2023",
//         href: "/financial/cashwise/2022-2023",
//         child: [
//           { name: "sheet1", href: "/financial/cashwise/2022-2023/sheet1" },
//           { name: "sheet2", href: "/financial/cashwise/2022-2023/sheet2" },
//           { name: "sheet3", href: "/financial/cashwise/2022-2023/sheet3" },
//         ],
//       },
//       {
//         yearName: "2021-2022",
//         href: "/financial/cashwise/2021-2022",
//         child: [
//           { name: "sheet4", href: "/financial/cashwise/2021-2022/sheet4" },
//           { name: "sheet5", href: "/financial/cashwise/2021-2022/sheet5" },
//         ],
//       },
//       { yearName: "2020-2021", href: "/financial/cashwise/2020-2021" },
//     ],
//   },
//   // {
//   //   name: "2021-2022",
//   //   children: [
//   //     { name: "Sheet_4", href: "/financial/sheet4" },
//   //     { name: "Sheet_5", href: "/financial/sheet5" },
//   //     { name: "Sheet_6", href: "/financial/sheet6" },
//   //   ],
//   // },
// ];

const navigation = [
  // {
  //   name: "Bank Accounts",
  //   href: "/",
  //   url: "/icons/Home/Bank.png",
  //   icon: HomeIcon,
  //   current: true,
  // },
  // {
  //   name: "Monthly Invoices",
  //   href: `/invoice/${getCurrentFinancialYear()}`,
  //   url: "/icons/Home/Invoice.png",
  //   icon: UsersIcon,
  //   current: false,
  // },
  {
    name: "Settings",
    href: "/settings",
    url: "/icons/Home/Setting.png",
    current: false,
  },
];

// const secondNavigation = [
//   {
//     financialYear: "2022-2023",
//     children: [
//       {
//         sheetName: "Sheet1_Name",
//         sheetId: "sheet1",
//       },
//       {
//         sheetName: "Sheet2_Name",
//         sheetId: "sheet2",
//       },
//       {
//         sheetName: "Sheet3_Name",
//         sheetId: "sheet3",
//       },
//     ],
//   },
//   {
//     financialYear: "2021-2022",
//     children: [],
//   },
// ];

const documentNavigation = [
  {
    name: "Document Generation",
    href: `/documentGeneration`,
    url: "/icons/Home/foldersPlus.png",
    current: false,
  },
  // {
  //   name: "Formation and Diligence",
  //   href: `/formationDiligence`,
  //   url: "/icons/Home/folders.png",
  //   current: false,
  // },
  // {
  //   name: "Compliance",
  //   href: "/compliance",
  //   url: "/icons/Home/folders.png",
  //   current: false,
  // },
];

const documentSecondNavigation = [
  {
    name: "Document Storage",
    children: [
      {
        childName: "Formation and Diligence",
        href: `/formationDiligence`,
        url: "/icons/Home/folders.png",
        current: false,
      },
      {
        childName: "Compliance",
        href: "/compliance",
        url: "/icons/Home/folders.png",
        current: false,
      },
    ],
  },
];

export default function SideBar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showProfileSlider, setShowProfileSlider] = useState(false);
  const [showEmailSlider, setShowEmailSlider] = useState(false);
  const [showLogOutSlider, setShowLogOutSlider] = useState(false);
  const [hover, setHover] = useState(false);

  const { state: CompanyData, reloadCompnayData } = useCompanyData();
  const { getCompanyRelatedData, getUserDetails } = useAuthOnBoardFlow();
  const {
    state: authData,
    fetchTokenManually,
    isLoading,
    idToken,
    dispatch,
    state: UserData,
  } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      if (idToken) {
        const updatedToken = await fetchTokenManually();
        const token = updatedToken || authData?.idTokenUpdated || idToken;
        const companyId = window.localStorage.getItem("companyId") || state;
        try {
          const userData = await getUserDetails(token);
          const res = await getCompanyRelatedData(token, companyId);
          dispatch({
            type: UserActions.INTIAL,
            payload: {
              customer: userData,
              isAuthenticated: true,
              companyClicked: true,
            },
          });
          reloadCompnayData(res);
        } catch (error) {
          toast.error(error?.response?.data?.message, {
            className: "toast-position",
            position: "bottom-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // Commenting as we disable bankaccount for now and our / is changed from bankaccount to companies page
          // navigate("/companies");
          // Commenting this as we handle this in axiosInterceptor
          // navigate("/");
        }
      }
    };

    fetchData();
  }, [idToken]);

  useEffect(() => {
    const isUser = window.localStorage.getItem("user");
    if (!isUser) {
      navigate("/login");
    }
  }, []);

  // Commenting this as it will be only element on our DOM, move this on bottom
  // if (isLoading) {
  //   return <FullScreenLoader />;
  // }

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const getCurrentTitle = () => {
    if (location.pathname.includes("/financial")) {
      return "Financials";
    }

    if (location.pathname.includes("/invoice")) {
      return "Invoices and Documents";
    }

    switch (location.pathname) {
      case "/":
        return "Bank Accounts";
      case "/invoice":
        return "Invoices and Documents";
      case "/settings":
        return "Settings";
      case "/co-workers":
        return "Co-workers";
      case "/account":
        return "Your Account";
      case "/gethelp":
        return "Get Help";
      case "/invite":
        return "Invite";
      default:
        return "";
    }
  };

  const handleSwitchCompany = (switched) => {
    if (switched) {
      // Commenting as we disable bankaccount for now and our / is changed from bankaccount to companies page
      // navigate("/companies");
      navigate("/");
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const userNavigation = [
    {
      name: "Profile",
      func: setShowProfileSlider,
      href: "/account",
      iconPath: "/icons/Profile/user.png",
    },
    {
      name: "Get Help",
      func: setShowEmailSlider,
      href: "/invite",
      iconPath: "/icons/Profile/questionHelp.png",
    },
    {
      name: "Switch Company",
      func: handleSwitchCompany,
      href: "/gethelp",
      iconPath: "/icons/Profile/switchCompany.png",
    },
  ];

  const noSheetTitle = (
    <div className="fYear_Navigation flex items-center gap-2">
      {/* <img
      src="/icons/Home/Financial.png"
      className="mr-2 inline-block"
      alt="Year"
    /> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
        />
      </svg>

      <span className="text-sm">FY {getCurrentFinancialYear()}</span>
    </div>
  );

  const noSheetContent = (
    <div
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      className="flex relative gap-2 mt-4 pb-8 text-neutral-400"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        className="w-6 h-6 text-neutral-400"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
        />
      </svg>
      <span>No sheets available</span>
      {hover && (
        <div className="absolute z-10 top-[40px] w-auto bg-white rounded-lg p-4 text-neutral-700 text-sm shadow-lg">
          <p>
            Sheets of financials are generated only after you have added your
            <Link
              className="text-tertiary4-500 underline hover:no-underline"
              to={`/invoice/${getCurrentFinancialYear()}`}
            >
              {" "}
              monthly invoices{" "}
            </Link>
            of the previous month.
          </p>
        </div>
      )}
    </div>
  );

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-2">
                  <div className="flex flex-shrink-0 items-center">
                    {CompanyData?.logoUrl ? (
                      <Link to="/settings">
                        <img
                          src={CompanyData?.logoUrl}
                          className="w-9 h-9"
                          alt={CompanyData?.name}
                        />
                      </Link>
                    ) : (
                      <div className="flex items-center justify-center w-9 h-9 bg-neutral-300">
                        <Link to="/settings">
                          {CompanyData?.name?.slice(0, 1)}
                        </Link>
                      </div>
                    )}
                    <Link
                      to="/settings"
                      className="ml-2 text-black text-sm truncate"
                    >
                      {CompanyData?.name}
                    </Link>
                  </div>
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <nav className="space-y-1 px-2">
                    <div className="space-y-2 pt-5 border-t-[1px] border-neutral-400">
                      <h3
                        className="px-3 text-base font-medium"
                        id="projects-headline"
                      >
                        Documents
                      </h3>

                      <div
                        className="space-y-1 px-1"
                        role="group"
                        aria-labelledby="projects-headline"
                      >
                        {documentNavigation?.map((item, index) => (
                          <NavLink
                            key={index}
                            to={item.href}
                            className={({ isActive, isPending }) =>
                              isActive
                                ? "navigationMain bg-tertiary4-500 text-white group flex items-center px-2 py-2 text-base font-normal"
                                : `${"navigationMain hover:bg-neutral-300 text-black group flex items-center px-2 py-2 text-base font-normal"} `
                            }
                          >
                            {/* <img
                              src={item.url}
                              alt={item.name}
                              className="mr-2"
                            /> */}
                            {item.name}
                          </NavLink>
                        ))}
                      </div>

                      <div role="group" aria-labelledby="projects-headline">
                        {documentSecondNavigation?.length > 0 &&
                          documentSecondNavigation?.map((year, index) => {
                            const content = (
                              <div className="space-y-2 pt-4 flex flex-col text-white">
                                {year.children.length > 0 &&
                                  year.children.map((sheet) => (
                                    <NavLink
                                      // className={({ isActive, isPending }) =>
                                      //   isActive
                                      //     ? "bg-tertiary4-500 text-white group flex items-center py-1  text-base font-normal"
                                      //     : `${"hover:bg-primary-200 text-white group flex items-center py-1  text-base font-normal"} `
                                      // }
                                      className={({ isActive, isPending }) =>
                                        isActive
                                          ? "navigationMain bg-tertiary4-500 text-white group flex items-center px-2 py-2 text-base font-normal"
                                          : `${"navigationMain hover:bg-primary-200 hover:text-white text-black group flex items-center px-2 py-2 text-base font-normal"} `
                                      }
                                      to={sheet.href}
                                      // to={`/financial/${year.financialYear}/${sheet.sheetId}`}
                                    >
                                      {/* <img
                                        src={sheet.url}
                                        className="mr-2 inline-block"
                                        alt="Year"
                                      /> */}
                                      {sheet.childName}
                                    </NavLink>
                                  ))}
                              </div>
                            );

                            const title = (
                              <div className="fYear_Navigation flex items-center gap-2">
                                <span className="text-base">{year.name}</span>
                              </div>
                            );

                            return (
                              <Accordian
                                title={title}
                                content={content}
                                className="FYearList"
                              />
                            );
                          })}
                      </div>
                    </div>

                    <div className="space-y-4 pt-6 border-t-[3px] border-neutral-400">
                      {navigation.map((item, index) => (
                        <NavLink
                          onClick={() => setSidebarOpen(false)}
                          key={index}
                          to={item.href}
                          className={({ isActive, isPending }) =>
                            isActive
                              ? "navigationMain bg-tertiary4-500 text-white group flex items-center px-2 py-2 text-sm font-normal"
                              : `${
                                  (item.href === "/settings" &&
                                    location.pathname === "/co-workers") ||
                                  (location.pathname.includes("/invoice") &&
                                    item.href.includes("/invoice"))
                                    ? "navigationMain bg-tertiary4-500 text-white group flex items-center px-2 py-2 text-sm font-normal"
                                    : "navigationMain hover:bg-neutral-300 text-black group flex items-center px-2 py-2 text-sm font-normal"
                                } `
                          }
                        >
                          {/* <item.icon
                      className={classNames(
                        // item.current
                        // ? "text-gray-500"
                        // : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    /> */}
                          {item.name}
                        </NavLink>
                      ))}
                      {/* <h3
                        className="px-3 text-sm font-medium text-black"
                        id="projects-headline"
                      >
                        Financials
                      </h3>

                      <div
                        className="space-y-5 px-4"
                        role="group"
                        aria-labelledby="projects-headline"
                      >
                        {CompanyData?.financials.length > 0 ? (
                          CompanyData?.financials?.map((year, index) => {
                            const content = (
                              <div className="space-y-2 pt-4 flex flex-col text-white">
                                {year.children.length > 0 ? (
                                  year.children.map((sheet) => (
                                    <NavLink
                                      state={{
                                        tableData: sheet.sheetData,
                                        tableColumns: sheet.sheetColumns,
                                      }}
                                      // state={{
                                      //   tableData: {
                                      //     tableRowData: sheet.sheetData,
                                      //     tableColumnData: sheet.sheetColumns,
                                      //   },
                                      // }}
                                      className={({ isActive, isPending }) =>
                                        isActive
                                          ? "bg-tertiary4-500 text-white group flex items-center py-1 px-1 text-sm font-normal"
                                          : `${"hover:bg-primary-200 text-black hover:text-white group flex items-center py-1 px-1 text-sm font-normal"} `
                                      }
                                      to={`/financial/${year.financialYear}/${sheet.sheetId}`}
                                    >
                                      <img
                                        src="/icons/Home/sheet.png"
                                        className="mr-2 inline-block"
                                        alt="Year"
                                      />
                                      {sheet.sheetName}
                                    </NavLink>
                                  ))
                                ) : (
                                  <div
                                    onMouseEnter={onHover}
                                    onMouseLeave={onLeave}
                                    className="flex relative gap-2 pb-8 text-neutral-400"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6 text-neutral-400"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                      />
                                    </svg>
                                    <span>No sheets available</span>
                                    {hover && (
                                      <div className="absolute z-10 top-[40px] w-auto bg-white rounded-lg p-4 text-neutral-700 shadow-lg">
                                        <p>
                                          Sheets of financials are generated
                                          only after you have added your
                                          <Link
                                            className="text-tertiary4-500 underline hover:no-underline"
                                            to={`/invoice/${getCurrentFinancialYear()}`}
                                          >
                                            {" "}
                                            monthly invoices{" "}
                                          </Link>
                                          of the previous month.
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            );

                            const title = (
                              <div className="fYear_Navigation flex items-center gap-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                                  />
                                </svg>

                                <span className="text-sm">
                                  FY {year.financialYear}
                                </span>
                              </div>
                            );

                            return (
                              <Accordian
                                title={title}
                                content={content}
                                className="FYearList"
                              />
                            );
                          })
                        ) : (
                          <Accordian
                            title={noSheetTitle}
                            content={noSheetContent}
                            className="FYearList"
                          />
                        )}
                      </div> */}
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden z-[11] md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        {/* <div className="flex flex-grow flex-col overflow-y-auto bg-secondary-500 border-r border-gray-200 pt-5"> */}
        <div className="flex flex-grow flex-col overflow-y-auto bg-secondary-500 pt-5">
          <div className="flex flex-shrink-0 items-center px-2">
            {CompanyData?.logoUrl ? (
              <Link to="/settings">
                <img
                  src={CompanyData?.logoUrl}
                  className="w-9 h-9"
                  alt={CompanyData?.name}
                />
              </Link>
            ) : (
              <div className="flex items-center justify-center w-9 h-9 bg-neutral-300">
                <Link to="/settings">{CompanyData?.name?.slice(0, 1)}</Link>
              </div>
            )}
            <Link
              title={CompanyData?.name}
              to="/settings"
              className="ml-2 text-white text-xl truncate w-[75%]"
            >
              {CompanyData?.name}
            </Link>
          </div>

          <div className="mt-5 flex flex-grow flex-col">
            <nav className="flex-1 space-y-1 pb-4">
              {/* <div className="pt-4 border-t-[1px] border-neutral-400"></div> */}

              <div className="space-y-2 pt-5 border-t-[1px] border-neutral-400">
                <h3
                  className="px-3 text-base font-medium text-white"
                  id="projects-headline"
                >
                  Documents
                </h3>

                <div
                  className="space-y-1"
                  role="group"
                  aria-labelledby="projects-headline"
                >
                  {documentNavigation?.map((item, index) => (
                    <NavLink
                      key={index}
                      to={item.href}
                      className={({ isActive, isPending }) =>
                        isActive
                          ? "mb-3 bg-tertiary4-500 text-white group flex items-center px-2 py-2 text-base font-normal"
                          : `${"mb-3 hover:bg-primary-200 text-white group flex items-center px-2 py-2 text-base font-normal"} `
                      }
                    >
                      <img src={item.url} alt={item.name} className="mr-2" />
                      {item.name}
                    </NavLink>
                  ))}
                </div>

                <div role="group" aria-labelledby="projects-headline">
                  {documentSecondNavigation?.length > 0 &&
                    documentSecondNavigation?.map((year, index) => {
                      const content = (
                        <div className="space-y-2 pt-4 flex flex-col text-white">
                          {year.children.length > 0 &&
                            year.children.map((sheet) => (
                              <NavLink
                                // className={({ isActive, isPending }) =>
                                //   isActive
                                //     ? "bg-tertiary4-500 text-white group flex items-center py-1  text-base font-normal"
                                //     : `${"hover:bg-primary-200 text-white group flex items-center py-1  text-base font-normal"} `
                                // }
                                className={({ isActive, isPending }) =>
                                  isActive
                                    ? " bg-tertiary4-500 text-white group flex items-center px-2 py-2 text-base font-normal"
                                    : `${" hover:bg-primary-200 text-white group flex items-center px-2 py-2 text-base font-normal"} `
                                }
                                to={sheet.href}
                                // to={`/financial/${year.financialYear}/${sheet.sheetId}`}
                              >
                                <img
                                  src={sheet.url}
                                  className="mr-2 inline-block"
                                  alt="Year"
                                />
                                {sheet.childName}
                              </NavLink>
                            ))}
                        </div>
                      );

                      const title = (
                        <div className="fYear_Navigation flex items-center gap-2">
                          <img
                            // className="img w-6 h-6"
                            src="/icons/Home/cloud.png"
                            alt="doc"
                          />
                          {/* <CloudLogo /> */}

                          <span className="text-base">{year.name}</span>
                        </div>
                      );

                      return (
                        <Accordian
                          title={title}
                          content={content}
                          className="FYearList mb-[22px]"
                        />
                      );
                    })}
                </div>
              </div>

              <div className="space-y-4 pt-6 border-t-[1px] border-neutral-400">
                {navigation.map((item, index) => (
                  <NavLink
                    key={index}
                    to={item.href}
                    className={({ isActive, isPending }) =>
                      isActive
                        ? "navigationMain bg-tertiary4-500 text-white group flex items-center px-2 py-2 text-base font-normal"
                        : `${
                            (item.href === "/settings" &&
                              location.pathname === "/co-workers") ||
                            (location.pathname.includes("/invoice") &&
                              item.href.includes("/invoice"))
                              ? "navigationMain bg-tertiary4-500 text-white group flex items-center px-2 py-2 text-base font-normal"
                              : "navigationMain hover:bg-primary-200 text-white group flex items-center px-2 py-2 text-base font-normal"
                          } `
                    }
                  >
                    {/* <item.icon
                      className={classNames(
                        // item.current
                        // ? "text-gray-500"
                        // : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    /> */}
                    <img src={item.url} alt={item.name} className="mr-2" />
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div className="relative flex flex-1 flex-col md:ml-64">
        {/* <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow"> */}
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between pl-4 pr-5 h-[76px] bg-secondary-500 ">
            <div className="flex flex-1 items-center">
              <div className="flex w-full md:ml-0 items-center text-neutral-900 font-semibold">
                {/* {getCurrentTitle()} */}
              </div>
              <div className="bg-secondary-100 px-2 rounded-[18px] flex items-center justify-center text-xs w-[80px] h-[36px]">
                {CompanyData?.userRole}
              </div>
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm">
                    {({ open }) => (
                      <div
                        className={`${
                          open ? " ring-2 ring-offset-2 ring-tertiary4-500" : ""
                        } flex items-center justify-center h-8 w-8 rounded-full bg-tertiary4-500 text-white active:ring-2`}
                      >
                        <p className={`${open ? "ayush" : "not"}`}>
                          {UserData?.customer?.firstName.slice(0, 1)}
                          {UserData?.customer?.lastName.slice(0, 1)}
                        </p>
                      </div>
                    )}
                    {/* <span className="sr-only">Open user menu</span> */}
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="border border-neutral-400 absolute right-0 z-10 mt-2 w-80 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="flex items-center  p-3 border-b border-neutral-400">
                      <div className="flex items-center justify-center h-16 w-16 rounded-full bg-[#FF7D32FF] text-white">
                        <p>
                          {UserData?.customer?.firstName.slice(0, 1)}
                          {UserData?.customer?.lastName.slice(0, 1)}
                        </p>
                      </div>
                      <div>
                        <p
                          className={classNames(
                            "block px-4 py-1 text-xl text-neutral-900"
                          )}
                        >
                          {UserData?.customer?.firstName}{" "}
                          {UserData?.customer?.lastName}
                        </p>
                        <p
                          className={classNames(
                            "block px-4 py-1 text-sm text-neutral-900"
                          )}
                        >
                          {UserData?.customer?.email}
                        </p>
                      </div>
                    </div>
                    {userNavigation.map((item, index) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          // <Link
                          //   key={index}
                          //   to={item.href}
                          //   className={classNames(
                          //     active ? "bg-gray-100" : "",
                          //     "flex items-center gap-2 px-4 py-2.5 text-sm text-neutral-900"
                          //   )}
                          // >
                          //   <img
                          //     src={item.iconPath}
                          //     alt={item.name}
                          //     className="max-w-full h-auto w-6"
                          //   />
                          //   {item.name}
                          // </Link>
                          <button
                            onClick={() => item.func(true)}
                            key={index}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "flex w-full items-center gap-2 px-4 py-2.5 text-base text-neutral-900"
                            )}
                          >
                            <img
                              src={item.iconPath}
                              alt={item.name}
                              className="max-w-full h-auto w-6"
                            />
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                    <button
                      onClick={() => setShowLogOutSlider(true)}
                      className={classNames(
                        "flex items-center gap-2 w-full text-left px-4 py-2.5 text-base text-neutral-900 hover:bg-gray-100 border-t border-neutral-400"
                      )}
                    >
                      <img
                        src="/icons/Profile/logOut.png"
                        alt="Log out"
                        className="max-w-full h-auto w-6"
                      />
                      Log Out
                    </button>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="flex-1 ">
          <div className="pb-6">
            <div className="mx-auto max-w-full">
              {isLoading ? (
                <FullScreenLoader />
              ) : (
                <div className="py-4">
                  {/* <span className="text-xl text-neutral-900 font-medium inline-block py-5">
                    {getCurrentTitle()}
                  </span> */}
                  <Outlet />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>

      <ProfileEditNameSlider
        open={showProfileSlider}
        closeModal={setShowProfileSlider}
      />
      <EmailSendSlider open={showEmailSlider} closeModal={setShowEmailSlider} />
      <LogOutSlider open={showLogOutSlider} closeModal={setShowLogOutSlider} />
    </>
  );
}

{
  /* Below code will be used when we want to open first sheet automatically when clicked on FY  */
}
{
  /* <div
                    className="space-y-1"
                    role="group"
                    aria-labelledby="projects-headline"
                  >
                    {secondNavigation.map((item) => (
                      <>
                        <NavLink
                          key={item.financialYear}
                          to={
                            item.children.length > 0
                              ? item.children[0].href
                              : item.href
                          }
                          className={({ isActive, isPending }) =>
                            isActive
                              ? "bg-tertiary4-500 text-white group flex items-center px-2 py-2 text-sm font-normal"
                              : "text-white  group flex items-center px-2 py-2 text-sm font-normal"
                          }
                        >
                          <img
                            src="/icons/Home/Financial.png"
                            className="mr-2"
                          />
                          <span>FY {item.financialYear}</span>
                        </NavLink>
                        {location.pathname.includes(item.href) ? (
                          item.children.length > 0 ? (
                            <div className="pt-2 space-y-2 ml-4">
                              {item.children.map((item, index) => (
                                <NavLink
                                  to={item.href}
                                  key={index}
                                  className={({ isActive, isPending }) =>
                                    isActive
                                      ? "bg-tertiary4-500 text-white group flex items-center px-2 py-1 text-sm"
                                      : "text-white group flex items-center px-2 text-sm"
                                  }
                                >
                                  {item.name}
                                </NavLink>
                              ))}
                            </div>
                          ) : null
                        ) : null}
                      </>
                    ))}
                  </div> */
}

{
  /* Below code will be used when we use multiple compnay in one worksapce */
}
{
  /* <div
                    className="space-y-1"
                    role="group"
                    aria-labelledby="projects-headline"
                  >
                    {secondNavigation.map((item) => {
                      const childContent = (
                        <div className="pt-2 space-y-2 ml-1">
                          {item.children.map(
                            ({ yearName, href, child = [] }) => (
                              <>
                                <NavLink
                                  key={yearName}
                                  to={child.length > 0 ? child[0].href : href}
                                  className={({ isActive, isPending }) =>
                                    isActive
                                      ? "text-gray-900 group flex items-center px-2 py-1 text-sm rounded-md"
                                      : "text-neutral-900 group flex items-center px-2 text-sm rounded-md"
                                  }
                                >
                                  <span className="truncate">{yearName}</span>
                                </NavLink>
                                {location.pathname.includes(href) ? (
                                  child.length ? (
                                    <div className="pt-2 space-y-2 ml-4">
                                      {child.map((item, index) => (
                                        <NavLink
                                          to={item.href}
                                          key={index}
                                          className={({
                                            isActive,
                                            isPending,
                                          }) =>
                                            isActive
                                              ? "bg-[#B2B2B2FF] text-gray-900 group flex items-center px-2 py-1 text-sm rounded-md"
                                              : "text-neutral-900 group flex items-center px-2 text-sm rounded-md"
                                          }
                                        >
                                          {item.name}
                                        </NavLink>
                                      ))}
                                    </div>
                                  ) : null
                                ) : null}
                              </>
                            )
                          )}
                        </div>
                      );

                      return (
                        <Accordian
                          className="sidebar_accordian"
                          title={item.companyName}
                          content={childContent}
                        />
                      );
                    })}
                  </div> */
}
