import axios from "axios";

const useDocumentGeneration = () => {
  const downloadDocument = async (idToken, companyId, docCode, dataObj) => {
    console.log("hoook", docCode, dataObj);
    return await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/doc-gen/${companyId}/${docCode}/word`,
      dataObj,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        responseType: "blob",
      }
    );
  };

  const downloadDocumentPDF = async (idToken, companyId, docCode, dataObj) => {
    console.log("hoook", docCode, dataObj);
    return await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/doc-gen/${companyId}/${docCode}/pdf`,
      dataObj,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        responseType: "blob",
      }
    );
  };

  const downloadSampleTemplate = async (idToken, companyId, templateCode) => {
    console.log("hoook", templateCode);
    return await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/doc-gen/${companyId}/template/${templateCode}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        responseType: "blob",
      }
    );
  };

  const requestCustomDocument = async (idToken, companyId, dataObj) => {
    console.log("hoook", dataObj);
    return await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/doc-gen/${companyId}/custom-request`,
      dataObj,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  return {
    downloadDocument,
    downloadSampleTemplate,
    requestCustomDocument,
    downloadDocumentPDF,
  };
};

export default useDocumentGeneration;
