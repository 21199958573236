import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { useSettings } from "src/hooks/useSettings";
import { useAuth } from "src/context/AuthProvider";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import { useFormationDocuments } from "src/hooks/useFormationDocuments";
import { useCompanyData } from "src/context/CompanyDataProvider";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function ImportDriveLinkModal({ open, closeModal, docType }) {
  const [importInProgress, setImportInProgress] = useState(false);

  const { idToken } = useAuth();
  const { shareDriveLink } = useFormationDocuments();
  const { state: CompanyData } = useCompanyData();

  const navigate = useNavigate();
  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  // const onSubmit = async(data) => {
  //   console.log(data);
  //   reset();
  //   setImportInProgress(true);
  // };

  const onSubmit = async (data) => {
    // setLoading(true);
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");

    try {
      const res: any = await shareDriveLink(
        companyId,
        idToken,
        docType,
        data?.driveLink
      );
      if (res) {
        console.log("res link", res);
        reset();
        setImportInProgress(true);

        // setLoading(false);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong!! Please try again later",
        {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      // setLoading(false);
      // closeModal(false);
    }
  };

  return (
    <>
      {importInProgress ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-[12]" onClose={() => null}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="flex flex-col justify-center items-center w-full">
                      <img
                        className="mt-3"
                        alt="wait"
                        src="/icons/FormDill/progress.png"
                      />
                      <p className="mt-3 text-neutral-900 text-xl">
                        Import in Progress
                      </p>
                      <p className="my-6 text-neutral-900 text-base text-center">
                        Thank you for the link! The import can take upto 24hrs
                        to be reflected on your cashwise dashboard.
                      </p>
                      <Button
                        onClick={() => {
                          closeModal(false);
                          setImportInProgress(false);
                        }}
                        type="button"
                        className="flex w-full justify-center rounded text-base font-medium text-white focus:outline-none"
                      >
                        Continue
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-[12]" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[600px] sm:p-6">
                    <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
                        onClick={() => closeModal(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="sm:flex flex-col sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base text-neutral-900"
                        >
                          Paste a shareable link of the drive containing all the
                          files
                        </Dialog.Title>
                      </div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="w-full mt-8 mb-2">
                        <div className="mt-1">
                          <input
                            type="text"
                            name="driveLink"
                            id="driveLink"
                            className="block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-base"
                            placeholder="Drive Link"
                            {...register("driveLink", {
                              required: {
                                value: true,
                                message: "Please enter drive link to proceed",
                              },
                            })}
                          />
                          <ErrorTest errors={errors} field="driveLink" />
                        </div>
                        <div className="mt-5 flex items-center">
                          <div className="flex h-5 items-center">
                            <input
                              id="confirm"
                              aria-describedby="confirm"
                              name="confirm"
                              type="checkbox"
                              className="h-4 w-4 rounded border-neutral-600 text-neutral-900"
                              {...register("confirm", {
                                required: {
                                  value: true,
                                  message: "Please select checkbox to proceed",
                                },
                              })}
                            />
                          </div>
                          <div className="ml-3 text-base">
                            <span id="confirm" className="text-neutral-900">
                              I confirm that anyone with the link can access the
                              files
                            </span>
                          </div>
                        </div>
                        <ErrorTest errors={errors} field="confirm" />
                      </div>
                      <div className="mt-5 sm:mt-4 gap-4 ">
                        <Button
                          // disabled={loading}
                          // loading={loading}
                          className="flex w-full justify-center rounded text-base font-medium text-white focus:outline-none"
                        >
                          Import
                        </Button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
      <ToastContainer />
    </>
  );
}
