import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router";
import Button from "src/components/Button";
import { useAuth } from "src/context/AuthProvider";
import { useCompanyData } from "src/context/CompanyDataProvider";
import useDocumentGeneration from "src/hooks/useDocumentGeneration";
import CopyDownloadModal from "../CopyDownloadModal";
import DownloadTemplateModal from "../DownloadTemplateModal";
import RequestSubmitModal from "../RequestSubmitModal";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function RequestCustom() {
  const [showRequestCustomModal, setShowRequestCustomModal] = useState(false);

  const { state: CompanyData } = useCompanyData();
  const { idToken } = useAuth();
  const { docName } = useParams();
  const { state } = useLocation();
  const { requestCustomDocument } = useDocumentGeneration();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const handleFromSubmit = async (data) => {
    console.log(data);
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");

    const dataObj = {
      email: data.Email || "",
      mobile: data.mobileNumber || "",
      docType: data.documentType || "",
      purpose: data.comment || "",
    };

    try {
      const result = await requestCustomDocument(idToken, companyId, dataObj);
      console.log("result", result);
      if (result?.status == 200 || result?.data == "OK") {
        setShowRequestCustomModal(true);
      }
    } catch (error) {
      console.log("error", error);
    }

    // setactivateToCompo(true);
  };

  return (
    <>
      <div className="docComponentRender">
        <div className="flex items-center justify-between border-b border-neutral-400 gap-4 pl-[30px] pr-5">
          <div className="flex items-center gap-6">
            <span className="text-xl text-neutral-900 font-semibold inline-block py-5">
              Request Custom Document
            </span>
          </div>
        </div>
        <div className="activeFromComp ml-[30px] mr-5 mt-6">
          <p className="text-neutral-900">
            Tell us about your request, and our team of lawyers and CAs would
            draft an appropriate document for you!{" "}
          </p>
          <p className="mt-5 font-semibold text-neutral-900">
            Contact Details:
          </p>
          <form onSubmit={handleSubmit(handleFromSubmit)}>
            <div className="fromGroup space-y-5 pt-4">
              <div className="flex gap-6">
                <div className="w-full">
                  <input
                    {...register("Email", {
                      required: {
                        value: true,
                        message: "Email is required",
                      },
                    })}
                    id="Email"
                    name="Email"
                    type="email"
                    className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Email"
                  />
                  <ErrorTest errors={errors} field="Email" />
                </div>
                <div className="w-full">
                  <input
                    {...register("mobileNumber", {
                      required: {
                        value: true,
                        message: "Mobile Number is required",
                      },
                      minLength: {
                        value: 10,
                        message: "Please enter 10 digit mobile number",
                      },
                      maxLength: {
                        value: 10,
                        message: "Please enter 10 digit mobile number",
                      },
                    })}
                    id="mobileNumber"
                    name="mobileNumber"
                    type="text"
                    className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Mobile Number"
                  />
                  <ErrorTest errors={errors} field="mobileNumber" />
                </div>
              </div>
              <div>
                <label className="text-neutral-900 font-semibold">
                  Document Type:{" "}
                </label>
                <input
                  {...register("documentType", {
                    required: {
                      value: true,
                      message: "Document type is required",
                    },
                  })}
                  id="documentType"
                  name="documentType"
                  type="text"
                  className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 mt-3 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Ex: Vendor Agreement, Privacy Policy, etc"
                />
                <ErrorTest errors={errors} field="documentType" />
              </div>
              <div>
                <label
                  htmlFor="comment"
                  className="text-neutral-900 font-semibold"
                >
                  Tell us about the purpose of document:
                </label>
                <div className="mt-2">
                  <textarea
                    {...register("comment", {
                      required: {
                        value: true,
                        message: "This is required",
                      },
                    })}
                    rows={4}
                    name="comment"
                    id="comment"
                    className="block w-full rounded-md border-0 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-500 placeholder:text-neutral-900 focus:ring-inset focus:ring-indigo-500 sm:py-1.5 sm:text-sm sm:leading-6"
                    defaultValue={""}
                    placeholder="Explain in brief about why you need the document, we would be able to serve you better!"
                  />
                </div>
                <ErrorTest errors={errors} field="comment" />
              </div>
            </div>
            <Button
              //   onClick={async () => {
              //     const result = await trigger();
              //     if (result) {
              //       setStep(4);
              //     }
              //   }}
              type="submit"
              className="text-white rounded my-4"
            >
              {" "}
              Submit
            </Button>
          </form>
        </div>{" "}
      </div>
      <RequestSubmitModal
        open={showRequestCustomModal}
        closeModal={setShowRequestCustomModal}
        reset={reset}
      />
    </>
  );
}
