import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
// import { routesConfig } from "./routes/routesconfig";
import { AuthProvider } from "./context/AuthProvider";
import App from "./App";
import Invoice from "./routes/Invoice";
import FinancialYear from "./routes/FinancialYear";
import FinancialYearInitial from "./routes/FinancialYear/FinancialInitial";
import BankAccount from "./routes/BankAccount";
import AccountSideBar from "./components/AccountSideBar";
import Account from "./routes/Account";
import GetHelp from "./routes/GetHelp";
import InviteTeam from "./routes/InviteTeam";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const router = createBrowserRouter(routesConfig);

// const routingStr = createRoutesFromElements(
//   <Routes>
//     <Route path="/" element={<App />}>
//       <Route index={true} element={<BankAccount />} />
//       <Route path="invoice" element={<Invoice />} />
//       <Route
//         path="financial/:financialYear"
//         element={<FinancialYearInitial />}
//       />
//       <Route
//         path="financial/:financialYear/:sheetName"
//         element={<FinancialYear />}
//       />
//     </Route>
//     <Route element={<AccountSideBar />}>
//       <Route path="account" element={<Account />} />
//       <Route path="gethelp" element={<GetHelp />} />
//       <Route path="invite" element={<InviteTeam />} />
//     </Route>
//   </Routes>
// );
// const router = createBrowserRouter(routingStr);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    {/* <RouterProvider router={router} /> */}
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
