import axios from "axios";

export const useInvoice = () => {
  const getListOfFinancialYear = (companyId, idToken) => {
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/docs/${companyId}/fy-list`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const getMonthWiseDataInFinancialYear = (
    companyId,
    financialYear,
    idToken
  ) => {
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/docs/${companyId}/FY ${financialYear}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const getDataInMonth = (companyId, financialYear, monthName, idToken) => {
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/docs/${companyId}/FY ${financialYear}/${monthName}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const getParticularFolderData = (
    companyId,
    financialYear,
    monthName,
    folderId,
    idToken
  ) => {
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/docs/${companyId}/FY ${financialYear}/${monthName}/folder/${folderId}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const addFolderInMonth = (
    folderName,
    companyId,
    financialYear,
    monthName,
    idToken,
    parentFolderId = null
  ) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/docs/${companyId}/FY ${financialYear}/${monthName}/folder`,
      {
        folderName,
        parentFolderId: parentFolderId,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const renameFolder = (
    companyId,
    financialYear,
    monthName,
    folderId,
    newFolderName,
    idToken
  ) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/docs/${companyId}/FY ${financialYear}/${monthName}/folder/${folderId}/rename`,
      {
        newFolderName,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const deleteFolder = (
    companyId,
    financialYear,
    monthName,
    folderId,
    idToken
  ) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/docs/${companyId}/FY ${financialYear}/${monthName}/folder/${folderId}/delete`,
      {},
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  // Below are file API Functions

  const uploadFile = (
    companyId,
    financialYear,
    monthName,
    idToken,
    fileData
  ) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/docs/${companyId}/FY ${financialYear}/${monthName}/file`,
      fileData,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  const downloadFile = (
    companyId,
    financialYear,
    monthName,
    fileId,
    idToken
  ) => {
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/docs/${companyId}/FY ${financialYear}/${monthName}/file/${fileId}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        responseType: "blob",
      }
    );
  };

  const deleteFile = (companyId, financialYear, monthName, fileId, idToken) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/docs/${companyId}/FY ${financialYear}/${monthName}/file/${fileId}/delete`,
      {},
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  const renameFile = (
    companyId,
    financialYear,
    monthName,
    fileId,
    newFileName,
    idToken
  ) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/v1/docs/${companyId}/FY ${financialYear}/${monthName}/file/${fileId}/rename`,
      {
        newFileName,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
  };

  return {
    getListOfFinancialYear,
    getMonthWiseDataInFinancialYear,
    getDataInMonth,
    getParticularFolderData,
    addFolderInMonth,
    renameFolder,
    deleteFolder,
    uploadFile,
    downloadFile,
    deleteFile,
    renameFile,
  };
};
