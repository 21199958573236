import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import Button from "src/components/Button";
import FullScreenLoader from "src/components/FullScreenLoader";
import { useAuth } from "src/context/AuthProvider";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function CreateNewCompany() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state: authData, isLoading, idToken } = useAuth();
  const { createCompany } = useAuthOnBoardFlow();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = methods;

  const countryName = watch("country"); // you can supply default value as second argument

  const onSubmit = (data) => {
    setLoading(true);
    const token = authData?.idTokenUpdated || idToken;
    createCompany(
      token,
      data.companyName,
      // data.brandName,
      data.numberOfEmployees,
      data.country,
      data.companyType
    )
      .then((res) => {
        window.localStorage.setItem("companyId", res.companyId);
        setLoading(false);
        navigate("/companies/invite", {
          state: {
            companyId: res.companyId,
            companyName: data.companyName,
          },
        });
      })
      .catch((err) => {
        console.log("error in creating new company", err);
        setLoading(false);
        toast.error(
          err?.response?.data?.message ||
            "Something went wrong!! Please try again later",
          {
            className: "toast-position",
            position: "bottom-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      });
  };

  useEffect(() => {
    const isUser = window.localStorage.getItem("user");
    if (!isUser) {
      navigate("/login");
    }
  }, []);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <img
        className="companyLogo absolute top-5 left-5 mx-auto w-auto h-8"
        src="/logo/cashwise.png"
        alt="Cashwise"
      />
      <div className="hor-ver-center">
        <h2 className="text-center text-neutral-900 text-3xl mb-4">
          Enter details
        </h2>
        <p className="text-center text-neutral-900">
          Please enter details to create your company with cashwise.in
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-6 pt-4">
            <div>
              <input
                {...register("companyName", {
                  required: {
                    value: true,
                    message: "Company name is required",
                  },
                  minLength: {
                    value: 3,
                    message:
                      "Company name should be between 3 to 50 characters",
                  },
                  maxLength: {
                    value: 50,
                    message:
                      "Company name should be between 3 to 50 characters",
                  },
                })}
                id="companyName"
                name="companyName"
                type="companyName"
                className="relative block w-[450px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Company Name"
              />
              <ErrorTest errors={errors} field="companyName" />
            </div>

            {/* <div>
              <input
                {...register("brandName", {
                  required: {
                    value: true,
                    message: "Brand name is required",
                  },
                  minLength: {
                    value: 3,
                    message: "Brand name should be between 3 to 50 characters",
                  },
                  maxLength: {
                    value: 50,
                    message: "Brand name should be between 3 to 50 characters",
                  },
                })}
                id="brandName"
                name="brandName"
                type="brandName"
                className="relative block w-[450px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Brand Name"
              />
              <ErrorTest errors={errors} field="brandName" />
            </div> */}

            <div>
              <select
                {...register("numberOfEmployees", {
                  required: {
                    value: true,
                    message: "Please select number of employees",
                  },
                })}
                id="numberOfEmployees"
                name="numberOfEmployees"
                className="relative block w-[450px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              >
                <option value="" disabled selected hidden>
                  Number of Employees
                </option>
                <option value="OneToTen">1-10</option>
                <option value="ElevenToFifty">11-50</option>
                <option value="FiftyOneToHundred">50-100</option>
                <option value="AboveHundred">more than 100</option>
              </select>
              <ErrorTest errors={errors} field="numberOfEmployees" />
            </div>
            <div>
              <select
                {...register("country", {
                  required: {
                    value: true,
                    message: "Please select type of country ",
                  },
                })}
                id="country"
                name="country"
                className="relative block w-[450px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              >
                <option value="" disabled selected hidden>
                  Country
                </option>
                <option value="India">India</option>
                <option value="US">US</option>
                <option value="Singapore">Singapore</option>
              </select>
              <ErrorTest errors={errors} field="country" />
            </div>
            {!!countryName && (
              <div>
                <select
                  {...register("companyType", {
                    required: {
                      value: true,
                      message: "Please select type of Company ",
                    },
                  })}
                  id="companyType"
                  name="companyType"
                  className="relative block w-[450px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="" disabled selected hidden>
                    Type of Entity
                  </option>
                  {countryName === "India" && (
                    <>
                      <option value="Private Limited">Private Limited</option>
                      <option value="LLP">LLP</option>
                      <option value="Partnership">Partnership</option>
                      <option value="Proprietorship">Proprietorship</option>
                      <option value="HUF">HUF</option>
                    </>
                  )}
                  {countryName === "US" && (
                    <>
                      <option value="Delaware C-Corp">Delaware C-Corp</option>
                    </>
                  )}
                  {countryName === "Singapore" && (
                    <>
                      <option value="Singapore Company">
                        Singapore Company
                      </option>
                    </>
                  )}
                </select>
                <ErrorTest errors={errors} field="companyType" />
              </div>
            )}
            <div>
              {/* <button
                type="submit"
                className="group relative flex w-full justify-center rounded border border-transparent bg-tertiary4-500 py-2 px-4 text-sm font-medium text-white hover:bg-primary-550"
              >
                Continue
              </button> */}
              <Button
                disabled={loading}
                loading={loading}
                className="group relative flex w-full justify-center rounded font-medium text-white focus:outline-none text-base"
              >
                Continue
              </Button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}
