import React from "react";
import NewCustomContextMenu from "./NewCustomContextMenu";

export default function NewContextMenu({
  children,
  targetId,
  items,
  data = null,
  // monthName = null,
  handleClick,
}) {
  return (
    <>
      {children}
      <NewCustomContextMenu
        items={items}
        targetId={targetId}
        data={data}
        handleClick={handleClick}
      />
    </>
  );
}
