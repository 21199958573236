import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import Button from "src/components/Button";
import { stateUT } from "src/services/helpers/stateUT";
import DownloadPDFWorkModal from "../DownloadPDFWordModal";
import DownloadTemplateModal from "../DownloadTemplateModal";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function EmploymentAgreement() {
  const [formData, setFormData] = useState({});
  const [showCopyDownloadModal, setShowCopyDownloadModal] = useState(false);
  const [showDownloadTemplate, setShowDownloadTemplate] = useState(false);
  const [step, setStep] = useState(1);

  const navigate = useNavigate();
  const { docName } = useParams();
  const { state } = useLocation();

  const methods = useForm({ mode: "onChange" });
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = methods;

  const coFounderNo = watch("cofounderNumber");

  const onSubmit = (data) => {
    console.log(data);
    const updatedObj = {
      date_of_agreement: data.date_format || "",
      company_name: data.companyName || "",
      company_address: data.companyAddress || "",
      employee_name: data.employeeName || "",
      employee_address: data.employeeAddress || "",
      employment_type: data.employeeType || "",
      joining_date: data.joining_date || "",
      job_title: data.employeeTitle || "",
      probation_period: data.probabtionTime || "",
      probabtion_notice_period_termination: data.employerNoticePeriod || "",
      probabtion_notice_period_resignation: data.employeeNoticePeriod || "",
      currency: data.currency || "",
      employee_annual_salary: data.amount || "",
      salary_day: data.period || "",
      joining_bonus: data.joiningBonus || "",
      joining_bonus_time: data.joiningDateBonus || "",
      employee_job_duties: data.comment || "",
      office_address: data.officeAddress || "",
      hours_per_week: data.hourPerWeek || "",
      confidential_period: data.timePeriodTermination || "",
      restrain_period: data.restrainPeriod || "",
      notice_period: data.noticePeriodResignation || "",
      non_solicitation_period: data.restrictionPeriod || "",
      court_state: data.state || "",
      employer_sign_date: data.date_format_employerAgreement || "",
      employee_sign_date: data.date_format_employeeAgreement || "",
      company_rep_name: data.fullName || "",
      company_rep_designation: data.designation || "",
    };
    setFormData((prev) => ({ ...prev, updatedObj }));
    setShowCopyDownloadModal(true);
  };

  return (
    <>
      <div className="coFounder">
        <div className="flex items-center justify-between border-b border-neutral-400 gap-4 pl-[30px] pr-5">
          <div className="flex items-center gap-6">
            <span className="text-xl text-neutral-900 font-semibold inline-block py-5">
              Generate {state?.documentName || "Employment Agreement"}
            </span>
          </div>
          <div className="flex gap-4">
            <div
              onClick={() => setShowDownloadTemplate(!showDownloadTemplate)}
              className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>

              <span>Download Template</span>
            </div>
            <div
              onClick={() => navigate("/documentGeneration/requestCustom")}
              // onClick={() => setShowShareModal(!showShareModal)}
              className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>

              <span>Request Custom</span>
            </div>
          </div>
        </div>
        <div className="activeFromComp ml-[30px] mr-5 mt-6">
          <div className="flex justify-between">
            <span className="text-neutral-900">
              Enter below details to generate a prefilled Employment Agreement!
            </span>
            <span>{`Page ${step}/5`}</span>
          </div>

          <FormProvider {...methods}>
            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
              {step === 1 && (
                <>
                  <div>
                    <label className="text-neutral-900 font-semibold">
                      Date on which the agreement will be entered into:
                    </label>
                    <input
                      className="mt-4 relative block appearance-none rounded border border-neutral-500 px-3 py-1 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      type="date"
                      id="date_format"
                      name="date_format"
                      //   onKeyDown={(event) => {
                      //     event.preventDefault();
                      //   }}
                      {...register("date_format", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                      // max={new Date().toISOString().split("T")[0]}
                    />
                    <ErrorTest errors={errors} field="date_format" />
                  </div>

                  <div className="toGroup space-y-4 pt-5">
                    <label className="text-neutral-900 font-semibold">
                      Name and address of the employer:{" "}
                    </label>
                    <div>
                      <input
                        {...register("companyName", {
                          required: {
                            value: true,
                            message: "Company name is required",
                          },
                          minLength: {
                            value: 3,
                            message:
                              "Company name should be between 3 to 50 characters",
                          },
                          maxLength: {
                            value: 50,
                            message:
                              "Company name should be between 3 to 50 characters",
                          },
                        })}
                        id="companyName"
                        name="companyName"
                        type="text"
                        className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Company Name"
                      />
                      <ErrorTest errors={errors} field="companyName" />
                    </div>
                    <div>
                      <input
                        {...register("companyAddress", {
                          required: {
                            value: true,
                            message: "Address is required",
                          },
                        })}
                        id="companyAddress"
                        name="companyAddress"
                        type="text"
                        className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Company Address"
                      />
                      <ErrorTest errors={errors} field="companyAddress" />
                    </div>
                  </div>

                  <div className="toGroup space-y-4 pt-5">
                    <label className="text-neutral-900 font-semibold">
                      Name and address of the employee:{" "}
                    </label>
                    <div>
                      <input
                        {...register("employeeName", {
                          required: {
                            value: true,
                            message: "Employee name is required",
                          },
                        })}
                        id="employeeName"
                        name="employeeName"
                        type="text"
                        className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Employee's Name"
                      />
                      <ErrorTest errors={errors} field="employeeName" />
                    </div>
                    <div>
                      <input
                        {...register("employeeAddress", {
                          required: {
                            value: true,
                            message: "Address is required",
                          },
                        })}
                        id="employeeAddress"
                        name="employeeAddress"
                        type="text"
                        className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Employee's Address"
                      />
                      <ErrorTest errors={errors} field="employeeAddress" />
                    </div>
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Type of employment:
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("employeeType")}
                      type="radio"
                      value="FullTime"
                      id="employeeType"
                    />
                    {"  "}
                    Full-time
                  </div>

                  <div className="mt-2">
                    <input
                      {...register("employeeType")}
                      type="radio"
                      value="PartTime"
                      id="employeeType"
                    />
                    {"  "}
                    Part-time
                  </div>

                  <Button
                    onClick={async () => {
                      const result = await trigger();
                      if (result) {
                        setStep(2);
                      }
                    }}
                    type="button"
                    className="text-white rounded my-4"
                  >
                    {" "}
                    Continue
                  </Button>
                </>
              )}

              {step === 2 && (
                <>
                  <div>
                    <label className="text-neutral-900 font-semibold">
                      Joining date for the employee:{" "}
                    </label>
                    <input
                      className="mt-4 relative block appearance-none rounded border border-neutral-500 px-3 py-1 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      type="date"
                      id="joining_date"
                      name="joining_date"
                      //   onKeyDown={(event) => {
                      //     event.preventDefault();
                      //   }}
                      {...register("joining_date", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                      // max={new Date().toISOString().split("T")[0]}
                    />
                    <ErrorTest errors={errors} field="joining_date" />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Employee's job title:{" "}
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("employeeTitle", {
                        required: {
                          value: true,
                          message: "Employee's title is required",
                        },
                      })}
                      id="employeeTitle"
                      name="employeeTitle"
                      type="text"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Job Title. Ex: Sales Manager, Software Developer, etc"
                    />
                    <ErrorTest errors={errors} field="employeeTitle" />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      New employee's probabtionary period:{" "}
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("probabtionTime", {
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                      id="probabtionTime"
                      name="probabtionTime"
                      type="text"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Ex: 3 months, 8 weeks, etc"
                    />
                    <ErrorTest errors={errors} field="probabtionTime" />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Notice period (in days) in case of termination by employer
                      during the probabation period:{" "}
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("employerNoticePeriod", {
                        required: {
                          value: true,
                          message: "Notice period is required",
                        },
                      })}
                      id="employerNoticePeriod"
                      name="employerNoticePeriod"
                      type="number"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Notice period (in days). Ex: 7, 14, 30, etc"
                    />
                    <ErrorTest errors={errors} field="employerNoticePeriod" />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Notice period (in days) in case of resignation by employee
                      during the probabtion period:{" "}
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("employeeNoticePeriod", {
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                      id="employeeNoticePeriod"
                      name="employeeNoticePeriod"
                      type="number"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Notice period (in days). Ex: 7, 14, 30, etc"
                    />
                    <ErrorTest errors={errors} field="employeeNoticePeriod" />
                  </div>

                  <div className="flex gap-4 my-4">
                    <div
                      onClick={() => setStep(1)}
                      className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                        />
                      </svg>

                      <span>Back</span>
                    </div>
                    <div
                      onClick={async () => {
                        const result = await trigger();
                        if (result) {
                          setStep(3);
                        }
                      }}
                      className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                    >
                      <span>Continue</span>
                    </div>
                  </div>
                </>
              )}

              {step === 3 && (
                <>
                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Employee's annual salary (CTC):{" "}
                    </label>
                    <div className="mt-3 flex items-center gap-4">
                      <select
                        {...register(`currency`)}
                        id={`currency`}
                        name={`currency`}
                        className="relative block w-full max-w-[125px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="" disabled selected hidden>
                          Currency{" "}
                        </option>
                        <option value="INR">INR</option>
                        <option value="USD">USD</option>
                      </select>

                      <input
                        {...register("amount")}
                        id="amount"
                        name="amount"
                        type="text"
                        className="relative block w-full max-w-[360px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Amount"
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      By which day of the subsequent month will the salary be
                      paid for the previous month?{" "}
                    </label>
                    <select
                      {...register(`period`, {
                        required: {
                          value: true,
                          message: "Please select Lock-in period",
                        },
                      })}
                      id={`period`}
                      name={`period`}
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 mt-3 text-gray-900 placeholder-gray-500 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="" disabled selected hidden>
                        Day of the Subsequent Month{" "}
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                    <ErrorTest errors={errors} field="period" />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Joining bonus to be given to the employee:
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("joiningBonus", {
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                      id="joiningBonus"
                      name="joiningBonus"
                      type="text"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Amount"
                    />
                    <ErrorTest errors={errors} field="joiningBonus" />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      After how many days/weeks/months of the joining date will
                      the joining bonus be paid?
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("joiningDateBonus", {
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                      id="joiningDateBonus"
                      name="joiningDateBonus"
                      type="text"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Ex: 3 months, 8 weeks, etc"
                    />
                    <ErrorTest errors={errors} field="joiningDateBonus" />
                  </div>

                  <div className="mt-4">
                    <label
                      htmlFor="comment"
                      className="text-neutral-900 font-semibold"
                    >
                      Employee's job duties:
                    </label>
                    <div className="mt-2">
                      <textarea
                        {...register("comment", {
                          required: {
                            value: true,
                            message: "This is required",
                          },
                        })}
                        rows={4}
                        name="comment"
                        id="comment"
                        className="block w-full rounded-md border-0 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-500 placeholder:text-neutral-900 focus:ring-inset focus:ring-indigo-500 sm:py-1.5 sm:text-sm sm:leading-6"
                        defaultValue={""}
                        placeholder={`1. First responsibility
2. Second responsibility
and so on...
                        `}
                      />
                    </div>
                    <ErrorTest errors={errors} field="comment" />
                  </div>

                  <div className="flex gap-4 my-4">
                    <div
                      onClick={() => setStep(2)}
                      className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                        />
                      </svg>

                      <span>Back</span>
                    </div>
                    <div
                      onClick={async () => {
                        const result = await trigger();
                        if (result) {
                          setStep(4);
                        }
                      }}
                      className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                    >
                      <span>Continue</span>
                    </div>
                  </div>
                </>
              )}

              {step === 4 && (
                <>
                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Office address from where the employee would be working:{" "}
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("officeAddress", {
                        required: {
                          value: true,
                          message: "Office Address is required",
                        },
                      })}
                      id="officeAddress"
                      name="officeAddress"
                      type="text"
                      className="relative block w-full  appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Office address"
                    />
                    <ErrorTest errors={errors} field="officeAddress" />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Hours per week that the employee will be required to work:{" "}
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("hourPerWeek", {
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                      id="hourPerWeek"
                      name="hourPerWeek"
                      type="number"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Hours per week (number). Ex: 36, 40, 45, etc."
                    />
                    <ErrorTest errors={errors} field="hourPerWeek" />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Enter the time period post termination for which the
                      employees should be restricted from sharing any
                      confidential information of the company:
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("timePeriodTermination", {
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                      id="timePeriodTermination"
                      name="timePeriodTermination"
                      type="text"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Ex: 1 year, 2 year, 6 months"
                    />
                    <ErrorTest errors={errors} field="timePeriodTermination" />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Notice period to be given by the employer for termination
                      of employee or by the employee in case of resignation:
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("noticePeriodResignation", {
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                      id="noticePeriodResignation"
                      name="noticePeriodResignation"
                      type="text"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Ex: 2 weeks, 1 month, 2 months"
                    />
                    <ErrorTest
                      errors={errors}
                      field="noticePeriodResignation"
                    />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Restrain period for the employee to not work with a
                      competitor after the employee leaves the company:{" "}
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("restrainPeriod", {
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                      id="restrainPeriod"
                      name="restrainPeriod"
                      type="text"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Ex: 2 weeks, 1 month, 2 months"
                    />
                    <ErrorTest errors={errors} field="restrainPeriod" />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Period of restriction regarding non-solicitation (for
                      instance, not soliciting customers) to be imposed after
                      employee leaves the company:{" "}
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("restrictionPeriod", {
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                      id="restrictionPeriod"
                      name="restrictionPeriod"
                      type="text"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Ex: 2 weeks, 1 month, 2 months"
                    />
                    <ErrorTest errors={errors} field="restrictionPeriod" />
                  </div>

                  <div className="flex gap-4 my-4">
                    <div
                      onClick={() => setStep(3)}
                      className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                        />
                      </svg>

                      <span>Back</span>
                    </div>
                    <div
                      onClick={async () => {
                        const result = await trigger();
                        if (result) {
                          setStep(5);
                        }
                      }}
                      className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                    >
                      <span>Continue</span>
                    </div>
                  </div>
                </>
              )}

              {step === 5 && (
                <>
                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Courts of this state have the jurisdiction over any
                      dispute arising out of this Agreement:
                    </label>
                    <select
                      {...register(`state`, {
                        required: {
                          value: true,
                          message: "Please select state",
                        },
                      })}
                      id={`state`}
                      name={`state`}
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 mt-3 text-gray-900 placeholder-gray-500 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="" disabled selected hidden>
                        State{" "}
                      </option>
                      {/* <option value="Rajasthan">Rajasthan</option>
                      <option value="Maharastra">Maharastra</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujrat">Gujrat</option> */}
                      {stateUT.map((item, index) => (
                        <option value={item.label}>{item.label}</option>
                      ))}
                    </select>
                    <ErrorTest errors={errors} field="state" />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Date on which the employment agreement will be signed by
                      the employer:{" "}
                    </label>
                    <input
                      className="mt-4 relative block appearance-none rounded border border-neutral-500 px-3 py-1 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      type="date"
                      id="date_format_employerAgreement"
                      name="date_format_employerAgreement"
                      //   onKeyDown={(event) => {
                      //     event.preventDefault();
                      //   }}
                      {...register("date_format_employerAgreement", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                      // max={new Date().toISOString().split("T")[0]}
                    />
                    <ErrorTest
                      errors={errors}
                      field="date_format_employerAgreement"
                    />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Date on which the employment agreement will be signed by
                      the employee:{" "}
                    </label>
                    <input
                      className="mt-4 relative block appearance-none rounded border border-neutral-500 px-3 py-1 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      type="date"
                      id="date_format_employeeAgreement"
                      name="date_format_employeeAgreement"
                      //   onKeyDown={(event) => {
                      //     event.preventDefault();
                      //   }}
                      {...register("date_format_employeeAgreement", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                      // max={new Date().toISOString().split("T")[0]}
                    />
                    <ErrorTest
                      errors={errors}
                      field="date_format_employeeAgreement"
                    />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Name of the person signing the agreement on behalf of the
                      company:
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("fullName", {
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                      id="fullName"
                      name="fullName"
                      type="text"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Full Name"
                    />
                    <ErrorTest errors={errors} field="fullName" />
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Designation of the above person in the company:
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("designation", {
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                      id="designation"
                      name="designation"
                      type="text"
                      className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Designation"
                    />
                    <ErrorTest errors={errors} field="designation" />
                  </div>

                  <div className="flex gap-4 items-center">
                    <div
                      onClick={() => {
                        setStep(4);
                      }}
                      className="cursor-pointer px-3 py-2 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                        />
                      </svg>

                      <span>Back</span>
                    </div>
                    <Button
                      //   onClick={async () => {
                      //     const result = await trigger();
                      //     if (result) {
                      //       setStep(4);
                      //     }
                      //   }}
                      type="submit"
                      className="text-white rounded my-4"
                    >
                      {" "}
                      Generate Document
                    </Button>
                  </div>
                </>
              )}
            </form>
          </FormProvider>
        </div>
      </div>
      <DownloadPDFWorkModal
        open={showCopyDownloadModal}
        closeModal={setShowCopyDownloadModal}
        updatedObj={formData}
        docType="employment-agreement"
        reset={reset}
      />
      <DownloadTemplateModal
        open={showDownloadTemplate}
        closeModal={setShowDownloadTemplate}
        templateCode="employment-agreement"
      />
    </>
  );
}
