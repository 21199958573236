import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "./index.scss";
import { useAuth, UserActions } from "src/context/AuthProvider";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";
import FullScreenLoader from "src/components/FullScreenLoader";
import { getCurrentFinancialYear } from "src/services/helpers/getCurrentFinancialYear";

export default function Companies() {
  const {
    state: authData,
    fetchTokenManually,
    logout,
    isLoading,
    idToken,
    dispatch,
  } = useAuth();
  const { acceptInvite, getAllCompanies, getUserDetails } =
    useAuthOnBoardFlow();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [companyData, setCompanyData] = useState(state);

  // Instead of getting data from state, we think we have to fetch companies data from here by
  // calling api in useeffect

  const onSignOut = async () => {
    try {
      await logout();
      localStorage.clear();
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (companyDetails) => {
    window.localStorage.setItem("companyId", companyDetails?.id);
    acceptInvite(idToken, companyDetails.id)
      .then((res) => {
        console.log(res);
        navigate("/companies/invited", { state: companyDetails });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClick2 = (companyDetails) => {
    window.localStorage.setItem("companyId", companyDetails?.id);
    // Commenting as we disable bankaccount for now and our / is changed from bankaccount to companies page
    // navigate("/", { state: companyDetails?.id });
    // Commneting as client dont need invoice page for now
    // navigate(`/invoice/${getCurrentFinancialYear()}`, {
    //   state: companyDetails?.id,
    // });
    navigate(`/formationDiligence`, {
      state: companyDetails?.id,
    });
  };

  useEffect(() => {
    if (idToken) {
      const fetchCompany = async () => {
        const updatedToken = await fetchTokenManually();
        const token = updatedToken || authData?.idTokenUpdated || idToken;
        getAllCompanies(token)
          .then((res) => {
            setCompanyData(res);
          })
          .catch((err) => {
            console.log(err);
          });
      };

      fetchCompany();
    }
  }, [idToken]);

  useEffect(() => {
    if (idToken) {
      const fetchUser = async () => {
        const updatedToken = await fetchTokenManually();
        const token = updatedToken || authData?.idTokenUpdated || idToken;
        if (token) {
          const userData = await getUserDetails(token);
          dispatch({
            type: UserActions.INTIAL,
            payload: {
              customer: userData,
              isAuthenticated: true,
              companyClicked: false,
            },
          });
        }
      };

      fetchUser();
    }
  }, []);

  useEffect(() => {
    const isUser = window.localStorage.getItem("user");
    if (!isUser) {
      navigate("/login");
    }
  }, []);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <div className="section relative w-full h-full">
      <div className="content h-1/2 bg-[#323842ff] mb-[-150px]">
        <h2 className="text-center text-3xl text-white pt-20">
          {companyData
            ? companyData?.welcomeText
            : `You don't have any company associated with you`}
        </h2>
      </div>

      {companyData?.companies.length > 0 ? (
        <>
          <div className="flex flex-wrap justify-center gap-[50px] pb-4">
            <div
              onClick={() => navigate("/companies/create")}
              className="cursor-pointer flex flex-col items-center justify-center gap-4 h-[289px] w-[299px] bg-white hover:bg-primary-100 border border-neutral-400 shadow-m rounded-lg"
            >
              <div className="flex items-center justify-center h-[100px] w-[100px] rounded-full border-2 border-primary-500">
                <img alt="add" src="/icons/Plus.png" />
              </div>
              <p className="text-neutral-900 text-xl">Create New</p>
            </div>
            {companyData?.companies.map((item) =>
              item?.userInviteStatus !== "ACCEPTED" ? (
                <div
                  onClick={() => handleClick(item)}
                  className="box-content sm:box-border cursor-pointer flex flex-col justify-center items-center h-[289px] w-[299px] bg-white hover:bg-primary-100 border border-neutral-400 shadow-m rounded-lg"
                >
                  <div className="flex flex-col justify-center gap-4 items-center h-[85%]">
                    {item.logoUrl ? (
                      <div className="flex items-center justify-center h-[100px] w-[100px]">
                        <img
                          className="w-auto h-full "
                          src={item.logoUrl}
                          alt={item.name}
                        />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center h-[100px] w-[100px] bg-tertiary4-500 rounded-full border-2 border-primary-500">
                        <span className="text-white font-semibold text-2xl">
                          {item.logoUrl || item.name.slice(0, 1)}
                        </span>
                      </div>
                    )}
                    <p className="text-center">{item.name}</p>
                  </div>
                  {item?.userInviteStatus && (
                    <p className="rounded-b-lg h-auto bg-neutral-300 block w-full p-[10px] text-center">
                      You've been invited
                    </p>
                  )}
                </div>
              ) : (
                <div
                  onClick={() => handleClick2(item)}
                  className="cursor-pointer flex flex-col justify-center items-center gap-4 h-[289px] w-[299px] bg-white hover:bg-primary-100 border border-neutral-400 shadow-m rounded-lg"
                >
                  {item.logoUrl ? (
                    <div className="flex items-center justify-center h-[100px] w-[100px]">
                      <img
                        className="w-auto h-full "
                        src={item.logoUrl}
                        alt={item.name}
                      />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center h-[100px] w-[100px] bg-tertiary4-500 rounded-full border-2 border-primary-500">
                      <span className="text-white font-semibold text-2xl">
                        {item.logoUrl || item.name.slice(0, 1)}
                      </span>
                    </div>
                  )}

                  <p className="text-center">{item.name}</p>
                </div>
              )
            )}
          </div>
          <div className="py-6 text-center text-base text-neutral-900">
            Not seeing your company?{" "}
            <span
              onClick={onSignOut}
              className="cursor-pointer text-tertiary4-500"
            >
              Try using a different email address
            </span>
          </div>
        </>
      ) : (
        <>
          <div className=" flex items-center justify-center pb-12">
            <div
              onClick={() => navigate("/companies/create")}
              className="cursor-pointer flex flex-col items-center justify-center gap-4 h-[289px] w-[299px] bg-white hover:bg-primary-100 border border-neutral-400 shadow-m rounded-lg"
            >
              <div className="flex items-center justify-center h-[100px] w-[100px] rounded-full border-2 border-primary-500">
                <img alt="add" src="/icons/Plus.png" />
              </div>
              <p className="text-neutral-900 text-xl">Create New</p>
            </div>
          </div>
          <div className="py-6 text-center text-base text-neutral-900">
            Not seeing your company?{" "}
            <span
              onClick={onSignOut}
              className="cursor-pointer text-tertiary4-500"
            >
              Try using a different email address
            </span>
          </div>
        </>
      )}
    </div>
  );
}
