import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useAuth } from "src/context/AuthProvider";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";
import { toast, ToastContainer } from "react-toastify";
import Button from "src/components/Button";
import { useDocumentData } from "src/context/DocumentProvider";
import { useFormationDocuments } from "src/hooks/useFormationDocuments";
import { useCompanyData } from "src/context/CompanyDataProvider";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function ChooseActionModal({
  open,
  closeModal,
  setShowShareModal,
  setShowDeleteModal,
}): any {
  const [loading, setLoading] = useState(false);
  const { idToken, state: UserData, reloadUserData } = useAuth();
  const {
    state: docData,
    selectedValues,
    setSelectedValues,
    selectChooseAction,
  } = useDocumentData();
  const { createShareLink } = useFormationDocuments();
  const { state: CompanyData } = useCompanyData();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = () => {};

  const handleDeleteAction = () => {
    selectChooseAction("delete");
    closeModal(false);
    setShowDeleteModal(true);
  };

  const handleShareAction = () => {
    selectChooseAction("share");
    closeModal(false);
    setShowShareModal(true);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[12]" onClose={() => null}>
          {/* <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden"> */}
          <div className=" inset-0 overflow-hidden">
            <div className=" inset-0 overflow-hidden">
              <div className="fixed top-[76px] inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white pb-6 shadow-xl">
                      <div className="border-b border-neutral-400 px-4 sm:px-6">
                        <div className="flex items-center justify-between w-full h-[72px]">
                          <Dialog.Title className="text-xl font-medium text-gray-900">
                            Choose Action
                          </Dialog.Title>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                              onClick={() => {
                                closeModal(false);
                                reset();
                                document.body.style.overflow = "auto";
                              }}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-5 flex-1 px-4 sm:px-6">
                        <div className="absolute inset-0 px-4 sm:px-6">
                          <div className="flex justify-between">
                            <div
                              onClick={handleDeleteAction}
                              // onClick={() => {
                              //   closeModal(false);
                              //   setShowDeleteModal(true);
                              // }}
                              className="cursor-pointer px-14 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-4 h-4 text-white"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                              <span>Delete</span>
                            </div>
                            <div
                              onClick={handleShareAction}
                              // onClick={() => {
                              //   closeModal(false);
                              //   setShowShareModal(true);
                              // }}
                              className="cursor-pointer px-14 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#ffffff"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4 text-white"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                                />
                              </svg>
                              <span>Share</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
}
