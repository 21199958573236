import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import Button from "src/components/Button";
import { stateUT } from "src/services/helpers/stateUT";
import DownloadPDFWorkModal from "../DownloadPDFWordModal";
import DownloadTemplateModal from "../DownloadTemplateModal";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function PrivacyPolicy() {
  const [formData, setFormData] = useState({});
  const [showCopyDownloadModal, setShowCopyDownloadModal] = useState(false);
  const [showDownloadTemplate, setShowDownloadTemplate] = useState(false);
  const [step, setStep] = useState(1);

  const navigate = useNavigate();
  const { docName } = useParams();
  const { state } = useLocation();

  const methods = useForm({ mode: "onChange" });
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = methods;

  const clientTypeValue = watch("clientType");
  const clientPaid = watch("clientPaid");

  const onSubmit = (data) => {
    console.log(data);
    const updatedObj = {
      entity_address: data.companyAddress || "",
      entity_name: data.companyName || "",
      contact_email: data.email || "",
      personal_info: data.personalInfo || "",
      effective_date: data.date_format || "",
      web_url: data.websiteURL || "",
      app_name: data.appName || "",
    };
    setFormData((prev) => ({ ...prev, updatedObj }));
    setShowCopyDownloadModal(true);
  };

  return (
    <>
      <div className="consultingAgree">
        <div className="flex items-center justify-between border-b border-neutral-400 gap-4 pl-[30px] pr-5">
          <div className="flex items-center gap-6">
            <span className="text-xl text-neutral-900 font-semibold inline-block py-5">
              Generate {state?.documentName || "Consulting Agreement"}
            </span>
          </div>
          <div className="flex gap-4">
            <div
              onClick={() => setShowDownloadTemplate(!showDownloadTemplate)}
              className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>

              <span>Download Template</span>
            </div>
            <div
              onClick={() => navigate("/documentGeneration/requestCustom")}
              // onClick={() => setShowShareModal(!showShareModal)}
              className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>

              <span>Request Custom</span>
            </div>
          </div>
        </div>
        <div className="activeFromComp ml-[30px] mr-5 mt-6">
          <div className="flex justify-between">
            <span className="text-neutral-900">
              Enter below details to generate an automatically edited Privacy
              Policy for you!{" "}
            </span>
            <span>{`Page 1/1`}</span>
          </div>
          <FormProvider {...methods}>
            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
              {step === 1 && (
                <>
                  <div>
                    <label className="text-neutral-900 font-semibold">
                      Date on which the Privacy Policy comes into effect:{" "}
                    </label>
                    <input
                      className="my-4 relative block appearance-none rounded border border-neutral-500 px-3 py-1 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      type="date"
                      id="date_format"
                      name="date_format"
                      {...register("date_format", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                      // max={new Date().toISOString().split("T")[0]}
                    />
                    <ErrorTest errors={errors} field="date_format" />
                  </div>

                  <div className="toGroup space-y-4">
                    <label className="text-neutral-900 font-semibold">
                      Name and address of the company/entity/person operating:{" "}
                    </label>
                    <div>
                      <input
                        {...register("companyName", {
                          required: {
                            value: true,
                            message: "Name is required",
                          },
                          minLength: {
                            value: 3,
                            message:
                              "Name should be between 3 to 50 characters",
                          },
                          maxLength: {
                            value: 50,
                            message:
                              "Name should be between 3 to 50 characters",
                          },
                        })}
                        id="companyName"
                        name="companyName"
                        type="text"
                        className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Name"
                      />
                      <ErrorTest errors={errors} field="companyName" />
                    </div>
                    <div>
                      <input
                        {...register("companyAddress", {
                          required: {
                            value: true,
                            message: "Address is required",
                          },
                        })}
                        id="companyAddress"
                        name="companyAddress"
                        type="text"
                        className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Address"
                      />
                      <ErrorTest errors={errors} field="companyAddress" />
                    </div>
                  </div>

                  <div className="mt-4">
                    <label className="text-neutral-900 font-semibold">
                      Generate it to be used for:
                    </label>
                  </div>

                  <div className="mt-4">
                    <input
                      {...register("clientType")}
                      type="radio"
                      value="privacy-policy-website"
                      id="clientType"
                    />
                    {"  "}
                    Website
                  </div>

                  <div className="mt-2">
                    <input
                      {...register("clientType")}
                      type="radio"
                      value="privacy-policy-mobile"
                      id="clientType"
                    />
                    {"  "}
                    Mobile Application
                  </div>

                  {clientTypeValue === "privacy-policy-website" && (
                    <>
                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Website URL:{" "}
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("websiteURL", {
                            required: {
                              value: true,
                              message: "URL is required",
                            },
                          })}
                          id="websiteURL"
                          name="websiteURL"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Website URL"
                        />
                        <ErrorTest errors={errors} field="websiteURL" />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Personal information collected when a user signs up or
                          uses the service (separated by commas):{" "}
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("personalInfo", {
                            required: {
                              value: true,
                              message: "This is required",
                            },
                          })}
                          id="personalInfo"
                          name="personalInfo"
                          type="text"
                          className="relative block w-full  appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Ex: Name, Phone number, Address, Email, Location and so on"
                        />
                        <ErrorTest errors={errors} field="personalInfo" />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Email for contact:{" "}
                        </label>
                      </div>
                      <div className="mt-4">
                        <input
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Email is required",
                            },
                          })}
                          id="email"
                          name="email"
                          type="email"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Email"
                        />
                        <ErrorTest errors={errors} field="email" />
                      </div>
                      <Button
                        //   onClick={async () => {
                        //     const result = await trigger();
                        //     if (result) {
                        //       setStep(4);
                        //     }
                        //   }}
                        type="submit"
                        className="text-white rounded my-4"
                      >
                        {" "}
                        Generate Document
                      </Button>
                    </>
                  )}

                  {clientTypeValue === "privacy-policy-mobile" && (
                    <>
                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Name of the Mobile Application:{" "}
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("appName", {
                            required: {
                              value: true,
                              message: "This is required",
                            },
                          })}
                          id="appName"
                          name="appName"
                          type="text"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="App Name"
                        />
                        <ErrorTest errors={errors} field="appName" />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Personal information collected when a user signs up or
                          uses the service (separated by commas):{" "}
                        </label>
                      </div>

                      <div className="mt-4">
                        <input
                          {...register("personalInfo", {
                            required: {
                              value: true,
                              message: "This is required",
                            },
                          })}
                          id="personalInfo"
                          name="personalInfo"
                          type="text"
                          className="relative block w-full  appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Ex: Name, Phone number, Address, Email, Location and so on"
                        />
                        <ErrorTest errors={errors} field="personalInfo" />
                      </div>

                      <div className="mt-4">
                        <label className="text-neutral-900 font-semibold">
                          Email for contact:{" "}
                        </label>
                      </div>
                      <div className="mt-4">
                        <input
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Email is required",
                            },
                          })}
                          id="email"
                          name="email"
                          type="email"
                          className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Email"
                        />
                        <ErrorTest errors={errors} field="email" />
                      </div>

                      <Button
                        //   onClick={async () => {
                        //     const result = await trigger();
                        //     if (result) {
                        //       setStep(4);
                        //     }
                        //   }}
                        type="submit"
                        className="text-white rounded my-4"
                      >
                        {" "}
                        Generate Document
                      </Button>
                    </>
                  )}
                  <DownloadPDFWorkModal
                    open={showCopyDownloadModal}
                    closeModal={setShowCopyDownloadModal}
                    updatedObj={formData}
                    docType={clientTypeValue}
                    reset={reset}
                  />
                </>
              )}
            </form>
          </FormProvider>
        </div>
      </div>
      <DownloadTemplateModal
        open={showDownloadTemplate}
        closeModal={setShowDownloadTemplate}
        templateCode="privacy-policy"
      />
    </>
  );
}
