import axios from "axios";
import { useState } from "react";

export const useAuthOnBoardFlow = () => {
  const [getData, setData] = useState(null);

  const createCompany = async (
    idToken,
    companyName,
    // brandName,
    numberOfEmployees,
    country,
    companyType
  ) => {
    const body = {
      companyName,
      // brandName,
      numberOfEmployees,
      country,
      companyType,
    };

    console.log("createUser idToken", idToken);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/company/`,
        body,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      if (error) {
        throw error;
      }
    }
  };

  const getCompanyRelatedData = async (idToken, companyId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/company/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      console.log("error", error);
      if (
        error?.response?.status === 404 &&
        error?.response?.data?.message === "Company not found"
      ) {
        throw error?.response?.data?.message;
      }
    }
  };

  const getAllCompanies = async (idToken) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/company/all`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await res.data;
      return data;
    } catch (error: any) {
      console.log("error in get companies", error);
      if (
        error?.response?.status === 403 &&
        error?.response?.data?.message === "Signup incomplete"
      ) {
        return error?.response?.data?.message;
      }
    }
  };

  const sendInvite = async (idToken, companyId, inviteObj) => {
    // TODO
    // const body = {
    //   "a@gmail.com": "Owner",
    //   "ab@gmail.com": "Member",
    // };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/company/${companyId}/send-invite`,
        inviteObj,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const acceptInvite = async (idToken, companyId) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/company/${companyId}/accept-invite`,
        {},
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const createUser = async (idToken, firstname, lastname) => {
    const body = {
      firstName: firstname,
      lastName: lastname,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/user`,
        body,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };

  const getUserDetails = async (idToken) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/user`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await res.data;
      return data;
    } catch (error: any) {
      console.log("error in get user", error);
    }
  };

  const editUser = async (firstname, lastname, idToken) => {
    try {
      const userEditRes = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/user`,
        {
          firstName: firstname,
          lastName: lastname,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (userEditRes) {
        const userData = await getUserDetails(idToken);
        return userData;
      }
    } catch (error) {
      console.log("error in get user", error);
      throw error;
    }
  };

  return {
    createUser,
    getUserDetails,
    createCompany,
    getCompanyRelatedData,
    getAllCompanies,
    sendInvite,
    acceptInvite,
    editUser,
  };
};
