import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { useSettings } from "src/hooks/useSettings";
import { useAuth } from "src/context/AuthProvider";
import { useNavigate } from "react-router";
import Button from "../Button";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function DeleteCompanyModal({ open, closeModal, companyData }) {
  const [loading, setLoading] = useState(false);

  const { deleteCompany } = useSettings();
  const { idToken } = useAuth();

  const navigate = useNavigate();
  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    setLoading(true);
    if (data.companyName === companyData?.name) {
      deleteCompany(idToken, companyData?.id)
        .then((res) => {
          console.log(res);
          setLoading(false);
          toast.success("Company deleted successfully!!", {
            className: "toast-position",
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // Commenting as we disable bankaccount for now and our / is changed from bankaccount to companies page
          // navigate("/companies");
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error(
            err?.response?.data?.message ||
              "Something went wrong!! Please try again",
            {
              className: "toast-position",
              position: "bottom-center",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          // Commenting as we disable bankaccount for now and our / is changed from bankaccount to companies page

          // navigate("/companies");
          navigate("/");
        });
    } else {
      setLoading(false);
      toast.info("Company Name doesn't match", {
        className: "toast-position",
        position: "bottom-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[12]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
                      onClick={() => closeModal(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex flex-col sm:items-start pb-4 border-b border-neutral-400">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="font-medium leading-6 text-gray-900"
                      >
                        Delete Company?
                      </Dialog.Title>
                    </div>
                  </div>
                  <div>
                    <p className="mt-3 text-neutral-900 text-xl">
                      Enter company name "{companyData?.name}" to delete!
                    </p>
                    <p className="mt-4 mb-2 text-neutral-900 font-semibold text-base">
                      Things to know:
                    </p>
                    <p className="text-neutral-900">
                      - This is permanent and can't be undone.
                    </p>
                    <p className="text-neutral-900">
                      - Admins, managers and members would no longer be able to
                      log in
                    </p>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-full mt-4 mb-2">
                      <label
                        htmlFor="companyName"
                        className="mt-4 mb-2 text-neutral-900 font-semibold text-sm"
                      >
                        Enter the company name to delete
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="companyName"
                          id="companyName"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          placeholder="Company Name"
                          {...register("companyName", {
                            required: {
                              value: true,
                              message: "Please enter company name to delete",
                            },
                          })}
                        />
                        <ErrorTest errors={errors} field="companyName" />
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 gap-4 ">
                      {/* <button
                        type="submit"
                        className="inline-flex justify-center rounded border border-transparent bg-tertiary4-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-550 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
                      >
                        Delete Company
                      </button> */}
                      <Button
                        disabled={loading}
                        loading={loading}
                        className="inline-flex w-full justify-center rounded-md text-base font-medium text-white focus:outline-none sm:w-auto"
                      >
                        Delete Company
                      </Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
}
