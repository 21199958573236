import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate, redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Button from "src/components/Button";
import ErrorPage from "src/components/ErrorPage";
import FullScreenLoader from "src/components/FullScreenLoader";
import { useAuth } from "src/context/AuthProvider";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function ProfileDetails() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    state: authData,
    fetchTokenManually,
    user,
    idToken,
    isLoading,
  } = useAuth();
  const { createUser } = useAuthOnBoardFlow();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  // const onSubmit = useCallback(
  //   async (data) => {
  //     setLoading(true);
  //     console.log("user on submit profile details", user);
  //     console.log("idToken on submit profile details", idToken);
  //     console.log("authData submit", authData);
  //     const updatedToken = await fetchTokenManually();
  //     console.log(
  //       "updatedToken after calling fetchTokenManually",
  //       updatedToken
  //     );
  //     console.log("authData after manuallytoken refetch", authData);
  //     console.log("idToken after calling fetchTokenManually", idToken);
  //   },
  //   [user, idToken]
  // );

  const onSubmit = async (data) => {
    setLoading(true);
    // console.log("user on submit profile details", user);
    // console.log("idToken on submit profile details", idToken);
    // const updatedToken = await fetchTokenManually();
    // const token = updatedToken || authData?.idTokenUpdated || idToken;
    // console.log("updatedToken after calling fetchTokenManually", updatedToken);
    // console.log("idToken after calling fetchTokenManually", idToken);

    createUser(idToken, data.firstname, data.lastname)
      .then((result) => {
        if (result === "Created") {
          setLoading(false);
          // Commenting as we disable bankaccount for now and our / is changed from bankaccount to companies page
          // navigate("/companies");
          navigate("/");
        } else {
          console.log("result", result);
          setLoading(false);
          toast.error("Something went wrong!! Please try again", {
            className: "toast-position",
            position: "bottom-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log("err in createuser", err);
        setLoading(false);
        toast.error(
          err?.response?.data?.message ||
            "Something went wrong!! Please try again",
          {
            className: "toast-position",
            position: "bottom-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      });
  };

  useEffect(() => {
    const isUser = window.localStorage.getItem("user");
    if (!isUser) {
      navigate("/login");
    }
  }, []);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <img
        className="companyLogo absolute top-5 left-5 mx-auto w-auto h-8"
        src="/logo/cashwise.png"
        alt="Your Company"
      />
      <div className="hor-ver-center">
        <p className="text-center text-neutral-900">
          Please enter your name and continue
        </p>
        <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-6 pt-4">
            <div className="flex flex-start content-center gap-6 -space-y-px rounded-md ">
              <div>
                <input
                  id="firstname"
                  type="text"
                  className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="First Name"
                  {...register("firstname", {
                    required: {
                      value: true,
                      message: "First name is required",
                    },
                    maxLength: {
                      value: 15,
                      message:
                        "First name length should be between 1 to 15 characters",
                    },
                  })}
                />
                <ErrorTest errors={errors} field="firstname" />
              </div>

              <div>
                <input
                  id="lastname"
                  type="lastname"
                  className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Last Name"
                  {...register("lastname", {
                    required: {
                      value: true,
                      message: "Last name is required",
                    },
                    maxLength: {
                      value: 15,
                      message:
                        "Last name length should be between 1 to 15 characters",
                    },
                  })}
                />
                <ErrorTest errors={errors} field="lastname" />
              </div>
            </div>
            <div>
              {/* <button
                type="submit"
                className="group relative flex w-full justify-center rounded border border-transparent bg-tertiary4-500 py-2 px-4 text-sm font-medium text-white hover:bg-primary-550"
              >
                Continue
              </button> */}
              <Button
                disabled={loading}
                loading={loading}
                className="inline-flex w-full justify-center rounded-md text-base font-medium text-white focus:outline-none"
              >
                Continue
              </Button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}
