import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useAuth } from "src/context/AuthProvider";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";
import { toast, ToastContainer } from "react-toastify";
import Button from "src/components/Button";
import { useDocumentData } from "src/context/DocumentProvider";
import { useFormationDocuments } from "src/hooks/useFormationDocuments";
import { useCompanyData } from "src/context/CompanyDataProvider";
import { useComplianceDocuments } from "src/hooks/useComplianceDocuments";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function DeleteLinkComplianceModal({ open, closeModal }) {
  const [loading, setLoading] = useState(false);
  const {
    state: authData,
    fetchTokenManually,
    idToken,
    state: UserData,
    reloadUserData,
  } = useAuth();
  const {
    state: docData,
    selectedValues,
    setSelectedValues,
    selectChooseAction,
    reloadDocumentData,
    reloadComplainceDocumentData,
  } = useDocumentData();
  const { createShareLink, deleteSelectedDocuments, getFormationDocs }: any =
    useFormationDocuments();
  const { renameComplianceFile, getComplianceDocs } = useComplianceDocuments();
  const { state: CompanyData } = useCompanyData();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = () => {};

  const handleSelectedDocuments = async () => {
    const arrRecordIds = selectedValues?.map((item) => item.id);
    console.log("arrRecordIds", arrRecordIds);

    setLoading(true);
    const updatedToken = await fetchTokenManually();
    const token = updatedToken || authData?.idTokenUpdated || idToken;
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");

    try {
      const res: any = await deleteSelectedDocuments(
        companyId,
        token,
        arrRecordIds
      );
      if (res) {
        console.log("res link", res);
        const allComplianceDocs = await getComplianceDocs(token, companyId);
        reloadComplainceDocumentData(allComplianceDocs);

        const allFormationDocs = await getFormationDocs(token, companyId);
        reloadDocumentData(allFormationDocs);

        setLoading(false);
        closeModal(false);
        setSelectedValues([]);
        selectChooseAction("");
        toast.success("Selected row deleted", {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      selectChooseAction("");
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong!! Please try again later",
        {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      setLoading(false);
      closeModal(false);
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[12]" onClose={() => null}>
          {/* <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child> */}
          {/* <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden"> */}
          <div className=" inset-0 overflow-hidden">
            <div className=" inset-0 overflow-hidden">
              <div className="fixed top-[76px] inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white pb-6 shadow-xl">
                      <div className="border-b border-neutral-400 px-4 sm:px-6">
                        <div className="flex items-center justify-between w-full h-[72px]">
                          <Dialog.Title className="text-xl font-medium text-gray-900">
                            Delete
                          </Dialog.Title>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                              onClick={() => {
                                closeModal(false);
                                reset();
                                document.body.style.overflow = "auto";
                              }}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-5 flex-1 px-4 sm:px-6">
                        <div className="absolute inset-0 px-4 sm:px-6">
                          {/* <Button
                            btnType="secondary"
                            className="mb-4 text-tertiary4-500 rounded"
                          >
                            Delete All Empty
                          </Button> */}
                          <form onSubmit={handleSubmit(onSubmit)}>
                            {selectedValues?.length > 0 ? (
                              <div>
                                <p className="text-base text-neutral-900 font-medium">
                                  Delete Selected Documents
                                </p>
                                <p className="text-sm text-neutral-900 my-4">
                                  {" "}
                                  All the selected rows would be deleted.
                                </p>

                                <ul className="mt-6 space-y-4 list-decimal list-inside">
                                  {selectedValues.map((item, index) => (
                                    <div className="flex items-center">
                                      {/* <div className="border border-neutral-900 mr-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="currentColor"
                                          className="w-4 h-4 font-bold text-neutral-900"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M6 18L18 6M6 6l12 12"
                                          />
                                        </svg>
                                      </div> */}
                                      <li
                                        key={item?.id || index}
                                        className="text-base text-neutral-900"
                                      >
                                        {item?.kind || "refresh"}
                                      </li>
                                    </div>
                                  ))}
                                </ul>
                                <div
                                  onClick={handleSelectedDocuments}
                                  className="cursor-pointer z-10 mt-4 px-3 py-1.5 text-base rounded inline-flex gap-2 items-center justify-center text-white bg-tertiary4-500 hover:bg-primary-550"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-4 h-4 text-white"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                  </svg>
                                  <span>Delete</span>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <p className="text-base text-neutral-900 font-medium">
                                  Delete Selected Files
                                </p>{" "}
                                <p className="mt-4 text-base text-neutral-900">
                                  Select single or multiple document rows to
                                  delete!
                                </p>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
}
