import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { useSettings } from "src/hooks/useSettings";
import { useAuth } from "src/context/AuthProvider";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import { useFormationDocuments } from "src/hooks/useFormationDocuments";
import { useDocumentData } from "src/context/DocumentProvider";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function FundingRoundModal({
  open,
  closeModal,
  setActiveFundingRound,
}): any {
  const [fundingAdded, setFundingAdded] = useState(false);
  const [fundingName, setfundingName] = useState("");
  const [loading, setLoading] = useState(false);
  const { state: authData, fetchTokenManually, idToken } = useAuth();
  const { addFundingRound, getFormationDocs } = useFormationDocuments();
  const { reloadDocumentData } = useDocumentData();

  const navigate = useNavigate();
  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    setfundingName(data.funding_name);

    try {
      const updatedToken = await fetchTokenManually();
      const token = updatedToken || authData?.idTokenUpdated || idToken;
      const companyId = window.localStorage.getItem("companyId");
      const res = await addFundingRound(token, companyId, data.funding_name);
      if (res) {
        const allFormationDocs = await getFormationDocs(token, companyId);
        reloadDocumentData(allFormationDocs);
        reset();
        setLoading(false);
        setActiveFundingRound(data.funding_name);
        setFundingAdded(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      {fundingAdded ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-[12]" onClose={() => null}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="flex flex-col justify-center items-center w-full">
                      <img
                        className="mt-3"
                        alt="wait"
                        src="/icons/FormDill/Timer.png"
                      />
                      <p className="mt-3 text-neutral-900 text-xl">
                        Funding Round added
                      </p>
                      <p className="my-6 text-neutral-900 text-base text-center">
                        "{fundingName}" is added! Start adding your documents
                        corresponding to it.
                      </p>
                      <Button
                        onClick={() => {
                          closeModal(false);
                          setFundingAdded(false);
                        }}
                        type="button"
                        className="flex w-full justify-center rounded text-base font-medium text-white focus:outline-none"
                      >
                        Continue
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-[12]" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[600px] sm:p-6">
                    <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
                        onClick={() => closeModal(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="sm:flex flex-col sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base text-neutral-900"
                        >
                          Select the funding round you want to add
                        </Dialog.Title>
                      </div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="w-full mt-8 mb-2">
                        <div className="mt-1">
                          <input
                            type="text"
                            name="funding_name"
                            id="funding_name"
                            className="block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-base"
                            placeholder="Funding Round Name"
                            {...register("funding_name", {
                              required: {
                                value: true,
                                message: "Please enter the funding round name",
                              },
                            })}
                          />
                          <ErrorTest errors={errors} field="funding_name" />
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 gap-4 ">
                        <Button
                          disabled={loading}
                          loading={loading}
                          className="flex w-full justify-center rounded text-base font-medium text-white focus:outline-none"
                        >
                          Add
                        </Button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
      <ToastContainer />
    </>
  );
}
