import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import Button from "src/components/Button";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

export default function IndividualClientAgree({
  setClientType,
  setHideClientTypeDropdown,
}) {
  const [step, setStep] = useState(1);

  const {
    register,
    watch,
    trigger,
    reset,
    formState: { errors: formErrors },
  } = useFormContext();

  const communicationValue = watch("communication_client");
  console.log("object", communicationValue);

  const handleBack = () => {
    reset();
    setClientType("");
  };

  return (
    <>
      {step === 1 && (
        <div className="mt-4">
          <label className="text-neutral-900 font-semibold">
            Name and address of the client:
          </label>
          <div className="mt-4">
            <input
              {...register("clientName", {
                required: {
                  value: true,
                  message: "Company name is required",
                },
              })}
              id="clientName"
              name="clientName"
              type="text"
              className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              placeholder="Name"
            />
            <ErrorTest errors={formErrors} field="clientName" />
          </div>

          <div className="mt-4">
            <input
              {...register("registeredClientAddress", {
                required: {
                  value: true,
                  message: "Address is required",
                },
              })}
              id="registeredClientAddress"
              name="registeredClientAddress"
              type="text"
              className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              placeholder="Address"
            />
            <ErrorTest errors={formErrors} field="registeredClientAddress" />
          </div>

          <div className="mt-4">
            <label className="text-neutral-900 font-semibold">
              Is client communication address same as above mentioned address?{" "}
            </label>
          </div>

          <div className="mt-4">
            <input
              {...register("communication_client")}
              type="radio"
              value="yes"
              id="communication_client"
            />
            {"  "}
            Yes
          </div>
          <div className="mt-2">
            <input
              {...register("communication_client")}
              type="radio"
              value="no"
              id="communication_client"
            />
            {"  "}
            No
          </div>
          {!communicationValue && (
            <div className="flex mt-6">
              <div
                onClick={() => handleBack()}
                className="cursor-pointer mt-1 px-3 py-1.5 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>

                <span>Back</span>
              </div>
            </div>
          )}

          {communicationValue === "yes" && (
            <>
              <div className="flex gap-4 items-center">
                <div
                  onClick={() => handleBack()}
                  className="cursor-pointer  px-3 py-2 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                    />
                  </svg>

                  <span>Back</span>
                </div>
                <Button
                  onClick={async () => {
                    const result = await trigger();
                    console.log(result);
                    if (result) {
                      setHideClientTypeDropdown(true);
                      setStep(2);
                    }
                  }}
                  type="button"
                  className="text-white rounded my-4"
                >
                  {" "}
                  Continue
                </Button>
              </div>
            </>
          )}

          {communicationValue === "no" && (
            <>
              <div className="mt-4">
                <label className="text-neutral-900 font-semibold">
                  Communication address of the client:{" "}
                </label>
              </div>

              <div className="mt-4">
                <input
                  {...register("clientCommunicationAddress", {
                    required: {
                      value: true,
                      message: "Communication Address is required",
                    },
                  })}
                  id="clientCommunicationAddress"
                  name="clientCommunicationAddress"
                  type="text"
                  className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Communication Address"
                />
                <ErrorTest
                  errors={formErrors}
                  field="clientCommunicationAddress"
                />
              </div>

              <div className="flex gap-4 items-center">
                <div
                  onClick={() => handleBack()}
                  className="cursor-pointer px-3 py-2 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                    />
                  </svg>

                  <span>Back</span>
                </div>
                <Button
                  onClick={async () => {
                    const result = await trigger();
                    console.log(result);
                    if (result) {
                      setHideClientTypeDropdown(true);
                      setStep(2);
                    }
                  }}
                  type="button"
                  className="text-white rounded my-4"
                >
                  {" "}
                  Continue
                </Button>
              </div>
            </>
          )}
        </div>
      )}

      {step === 2 && (
        <>
          <div className="flex justify-between">
            <span className="text-neutral-900">
              Enter below details to generate a prefilled Services Agreement!{" "}
            </span>
            <span>{`Page 3/5`}</span>
          </div>
          <div className="mt-4">
            <div>
              <label
                htmlFor="comment"
                className="text-neutral-900 font-semibold"
              >
                Describe the services being provided under the agreement (try
                and specify details):
              </label>
              <div className="mt-2">
                <textarea
                  {...register("comment", {
                    required: {
                      value: true,
                      message: "This is required",
                    },
                  })}
                  rows={4}
                  name="comment"
                  id="comment"
                  className="block w-full rounded-md border-0 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-500 placeholder:text-neutral-900 focus:ring-inset focus:ring-indigo-500 sm:py-1.5 sm:text-sm sm:leading-6"
                  defaultValue={""}
                  placeholder={`1. First service and detail
2. Second service and detail
and so on...
                        `}
                />
              </div>
              <ErrorTest errors={formErrors} field="comment" />
            </div>

            <div className="mt-4">
              <label className="text-neutral-900 font-semibold">
                Party responsible to obtain approvals required for provision of
                the services under the Agreement:{" "}
              </label>
            </div>

            <div className="mt-4">
              <input
                {...register("partyProvider")}
                type="radio"
                value="Service Provider"
                id="partyProvider"
              />
              {"  "}
              Service Provider
            </div>
            <div className="mt-2">
              <input
                {...register("partyProvider")}
                type="radio"
                value="Client"
                id="partyProvider"
              />
              {"  "}
              Client
            </div>

            <div className="mt-4">
              <label className="text-neutral-900 font-semibold">
                Location at which the services will be provided:
              </label>
            </div>
            <div className="mt-4">
              <input
                {...register("city", {
                  required: {
                    value: true,
                    message: "City/Address is required",
                  },
                })}
                id="city"
                name="city"
                type="text"
                className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Address/City"
              />
              <ErrorTest errors={formErrors} field="city" />
            </div>

            <div className="mt-4">
              <label className="text-neutral-900 font-semibold">
                Party responsible to to bear the costs of travel and
                accomodation, if required:
              </label>
            </div>

            <div className="mt-4">
              <input
                {...register("partyResponsible")}
                type="radio"
                value="Service Provider"
                id="partyResponsible"
              />
              {"  "}
              Service Provider
            </div>
            <div className="mt-2">
              <input
                {...register("partyResponsible")}
                type="radio"
                value="Client"
                id="partyResponsible"
              />
              {"  "}
              Client
            </div>

            <div className="flex gap-4 items-center">
              <div
                onClick={() => {
                  setHideClientTypeDropdown(false);
                  setStep(1);
                }}
                className="cursor-pointer px-3 py-2 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>

                <span>Back</span>
              </div>
              <Button
                onClick={async () => {
                  const result = await trigger();
                  // console.log(result);
                  if (result) {
                    setStep(3);
                  }
                }}
                type="button"
                className="text-white rounded my-4"
              >
                {" "}
                Continue
              </Button>
            </div>
          </div>
        </>
      )}

      {step === 3 && (
        <>
          <div className="flex justify-between">
            <span className="text-neutral-900">
              Enter below details to generate a prefilled Services Agreement!{" "}
            </span>
            <span>{`Page 4/5`}</span>
          </div>
          <div className="mt-4">
            <div>
              <label className="text-neutral-900 font-semibold">
                Expected date of completion of the service:
              </label>
              <input
                className="my-4 relative block appearance-none rounded border border-neutral-500 px-3 py-1 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                type="date"
                id="date_format_completion"
                name="date_format_completion"
                //   onKeyDown={(event) => {
                //     event.preventDefault();
                //   }}
                {...register("date_format_completion", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
                // max={new Date().toISOString().split("T")[0]}
              />
              <ErrorTest errors={formErrors} field="date_format_completion" />
            </div>

            <div>
              <label className="text-neutral-900 font-semibold">Fee: </label>
              <div className="flex items-center gap-4">
                <select
                  {...register(`currency`)}
                  id={`currency`}
                  name={`currency`}
                  className="relative block w-full max-w-[125px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="" disabled selected hidden>
                    Currency{" "}
                  </option>
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                </select>
                <input
                  {...register("amount")}
                  id="amount"
                  name="amount"
                  type="text"
                  className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Amount"
                />
                <select
                  {...register(`frequency`)}
                  id={`frequency`}
                  name={`frequency`}
                  className="relative block w-full max-w-[125px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="" disabled selected hidden>
                    Frequency{" "}
                  </option>
                  <option value="Fixed Fee">Fixed Fee</option>
                  <option value="Annualy">Annualy</option>
                  <option value="Semi-anually">Semi-anually</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Daily">Daily</option>
                  <option value="Hourly">Hourly</option>
                </select>
              </div>
            </div>

            <div className="mt-4">
              <label className="mt-4 text-neutral-900 font-semibold">
                Number of days within which payment is to be made by the client
                after receipt of invoice from service provider:
              </label>
            </div>
            <div className="mt-4">
              <input
                {...register("no_of_days", {
                  required: {
                    value: true,
                    message: "Number of Days is required",
                  },
                })}
                id="no_of_days"
                name="no_of_days"
                type="number"
                className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Number of Days. Ex: 7, 14, 30, etc"
              />
              <ErrorTest errors={formErrors} field="no_of_days" />
            </div>

            <div className="mt-4">
              <label className="mt-4 text-neutral-900 font-semibold">
                The rate (percentage per annum) at which interest will be paid
                in the event of delay in payment of invoiced amount by the
                client:
              </label>
            </div>

            <div className="mt-4">
              <input
                {...register("percentageInterest", {
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
                id="percentageInterest"
                name="percentageInterest"
                type="number"
                className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Percentage (number). Ex: 10, 12, 15, etc."
              />
              <ErrorTest errors={formErrors} field="percentageInterest" />
            </div>

            <div className="mt-4">
              <label className="text-neutral-900 font-semibold">
                Date of commencement of the service:{" "}
              </label>
              <input
                className="my-4 relative block appearance-none rounded border border-neutral-500 px-3 py-1 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                type="date"
                id="date_format_service"
                name="date_format_service"
                //   onKeyDown={(event) => {
                //     event.preventDefault();
                //   }}
                {...register("date_format_service", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
                // max={new Date().toISOString().split("T")[0]}
              />
              <ErrorTest errors={formErrors} field="date_format_service" />
            </div>

            <div className="flex gap-4 items-center">
              <div
                onClick={() => {
                  setStep(2);
                }}
                className="cursor-pointer px-3 py-2 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>

                <span>Back</span>
              </div>
              <Button
                onClick={async () => {
                  const result = await trigger();
                  // console.log(result);
                  if (result) {
                    setStep(4);
                  }
                }}
                type="button"
                className="text-white rounded my-4"
              >
                {" "}
                Continue
              </Button>
            </div>
          </div>
        </>
      )}

      {step === 4 && (
        <>
          <div className="flex justify-between">
            <span className="text-neutral-900">
              Enter below details to generate a prefilled Services Agreement!{" "}
            </span>
            <span>{`Page 5/5`}</span>
          </div>
          <div className="mt-4">
            <div className="mt-4">
              <label className="mt-4 text-neutral-900 font-semibold">
                Period of notice (in days) given by either party to terminate
                the contract:
              </label>
            </div>

            <div className="mt-4">
              <input
                {...register("no_of_days_final", {
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
                id="no_of_days_final"
                name="no_of_days_final"
                type="number"
                className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Number of Days. Ex: 15, 30, 60, etc."
              />
              <ErrorTest errors={formErrors} field="no_of_days_final" />
            </div>

            <div className="mt-4">
              <label className="text-neutral-900 font-semibold">
                Party to own the intellectual property in any final product of
                the services provided to the client:
              </label>
            </div>

            <div className="mt-4">
              <input
                {...register("partyProvider_final")}
                type="radio"
                value="Service Provider"
                id="partyProvider_final"
              />
              {"  "}
              Service Provider
            </div>
            <div className="mt-2">
              <input
                {...register("partyProvider_final")}
                type="radio"
                value="Client"
                id="partyProvider_final"
              />
              {"  "}
              Client
            </div>

            <div className="mt-4">
              <label className="mt-4 text-neutral-900 font-semibold">
                Enter the place where the courts will have jurisdiction over the
                agreement. Please choose a place where one of the parties
                reside:
              </label>
            </div>

            <div className="mt-4">
              <input
                {...register("city_final", {
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
                id="city_final"
                name="city_final"
                type="text"
                className="relative block w-full max-w-[500px] appearance-none rounded border border-neutral-500 px-3 py-2 text-neutral-900 placeholder-neutral-900 focus:z-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="City"
              />
              <ErrorTest errors={formErrors} field="city_final" />
            </div>

            <div className="flex gap-4 items-center">
              <div
                onClick={() => {
                  setStep(3);
                }}
                className="cursor-pointer  px-3 py-2 text-base rounded flex gap-2 items-center justify-center cursor-pointer text-tertiary4-500 border border-tertiary4-500 hover:text-primary-550 hover:bg-primary-100 hover:border-none border border-tertiary4-500 focus:border-tertiary4-500 focus:outline-none focus:ring-tertiary4-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>

                <span>Back</span>
              </div>
              <Button
                //   onClick={async () => {
                //     const result = await trigger();
                //     if (result) {
                //       setStep(4);
                //     }
                //   }}
                type="submit"
                className="text-white rounded my-4"
              >
                {" "}
                Generate Document
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
