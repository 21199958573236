import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import Button from "src/components/Button";
import { useAuth } from "src/context/AuthProvider";
import { useCompanyData } from "src/context/CompanyDataProvider";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";

export const ErrorTest = ({ errors, field }) => {
  if (errors && errors[field]) {
    return (
      <small id="emailHelp" className="comm-error">
        {errors[field].message}
      </small>
    );
  }
  return null;
};

const WorkerList = ({
  email,
  inviteStatus,
  role,
  inviteSent,
  setInviteSent,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmRole, setConfirmRole] = useState(false);
  const { state: CompanyData } = useCompanyData();
  const { idToken } = useAuth();
  const { sendInvite } = useAuthOnBoardFlow();

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      roleSelect: role,
    },
  });
  const { register, reset, getValues } = methods;

  const handleDelete = async (email) => {
    let body = [email];
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/company/${CompanyData?.id}/collaborators/delete`,
        body,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await res.data;
      console.log("data", data);
      setConfirmDelete(false);
      setInviteSent(!inviteSent);
      toast.success("Collaborator deleted", {
        className: "toast-position",
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
      setConfirmDelete(false);
      toast.error(`${error?.response?.data?.message}`, {
        className: "toast-position",
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleResent = (email, role) => {
    const inviteObj = {};

    inviteObj[email] = role;

    console.log(inviteObj);
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");

    sendInvite(idToken, companyId, inviteObj)
      .then((res) => {
        console.log(res);
        toast.success(
          "The email has been re-sent! Please also check the spam folder.",
          {
            className: "toast-position",
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        // navigate("/companies/completed", { state: companyId });
      })
      .catch((err) => {
        console.log(err);
        toast.error(`${err?.response?.data?.message}`, {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleRoleChange = async (email) => {
    let body = {
      email: email,
      newRole: getValues("roleSelect"),
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/company/${CompanyData?.id}/collaborators/update-role
          `,
        body,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await res.data;
      console.log("data", data);
      setConfirmRole(false);
      setInviteSent(!inviteSent);
      toast.success("Collaborator role changed", {
        className: "toast-position",
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      if (error?.response?.status == 403) {
        setConfirmRole(false);
        reset();
        toast.error(`${error?.response?.data?.message}`, {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setConfirmRole(false);
        toast.error(`${error?.response?.data?.message}`, {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  return (
    <div
      key={email}
      className={`grid grid-cols-5 items-center ${
        role === "OWNER" ? "text-neutral-500" : "text-neutral-900"
      }`}
    >
      <p className="col-span-2  text-sm">{email}</p>
      {role === "OWNER" ? (
        <p className="col-span-1  text-sm">Owner</p>
      ) : (
        <select
          {...register("roleSelect")}
          onChange={() => {
            setConfirmRole(true);
            setConfirmDelete(false);
          }}
          id={`roleSelect`}
          name={`roleSelect`}
          className="col-span-1 text-sm relative block w-full max-w-[120px] appearance-none rounded border border-primary-500 px-3 py-[7px] text-neutral-900 placeholder-neutral-900 focus:border-warning-500 focus:outline-none focus:ring-warning-500 sm:text-sm"
        >
          <option value="" disabled hidden>
            Assign Role
          </option>
          <option value="ADMIN">Admin</option>
          {/* <option value="MANAGER">Manager</option>
          <option value="MEMBER">Member</option> */}
        </select>
      )}
      {confirmRole ? (
        <div className="flex col-span-1 items-center justify-center gap-10">
          <img
            src="/icons/right.png"
            className="w-6 h-6 cursor-pointer"
            alt="confirm"
            onClick={() => handleRoleChange(email)}
            // onClick={(e) => {
            //   console.log(getValues("roleSelect"));
            // }}
          />
          <img
            src="/icons/cancel.png"
            alt="cancel"
            className="cursor-pointer"
            onClick={() => {
              setConfirmRole(false);
              reset();
            }}
          />
        </div>
      ) : (
        role !== "OWNER" &&
        !confirmDelete && (
          <div
            onClick={() => setConfirmDelete(true)}
            className="cursor-pointer flex h-auto items-center justify-center col-span-1 space-x-3 "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#4895EFFF"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
            <span className="text-sm text-tertiary4-500">Delete</span>
          </div>
        )
      )}

      {confirmDelete ? (
        <div className="flex col-span-1 items-center justify-center gap-10">
          <img
            src="/icons/deleteActive.png"
            className="w-6 h-6 cursor-pointer"
            alt="confirm"
            onClick={() => handleDelete(email)}
          />
          <img
            src="/icons/cancel.png"
            alt="cancel"
            className="cursor-pointer"
            onClick={() => setConfirmDelete(false)}
          />
        </div>
      ) : null}

      {inviteStatus !== "ACCEPTED" && (
        <div
          onClick={() => handleResent(email, role)}
          className="flex items-center ml-2 col-span-1 cursor-pointer text-tertiary4-500 text-sm"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
          <p className="ml-1 text-sm"> Resend Invite</p>
        </div>
      )}
    </div>
  );
};

export default function NewCoWorkers() {
  const [loading, setLoading] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [teamsData, setTeamsData] = useState(null);
  const [items, setItems] = useState([1]);
  const [hover, setHover] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [checked, setChecked] = useState(false);
  const location = useLocation();

  const { state: CompanyData } = useCompanyData();
  const { state: authData, fetchTokenManually, idToken } = useAuth();
  const { sendInvite } = useAuthOnBoardFlow();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    const fetchTeam = async () => {
      const updatedToken = await fetchTokenManually();
      const token = updatedToken || authData?.idTokenUpdated || idToken;
      if (token && CompanyData?.id) {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/company/${CompanyData?.id}/collaborators`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await res.data;
        setTeamsData(data);
      }
    };

    fetchTeam();
  }, [CompanyData, inviteSent, idToken]);

  useLayoutEffect(() => {
    setChecked(selectedPeople.length === teamsData?.length);
    // checkbox?.current?.indeterminate = isIndeterminate;
  }, [selectedPeople, teamsData]);

  // const toggleAll = () => {
  //   const emailTeamsArr = teamsData.map((team) => team.email);
  //   setSelectedPeople(checked ? [] : emailTeamsArr);
  //   setChecked(!checked);
  // };

  const onInviteSubmit = (data) => {
    setLoading(true);
    console.log(data);
    const inviteObj = {};
    items.forEach((item) => {
      inviteObj[data[`email${item}`]] = data[`role${item}`];
    });
    console.log(inviteObj);
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");
    sendInvite(idToken, companyId, inviteObj)
      .then((res) => {
        console.log(res);
        reset();
        setItems([1]);
        setInviteSent(!inviteSent);
        setLoading(false);
        toast.success(
          "The email has been sent! Please also check the spam folder.",
          {
            className: "toast-position",
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        // navigate("/companies/completed", { state: companyId });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(
          err?.response?.data?.message ||
            "Something went wrong!! Please try again",
          {
            className: "toast-position",
            position: "bottom-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      });
  };

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const handleAddMore = () => {
    console.log("sa");
    const arrL = items.length + 1;
    setItems([...items, arrL]);
  };

  const handleDeleteRow = (item) => {
    items.length > 1 && items.pop();
    const newItems = items;
    setItems([...newItems]);
  };

  const getCurrentTitle = () => {
    if (location.pathname.includes("/financial")) {
      return "Financials";
    }

    if (location.pathname.includes("/invoice")) {
      return "Invoices and Documents";
    }

    switch (location.pathname) {
      case "/":
        return "Bank Accounts";
      case "/invoice":
        return "Invoices and Documents";
      case "/settings":
        return "Settings";
      case "/co-workers":
        return "Co-workers";
      case "/account":
        return "Your Account";
      case "/gethelp":
        return "Get Help";
      case "/invite":
        return "Invite";
      default:
        return "";
    }
  };

  return (
    <div className="co-workers">
      <div className="flex items-center gap-5 z-10 pl-[30px] border-b border-neutral-400">
        <span className="text-xl text-neutral-900 font-medium inline-block py-5">
          {getCurrentTitle()}
        </span>
      </div>
      <p className="ml-[30px] mr-5 mt-6">
        Invite your co-workers to collaborate on {CompanyData?.name}. Please
        note that your current role governs the roles you can invite.
      </p>

      <form
        className="relative border-b border-neutral-400 pb-4 ml-[30px] mr-5 mt-6"
        onSubmit={handleSubmit(onInviteSubmit)}
      >
        <div>
          {items.map((item, index) => (
            <>
              <div
                key={index}
                className="flex flex-start items-center gap-4 mt-4"
              >
                <div>
                  <input
                    id={`email${item}`}
                    name={`email${item}`}
                    type="email"
                    autoComplete="current-password"
                    className="relative block w-[340px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Email"
                    {...register(`email${item}`, {
                      required: {
                        value: true,
                        message: "Email is required",
                      },
                    })}
                  />
                  <ErrorTest errors={errors} field={`email${item}`} />
                </div>
                {CompanyData?.userRole === "OWNER" && (
                  <div>
                    <select
                      {...register(`role${item}`, {
                        required: {
                          value: true,
                          message: "Please select a role",
                        },
                      })}
                      id={`role${item}`}
                      name={`role${item}`}
                      className="relative block w-[200px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      {/* <option value="" disabled hidden>
                        Assign Role
                      </option> */}
                      <option selected value="ADMIN">
                        Admin
                      </option>
                      {/* <option value="MANAGER">Manager</option>
                      <option value="MEMBER">Member</option> */}
                    </select>
                    <ErrorTest errors={errors} field={`role${item}`} />
                  </div>
                )}

                {CompanyData?.userRole === "ADMIN" && (
                  <div>
                    <select
                      {...register(`role${item}`, {
                        required: {
                          value: true,
                          message: "Please select a role",
                        },
                      })}
                      id={`role${item}`}
                      name={`role${item}`}
                      className="relative block w-[200px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="" disabled selected hidden>
                        Assign Role
                      </option>
                      <option selected value="ADMIN">
                        Admin
                      </option>
                      {/* <option value="MANAGER">Manager</option>
                      <option value="MEMBER">Member</option> */}
                    </select>
                    <ErrorTest errors={errors} field={`role${item}`} />
                  </div>
                )}

                {CompanyData?.userRole === "MANAGER" && (
                  <div>
                    <select
                      {...register(`role${item}`, {
                        required: {
                          value: true,
                          message: "Please select a role",
                        },
                      })}
                      id={`role${item}`}
                      name={`role${item}`}
                      className="relative block w-[200px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="" disabled selected hidden>
                        Assign Role
                      </option>
                      <option value="MEMBER">Member</option>
                    </select>
                    <ErrorTest errors={errors} field={`role${item}`} />
                  </div>
                )}

                {CompanyData?.userRole === "MEMBER" && (
                  <div>
                    <select
                      {...register(`role${item}`, {
                        required: {
                          value: true,
                          message: "Please select a role",
                        },
                      })}
                      disabled={true}
                      id={`role${item}`}
                      name={`role${item}`}
                      className="relative block w-[200px] appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="" disabled selected hidden>
                        Assign Role
                      </option>
                      <option value="MEMBER">Member</option>
                    </select>
                    <ErrorTest errors={errors} field={`role${item}`} />
                  </div>
                )}

                {items.length > 1 && (
                  <div
                    className="cursor-pointer"
                    onClick={() => handleDeleteRow(item)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#4895EFFF"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </>
          ))}

          <Button
            type="button"
            btnType="btnLink"
            className="my-3 text-tertiary4-500"
            onClick={handleAddMore}
          >
            + Add more
          </Button>
          <div>
            <Button
              loading={loading}
              disabled={loading}
              type="submit"
              className="rounded text-white font-medium"
            >
              Send Invites
            </Button>
          </div>
        </div>
        <div
          className={`${
            items.length === 1
              ? "absolute z-5 left-[572px] top-[8px]"
              : "absolute z-5 left-[608px] top-[8px]"
          }`}
          onMouseEnter={onHover}
          onMouseLeave={onLeave}
          role="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
          {hover && (
            <div className="absolute lg:w-[200px] xl:w-[300px] z-10 top-[35px] lg:top-[30px] left-[-450px] lg:left-[-40px] bg-secondary-500 rounded-lg p-4 text-white">
              <div className="flex">
                <p className="text-neutral-400">
                  Invited co-workers would have all the permissions except the
                  option to delete the company
                </p>
              </div>
            </div>
            // <div className="absolute lg:w-[400px] xl:w-[500px] z-10 top-[35px] lg:top-[30px] left-[-450px] lg:left-0 bg-secondary-500 rounded-lg p-4 text-white">
            //   <p className="mb-2">Roles</p>
            //   <div className="flex">
            //     <span className="mr-2">Owner:</span>
            //     <p className="text-neutral-400">Full permission</p>
            //   </div>

            //   <div className="flex">
            //     <span className="mr-2">Admin:</span>
            //     <p className="text-neutral-400">
            //       Access to all functions except delete company and
            //       invite/delete admins
            //     </p>
            //   </div>

            //   <div className="flex">
            //     <span className="mr-2">Manager:</span>
            //     <p className="text-neutral-400">
            //       Invite/delete members, add/delete invoices, access settings,
            //       view bank account details.
            //     </p>
            //   </div>

            //   <div className="flex">
            //     <span className="mr-2">Member:</span>
            //     <p className="text-neutral-400">
            //       View bank accounts, invoices and financials
            //     </p>
            //   </div>
            // </div>
          )}
        </div>
      </form>

      {teamsData?.length > 0 && (
        <div className="lg:w-2/3 xl:w-1/2 space-y-5 ml-[30px] mr-5">
          <div className="grid grid-cols-5 mt-5">
            <p className="col-span-2 text-neutral-900 font-semibold">
              Co-workers Emails
            </p>
            <p className="col-span-1 text-neutral-900 font-semibold">Role</p>
            <p className="col-span-2"></p>
          </div>
          {teamsData?.map((team) => {
            return (
              <WorkerList
                {...team}
                inviteSent={inviteSent}
                setInviteSent={setInviteSent}
              />
            );
          })}
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
