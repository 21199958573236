import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAuth, UserActions } from "src/context/AuthProvider";
import { getAuth, isSignInWithEmailLink } from "firebase/auth";
import { useAuthOnBoardFlow } from "src/hooks/useAuthOnBoardFlow";
import FullScreenLoader from "../FullScreenLoader";

export default function Confirm() {
  const navigate = useNavigate();
  const {
    auth,
    userData,
    isLoading,
    signInWithEmailLinkFunc,
    idToken,
    dispatch,
  } = useAuth();
  const { getAllCompanies } = useAuthOnBoardFlow();

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      signInWithEmailLinkFunc(email, window.location.href)
        .then((user) => {
          console.log("object");
          // window.localStorage.removeItem("emailForSignIn");

          if (user) {
            console.log("object user");
            getAllCompanies(user.accessToken)
              .then((result) => {
                if (result === "Signup incomplete") {
                  navigate("/profile-details");
                  window.localStorage.setItem("user", "1");
                } else {
                  // Commenting as we disable bankaccount for now and our / is changed from bankaccount to companies page
                  // navigate("/companies", { state: result });
                  navigate("/", { state: result });
                  window.localStorage.setItem("user", "1");
                }
              })
              .catch((err) => {
                console.log("err", err);
              });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, []);

  return <FullScreenLoader />;
}
