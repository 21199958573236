import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { useSettings } from "src/hooks/useSettings";
import { useAuth } from "src/context/AuthProvider";
import { useNavigate } from "react-router";
import { useInvoice } from "src/hooks/useInvoice";
import { useCompanyData } from "src/context/CompanyDataProvider";
import Button from "src/components/Button";

export default function DeleteFileConfirmationModal({
  open,
  closeModal,
  name,
  monthName,
  fYear,
  fileClickedId,
  fetchAgain,
  setFetchAgain,
}) {
  const [loading, setLoading] = useState(false);
  const { deleteFile } = useInvoice();
  const { state: CompanyData } = useCompanyData();
  const { idToken } = useAuth();

  const methods = useForm();
  const { handleSubmit } = methods;

  const onSubmit = async () => {
    setLoading(true);
    const companyId =
      CompanyData?.id || window.localStorage.getItem("companyId");
    try {
      const res = await deleteFile(
        companyId,
        fYear,
        monthName,
        fileClickedId,
        idToken
      );
      if (res) {
        toast.success("File removed successfully!!", {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setFetchAgain(!fetchAgain);
        setLoading(false);
        closeModal(false);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(
        `${error?.response?.data?.message} || Something went wrong!! Please try again later`,
        {
          className: "toast-position",
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      setFetchAgain(!fetchAgain);
      setLoading(false);
      closeModal(false);
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[12]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
                      onClick={() => closeModal(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <div>
                    <p className="mt-4 mb-6 text-neutral-900 text-base">
                      Are you sure you want to delete "{name}" ?
                    </p>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-5 sm:mt-4 gap-4 ">
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <Button
                          disabled={loading}
                          loading={loading}
                          className="inline-flex w-full justify-center rounded-md text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          Delete
                        </Button>
                        <Button
                          btnType="secondary"
                          type="button"
                          className="mt-3 inline-flex w-full justify-center items-center rounded-md text-tertiary4-500 text-base font-medium shadow-sm focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() => {
                            closeModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ToastContainer />
    </>
  );
}
