import React from "react";
import { Oval } from "react-loader-spinner";

export default function FullScreenLoader() {
  return (
    <div className="overlay">
      <Oval
        height={50}
        width={50}
        color="#2B85EDFF"
        wrapperStyle={{}}
        wrapperClass="hor-ver-center"
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#ffffff"
        strokeWidth={3}
        strokeWidthSecondary={2}
      />
    </div>
  );
}
