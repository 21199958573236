import React from "react";
import { Link } from "react-router-dom";

export default function Login({ setAuthMethod }) {
  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt="Your Company"
            />
            <h3 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Hello, Welcome Back
            </h3>
          </div>
          {/* <SocialComponent /> */}
          <p className="text-center">OR</p>
          <form className="mt-8 space-y-5" action="#" method="POST">
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div className="pt-5">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded border border-neutral-500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <Link
                  to="/resetpassword"
                  className="font-normal text-sm text-primary-500"
                >
                  Forgot password?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded border border-transparent bg-neutral-500 py-2 px-4 text-sm font-medium text-white hover:bg-primary-550 active:bg-neutal-600"
              >
                Log In
              </button>
            </div>

            <div className="flex justify-center gap-1.5">
              <span className="text-neutral-900 text-sm">
                Don't have an account?
              </span>
              <button
                type="button"
                onClick={() =>
                  setAuthMethod({
                    signup_screen: true,
                    login_screen: false,
                    forgot_password: false,
                  })
                }
                className="font-normal text-sm text-primary-500"
              >
                Sign up
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
